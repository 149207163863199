import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Pipe({ name: 'validationErrorMessage', standalone: true })
export class ValidationErrorMessagePipe implements PipeTransform {
    transform(validationErrors?: null | ValidationErrors): string {
        if (!validationErrors) {
            return '';
        }

        if (validationErrors['required']) {
            return 'This is a required field';
        }

        if (validationErrors['mask']) {
            return `Must be in the format ${validationErrors['mask'].requiredMask}`;
        }

        if (validationErrors['min']) {
            return `${validationErrors['min'].min} is the minimum value allowed`;
        }

        if (validationErrors['minlength']) {
            return `${validationErrors['minlength'].requiredLength} is the minimum character limit.`;
        }

        if (validationErrors['minArrayLength']) {
            return `${validationErrors['minArrayLength']} is the minimum required.`;
        }

        if (validationErrors['max']) {
            return `${validationErrors['max'].max} is the maximum value allowed`;
        }

        if (validationErrors['maxlength']) {
            return `${validationErrors['maxlength'].requiredLength} is the maximum character limit.`;
        }

        if (validationErrors['maxArraylength']) {
            return `${validationErrors['maxArraylength']} is the maximum allowed.`;
        }

        if (validationErrors['dateMustBeToday']) {
            return 'Date must be today';
        }

        if (validationErrors['dateMustBeBefore']) {
            return validationErrors['dateMustBeBefore'];
        }

        if (validationErrors['dateMustBeAfter']) {
            return validationErrors['dateMustBeAfter'];
        }

        if (validationErrors['datesMustBeValidRange']) {
            return 'Must be a valid date range';
        }

        if (validationErrors['mustContainRequiredFields']) {
            return 'Must contain all required fields';
        }

        if (validationErrors['isTrue']) {
            return 'This field must be selected';
        }

        if (validationErrors['regex']) {
            return validationErrors['regex'];
        }

        return 'The field is invalid';
    }
}
