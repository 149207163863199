import { Injectable } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DYNAMIC_FORM, FormSetup } from '@wdx/shared/utils';
import { ModalManagementService } from './modal-management.service';
import { QuickCreateModalComponent } from './quick-create-modal/quick-create-modal.component';

@Injectable()
export class DynamicFormsManagementService {
    constructor(private modalManagementService: ModalManagementService) {}

    openDynamicFormModal(formSetup: FormSetup): NgbModalRef | undefined {
        if (!formSetup.preserveActiveModal) {
            this.modalManagementService.closeActiveModal();
        }

        if (formSetup.isQuickCreate) {
            this.openQuickCreateModal(formSetup);
            return;
        }

        return this.modalManagementService.openModalWithId(
            DYNAMIC_FORM,
            formSetup
        );
    }

    openQuickCreateModal(formSetup: FormSetup) {
        const quickCreateModal =
            this.modalManagementService.openModalWithComponent(
                QuickCreateModalComponent
            );
        quickCreateModal.componentInstance.formId = formSetup.formId;
        quickCreateModal.componentInstance.entityId = formSetup.entityId;
        quickCreateModal.componentInstance.initialisationParams =
            formSetup.initialisationParams;
        quickCreateModal.componentInstance.createSuccess.subscribe(() =>
            quickCreateModal.close()
        );
    }
}
