import { Action, createReducer, on } from '@ngrx/store';
import { IdentityCheck } from '@wdx/clmi/clmi-swagger-gen';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import * as identityAndVerificationActions from './identity-and-verification.actions';
import {
    CrudStatus,
    IdentityCheckVerificationOptions,
} from '@wdx/shared/utils';

export const IdentityCheckStore = 'identityCheck';

export interface State {
    partyIdentityCheckData: {
        identityCheck: IdentityCheck[] | null;
        status: CrudStatus;
        id?: string;
    };

    identityCheckData: {
        identityCheck: IdentityCheck | null;
        status: CrudStatus;
        id?: string;
    };

    optionsData: {
        options: IdentityCheckVerificationOptions | null;
        status: CrudStatus;
    };
}

export const initialState: State = {
    partyIdentityCheckData: {
        identityCheck: null,
        status: CrudStatus.Initial,
    },
    identityCheckData: {
        identityCheck: null,
        status: CrudStatus.Initial,
    },

    optionsData: {
        options: null,
        status: CrudStatus.Initial,
    },
};

export const reducerSetup = createReducer(
    initialState,

    on(
        identityAndVerificationActions.getIdentityCheckForPartySuccess,
        (state, props): State => ({
            ...state,
            partyIdentityCheckData: {
                identityCheck: props.identityCheck,
                id: props.partyId,
                status: CrudStatus.Success,
            },
        }),
    ),

    on(
        identityAndVerificationActions.getIdentityCheckForPartyError,
        (state, props): State => ({
            ...state,
            partyIdentityCheckData: {
                identityCheck: null,
                id: props.partyId,
                status: CrudStatus.Error,
            },
        }),
    ),

    on(
        identityAndVerificationActions.getIdentityCheckForParty,
        (state, props): State => ({
            ...state,
            partyIdentityCheckData: {
                identityCheck: null,
                id: props.partyId,
                status: CrudStatus.Loading,
            },
        }),
    ),

    on(
        identityAndVerificationActions.getIdentityCheckSuccess,
        (state, props): State => ({
            ...state,
            identityCheckData: {
                identityCheck: props.identityCheck,
                id: props.identitycheckId,
                status: CrudStatus.Success,
            },
        }),
    ),

    on(
        identityAndVerificationActions.getIdentityCheckError,
        (state, props): State => ({
            ...state,
            identityCheckData: {
                identityCheck: null,
                id: props.identitycheckId,
                status: CrudStatus.Error,
            },
        }),
    ),

    on(
        identityAndVerificationActions.getIdentityCheck,
        (state, props): State => ({
            ...state,
            identityCheckData: {
                identityCheck: null,
                id: props.identitycheckId,
                status: CrudStatus.Loading,
            },
        }),
    ),

    on(
        identityAndVerificationActions.getIdentityCheckOptionsSuccess,
        (state, { options }): State => ({
            ...state,
            optionsData: {
                options,
                status: CrudStatus.Success,
            },
        }),
    ),

    on(
        identityAndVerificationActions.getIdentityCheckOptionsError,
        (state): State => ({
            ...state,
            optionsData: {
                options: null,
                status: CrudStatus.Error,
            },
        }),
    ),

    on(
        identityAndVerificationActions.getIdentityCheckOptions,
        (state): State => ({
            ...state,
            optionsData: {
                options: null,
                status: CrudStatus.Loading,
            },
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
