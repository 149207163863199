import {
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    OnInit,
    ViewChild,
    inject,
} from '@angular/core';
import {
    FormGroupDirective,
    NgControl,
    UntypedFormGroup,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { BaseWdxFormControlClass } from '../../../abstract-classes/base-form-control.class';

import { delay, takeUntil } from 'rxjs/operators';
import {
    IFormDynamicData,
    IWrappedFormComponentProvider,
} from '../../../interfaces';
import { ValidationErrorMessagePipe } from '../../../pipes';
import { FormWrappedControlDirective } from './form-wrapped-control-host.directive';

@Component({
    selector: 'wdx-ff-form-wrapped-control',
    templateUrl: './form-wrapped-control.component.html',
})
export class FormWrappedControlComponent
    extends BaseWdxFormControlClass
    implements OnInit
{
    @Input() formContext!: any;
    @ViewChild(FormWrappedControlDirective, { static: true })
    formWrappedControlHost!: FormWrappedControlDirective;
    public validationErrorMessage = '';

    private validationErrorMessagePipe = new ValidationErrorMessagePipe();
    private formControlService = inject(IWrappedFormComponentProvider);
    private injector = inject(Injector);
    private cdr = inject(ChangeDetectorRef);
    private componentRef!: any;
    private ngControl!: NgControl;

    formData$ = new BehaviorSubject<UntypedFormGroup>(null as any);

    constructor(
        public override controlContainer: FormGroupDirective,
        public override dynamicDataService: IFormDynamicData,
    ) {
        super(controlContainer, dynamicDataService);
        this.controlContainer.form.valueChanges
            // need a delay to ensure this is done after any form conditions/triggers have run
            .pipe(takeUntil(this.destroyed$), delay(0))
            .subscribe((_) => {
                this.formData$.next(this.controlContainer?.form.getRawValue());

                if (
                    !this.formControlService.hideErrorMessages &&
                    this.ngControl &&
                    this.ngControl.control?.touched
                ) {
                    this.validationErrorMessage =
                        this.validationErrorMessagePipe.transform(
                            this.ngControl.control.errors,
                        );
                }
            });
    }

    ngOnInit(): void {
        this.ngControl = this.injector.get(NgControl);

        if (this.formElement?.contextualValues?.length) {
            this.formData$.next(this.controlContainer?.form.getRawValue());
        }
        this.formElement.isDisabled = this.isDisabled;

        this.formData$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((formData) => {
                this.formControlService
                    .getComponent$(this.formElement, this.formContext, formData)
                    .subscribe(({ component, inputs }) => {
                        if (!this.componentRef) {
                            const viewContainerRef =
                                this.formWrappedControlHost.viewContainerRef;
                            this.componentRef =
                                viewContainerRef.createComponent(component);

                            this.ngControl.valueAccessor =
                                this.componentRef.instance;
                        }

                        Object.entries(inputs).forEach(([key, value]) => {
                            this.componentRef.instance[key] = value;
                        });
                        this.cdr.markForCheck();
                    });
            });
    }
}
