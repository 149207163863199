import { Component } from '@angular/core';
import { AssignToEvent } from '../../../../../models/assign.model';
import { ModalManagementService } from '@wdx/clmi/ui';
import { RelatedRecordsFacadeService } from '../../../../../shared/features/related-records/related-records-facade.service';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseComponentClass } from '../../../../../classes/base-component.class';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {
    Activity,
    DocumentType,
    Party,
    ProcessOutcome,
    SystemEntity,
    WorkItem,
} from '@wdx/clmi/clmi-swagger-gen';
import { EngagementState } from '../../../../../models/engagement-state.model';
import { SystemEntityRelatedRecordLink } from '../../../../../models/system-entity-related-record-link.model';
import * as activitiesActions from '../../../../../state/activities/activities.actions';
import * as activitiesSelectors from '../../../../../state/activities/activities.selectors';
import * as assignActions from '../../../../../state/assign/assign.actions';
import { dynamicFormsActions } from '@wdx/shared/infrastructure/form-framework';
import * as relatedRecordsActions from '../../../../../state/related-records/related-records.actions';
import * as rootReducer from '../../../../../state/_setup/reducers';
import { EngagementBarFacade } from '../../../../../state/engagement-bar/engagement-bar.facade';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'organism-engagement-bar',
    templateUrl: './organism-engagement-bar.component.html',
})
export class OrganismEngagementBarComponent extends BaseComponentClass {
    isVisible$: Observable<boolean>;
    activityId$: Observable<string>;
    party$: Observable<Party>;
    workItem$: Observable<WorkItem>;
    engagementState$: Observable<EngagementState>;

    activity$: Observable<Activity>;

    documentTypes$: Observable<DocumentType[]>;

    relatedActivitiesCount$: Observable<number>;
    relatedArticlesCount$: Observable<number>;
    relatedCasesCount$: Observable<number>;
    relatedOpportunitiesCount$: Observable<number>;

    activityId: string;

    constructor(
        actions$: Actions,
        store$: Store<rootReducer.State>,
        modalManagementService: ModalManagementService,
        private relatedRecordsFacadeService: RelatedRecordsFacadeService,
        private engagementBarFacade: EngagementBarFacade,
    ) {
        super();

        this.patchInjectedItems({
            actions$,
            store$,
            modalManagementService,
        });

        this.isVisible$ = this.engagementBarFacade.isVisible$;
        this.activityId$ = this.engagementBarFacade.activityId$;
        this.party$ = this.engagementBarFacade.party$;
        this.engagementState$ = this.engagementBarFacade.engagementState$;

        this.activityId$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((activityId) => {
                this.activityId = activityId;
                this.activity$ = this.store$.select(
                    activitiesSelectors.getSingle,
                    { id: activityId },
                );

                if (this.activityId) {
                    this.workItem$ = this.engagementBarFacade.getWorkItem$(
                        this.activityId,
                    );
                    this.reloadActivity();
                    this.getRelatedRecordsCounters();
                }
            });

        this.listenTo(
            ...dynamicFormsActions.formDataCreateAndUpdateSuccessActions,
        ).subscribe(() => {
            if (this.activityId) {
                this.reloadActivity();
            }
        });
    }

    getRelatedRecordsCounters() {
        const relatedProps = {
            entityType: SystemEntity.Activity,
            entityId: this.activityId,
        };

        this.relatedActivitiesCount$ = this.relatedRecordsFacadeService
            .getRelatedActivities$(relatedProps)
            .pipe(map((relatedActivities) => relatedActivities?.length));

        this.relatedArticlesCount$ = this.relatedRecordsFacadeService
            .getRelatedArticles$(relatedProps)
            .pipe(map((relatedArticles) => relatedArticles?.length));

        this.relatedCasesCount$ = this.relatedRecordsFacadeService
            .getRelatedCases$(relatedProps)
            .pipe(map((relatedCases) => relatedCases?.length));

        this.relatedOpportunitiesCount$ = this.relatedRecordsFacadeService
            .getRelatedOpportunities$(relatedProps)
            .pipe(map((relatedOpportunities) => relatedOpportunities?.length));
    }

    onCloseClick(): void {
        this.engagementBarFacade.setVisibility(false);
    }

    reloadActivity(): void {
        this.store$.dispatch(
            activitiesActions.getForId({
                activityId: this.activityId,
            }),
        );
    }

    onCaseLinkButtonClicked(link: SystemEntityRelatedRecordLink) {
        if (!this.activityId) {
            return;
        }

        this.store$.dispatch(
            relatedRecordsActions.createLinkToCaseForEntity({
                entityType: SystemEntity.Activity,
                entityId: this.activityId,
                caseId: link.linkedItemEntityId,
            }),
        );
    }

    onCompleteClicked(): void {
        this.engagementBarFacade.dispatchCompleteActivity(this.activityId);
        this.onCloseClick();
    }

    onAssignTo(assignToEvent: AssignToEvent) {
        this.store$.dispatch(assignActions.assignTo(assignToEvent));
    }

    onOutcomesClicked(outcome: ProcessOutcome) {
        this.engagementBarFacade.dispatchOutcomeWorkItem(
            outcome,
            this.activityId,
        );
    }
}
