export const UTILS_ICON_ACTIVATE = 'paper-plane';
export const UTILS_ICON_ADD = 'plus';
export const UTILS_ICON_ANNOUNCEMENT = 'bullhorn';
export const UTILS_ICON_ARROW_DOWNLOAD = 'cloud-arrow-down';
export const UTILS_ICON_ARROW_PROGRESS = 'arrow-progress';
export const UTILS_ICON_ARROW_UPLOAD = 'cloud-arrow-up';
export const UTILS_ICON_AUDIT = 'clock-rotate-left';
export const UTILS_ICON_BACK_BRACKET = 'arrow-right-from-bracket';
export const UTILS_ICON_BAN = 'ban';
export const UTILS_ICON_CALCULATOR_SIMPLE = 'calculator-simple';
export const UTILS_ICON_CALENDAR = 'calendar';
export const UTILS_ICON_CALENDAR_ALT = 'calendar-alt';
export const UTILS_ICON_CALENDAR_CHECK = 'calendar-check';
export const UTILS_ICON_CALENDAR_CIRCLE_PLUS = 'calendar-circle-plus';
export const UTILS_ICON_CALENDAR_LINES_PEN = 'calendar-lines-pen';
export const UTILS_ICON_CALENDAR_PEN = 'calendar-pen';
export const UTILS_ICON_CHART_MIXED = 'chart-mixed';
export const UTILS_ICON_CHECK = 'check';
export const UTILS_ICON_CHECK_DOUBLE = 'check-double';
export const UTILS_ICON_CHEVRON_RIGHT = 'chevron-right';
export const UTILS_ICON_CHEVRON_LEFT = 'chevron-left';
export const UTILS_ICON_CHEVRON_UP = 'chevron-up';
export const UTILS_ICON_CIRCLE = 'circle';
export const UTILS_ICON_CIRCLE_CHECK = 'circle-check';
export const UTILS_ICON_CIRCLE_EXCLAMATION = 'circle-exclamation';
export const UTILS_ICON_CIRCLE_USER = 'circle-user';
export const UTILS_ICON_CLIPBOARD_LIST_CHECK = 'clipboard-list-check';
export const UTILS_ICON_CLOCK = 'clock';
export const UTILS_ICON_CLONE = 'clone';
export const UTILS_ICON_CLOSE = 'times';
export const UTILS_ICON_COMMENT = 'comment';
export const UTILS_ICON_COMMENTS = 'comments';
export const UTILS_ICON_COMMENT_PLUS = 'comment-plus';
export const UTILS_ICON_CONTEXT_MENU = 'ellipsis-h';
export const UTILS_ICON_CREDIT_CARD = 'credit-card';
export const UTILS_ICON_DANGER = 'exclamation-circle';
export const UTILS_ICON_DEACTIVATE = 'archive';
export const UTILS_ICON_DELETE = 'trash-alt';
export const UTILS_ICON_DELETE_UNDO = 'trash-undo';
export const UTILS_ICON_DIAMOND_EXCLAMATION = 'diamond-exclamation';
export const UTILS_ICON_DOCUMENT = 'file';
export const UTILS_ICON_DOWNLOAD = 'download';
export const UTILS_ICON_DROPDOWN = 'chevron-down';
export const UTILS_ICON_ENVELOPE = 'envelope';
export const UTILS_ICON_ENVELOPE_CIRCLE_CHECK = 'envelope-circle-check';
export const UTILS_ICON_ENVELOPE_DOT = 'envelope-dot';
export const UTILS_ICON_ENVELOPE_OPEN = 'envelope-open';
export const UTILS_ICON_EXCLAMATION = 'exclamation';
export const UTILS_ICON_EXTERNAL_LINK = 'arrow-up-right-from-square';
export const UTILS_ICON_EYE = 'eye';
export const UTILS_ICON_EYE_SLASH = 'eye-slash';
export const UTILS_ICON_FAVOURITE = 'star';
export const UTILS_ICON_FILE_CODE = 'file-code';
export const UTILS_ICON_FILE_CONTRACT = 'file-contract';
export const UTILS_ICON_FILE_CSV = 'file-csv';
export const UTILS_ICON_FILE_EXCEL = 'file-excel';
export const UTILS_ICON_FILE_IMAGE = 'file-image';
export const UTILS_ICON_FILE_PDF = 'file-pdf';
export const UTILS_ICON_FILE_PEN = 'file-pen';
export const UTILS_ICON_FILE_PLUS = 'file-plus';
export const UTILS_ICON_FILE_POWERPOINT = 'file-powerpoint';
export const UTILS_ICON_FILE_USER = 'file-user';
export const UTILS_ICON_FILE_WORD = 'file-word';
export const UTILS_ICON_FILE_CHECK = 'file-check';
export const UTILS_ICON_FILTER = 'filter';
export const UTILS_ICON_FOLDER_OPEN = 'folder-open';
export const UTILS_ICON_HOME = 'home';
export const UTILS_ICON_INFO = 'info-circle';
export const UTILS_ICON_INFO_CIRCLE = 'exclamation-circle';
export const UTILS_ICON_LINK = 'link';
export const UTILS_ICON_LOCATION_DOT = 'location-dot';
export const UTILS_ICON_MESSAGES = 'messages';
export const UTILS_ICON_MINUS = 'minus';
export const UTILS_ICON_MISSING = 'times-square';
export const UTILS_ICON_PENCIL = 'pencil';
export const UTILS_ICON_PHONE = 'phone';
export const UTILS_ICON_PROMOTE = 'arrow-up-from-bracket';
export const UTILS_ICON_QUESTION = 'question';
export const UTILS_ICON_REFRESH = 'arrows-rotate';
export const UTILS_ICON_RESET = 'rotate-left';
export const UTILS_ICON_SEARCH = 'search';
export const UTILS_ICON_SHARE = 'share';
export const UTILS_ICON_SHARE_PORTAL = 'share-nodes';
export const UTILS_ICON_SLASH = 'slash';
export const UTILS_ICON_SMS = 'sms';
export const UTILS_ICON_SQUARE_XMARK = 'square-xmark';
export const UTILS_ICON_XMARK = 'xmark';
export const UTILS_ICON_CIRCLE_XMARK = 'circle-xmark';
export const UTILS_ICON_STICKY_NOTE = 'sticky-note';
export const UTILS_ICON_SUCCESS = 'check-circle';
export const UTILS_ICON_SYNC = 'rotate';
export const UTILS_ICON_TASKS = 'tasks';
export const UTILS_ICON_THUMBTACK = 'thumbtack';
export const UTILS_ICON_USER = 'user';
export const UTILS_ICON_WARNING = 'exclamation-triangle';
export const UTILS_ICON_WATCH = 'watch';
export const UTILS_CLOUD_ARROW_DOWN = 'cloud-arrow-down';
export const UTILS_THIN_ARROW_DOWN = 'arrow-down';
export const UTILS_CLOUD_ARROW_UP = 'cloud-arrow-up';
export const UTILS_ICON_CROSS = 'cross';
export const UTILS_ICON_COPY = 'copy';
export const UTILS_ICON_GRID = 'grid-horizontal';
