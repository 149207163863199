<ng-container *ngIf="actionButton">
    <ng-container *hasPrivilege="actionButton.privilege">
        <ng-container [ngSwitch]="actionButton.mode">
            <ng-container *ngSwitchCase="ACTION_BUTTONS_MODE.StandardButton">
                <button
                    *ngIf="isVisible"
                    class="btn {{ btnClass }} d-sm-flex align-items-center"
                    [disabled]="isDisabled"
                    [clmiUiDynamicFormSetup]="actionButton.formSetup"
                    (click)="onActionButtonClicked()"
                >
                    <ng-content select="action-button-standard"></ng-content>
                    {{ actionButton.label }}
                </button>
            </ng-container>
            <ng-container *ngSwitchCase="ACTION_BUTTONS_MODE.AddButton">
                <button
                    class="btn {{
                        btnClass
                    }} d-none d-sm-flex align-items-center"
                    [clmiUiDynamicFormSetup]="actionButton.formSetup"
                    (click)="onActionButtonClicked()"
                >
                    <wdx-icon
                        class="me-1"
                        icon="plus"
                        [size]="size || 'fit'"
                    ></wdx-icon>
                    <span *ngIf="actionButton.label">{{
                        actionButton.label
                    }}</span>
                    <ng-content select="action-button-add"></ng-content>
                </button>
                <atom-icon-button
                    class="d-block d-sm-none"
                    icon="plus"
                    [btnClass]="btnClass"
                    [altText]="actionButton.altText"
                    [cySelector]="actionButton.cySelector"
                    (click)="onActionButtonClicked()"
                ></atom-icon-button>
            </ng-container>
            <ng-container *ngSwitchCase="ACTION_BUTTONS_MODE.FavouriteButton">
                <wdx-toggle-button
                    [size]="size"
                    [toggle]="actionButton.isToggledOn"
                    [resetButtonsFlag]="resetButtonsFlag"
                    [cySelector]="actionButton.cySelector"
                    [isDisabled]="isDisabled"
                    (wdxOnChange)="onActionButtonClicked($event)"
                ></wdx-toggle-button>
            </ng-container>
            <ng-container *ngSwitchCase="ACTION_BUTTONS_MODE.LikeButton">
                <wdx-toggle-button
                    [iconOn]="ICON_UNLIKE"
                    [iconOff]="ICON_LIKE"
                    [size]="size"
                    [toggle]="actionButton.isToggledOn"
                    [cySelector]="actionButton.cySelector"
                    (wdxOnChange)="onActionButtonClicked($event)"
                ></wdx-toggle-button>
            </ng-container>
            <ng-container *ngSwitchCase="ACTION_BUTTONS_MODE.LinkedButton">
                <wdx-toggle-button
                    [iconOn]="ICON_UNLINK"
                    [iconOff]="ICON_LINK"
                    [size]="size"
                    [toggle]="actionButton.isToggledOn"
                    [cySelector]="actionButton.cySelector"
                    (wdxOnChange)="onActionButtonClicked($event)"
                ></wdx-toggle-button>
            </ng-container>
            <ng-container *ngSwitchCase="ACTION_BUTTONS_MODE.IconButton">
                <atom-icon-button
                    *ngIf="isVisible"
                    [size]="size"
                    [icon]="actionButton.icon"
                    [btnClass]="btnClass"
                    [disabled]="isDisabled"
                    [altText]="actionButton.altText"
                    [formSetup]="actionButton.formSetup"
                    [cySelector]="actionButton.cySelector"
                    (onClick)="onActionButtonClicked()"
                ></atom-icon-button>
            </ng-container>
            <ng-container *ngSwitchCase="ACTION_BUTTONS_MODE.UploadButton">
                <input
                    #fileUpload
                    type="file"
                    class="d-none"
                    (change)="onFileSelected($event)"
                />
                <atom-icon-button
                    *ngIf="isVisible"
                    [size]="size"
                    icon="upload"
                    [btnClass]="btnClass"
                    [disabled]="isDisabled"
                    [altText]="actionButton.altText"
                    [cySelector]="actionButton.cySelector"
                    (onClick)="fileUpload.click()"
                ></atom-icon-button>
            </ng-container>
            <ng-container *ngSwitchCase="ACTION_BUTTONS_MODE.DropdownButtonAdd">
                <ng-container
                    *ngTemplateOutlet="
                        dropdownTemplateId;
                        context: {
                            hideDropdownChevron: hideDropdownChevron,
                            btnClass: btnClass
                        }
                    "
                ></ng-container>
            </ng-container>
            <ng-container
                *ngSwitchCase="ACTION_BUTTONS_MODE.DropdownButtonAddSearch"
            >
                <ng-container
                    *ngTemplateOutlet="
                        dropdownTemplateId;
                        context: {
                            hideDropdownChevron: hideDropdownChevron,
                            btnClass: btnClass
                        }
                    "
                ></ng-container>
            </ng-container>
            <ng-container
                *ngSwitchCase="ACTION_BUTTONS_MODE.DropdownButtonMenu"
            >
                <ng-container
                    *ngTemplateOutlet="
                        dropdownTemplateId;
                        context: {
                            hideDropdownChevron: true,
                            btnClass: btnClass + ' btn-icon'
                        }
                    "
                ></ng-container>
            </ng-container>
            <ng-container
                *ngSwitchCase="ACTION_BUTTONS_MODE.DropdownButtonMenuWithLabel"
            >
                <ng-container
                    *ngTemplateOutlet="
                        dropdownTemplateId;
                        context: {
                            hideDropdownChevron: hideDropdownChevron,
                            btnClass: btnClass
                        }
                    "
                ></ng-container>
            </ng-container>
            <ng-container
                *ngSwitchCase="ACTION_BUTTONS_MODE.DropdownButtonIcon"
            >
                <ng-container
                    *ngTemplateOutlet="
                        dropdownTemplateId;
                        context: {
                            hideDropdownChevron: true,
                            btnClass: btnClass + ' btn-icon'
                        }
                    "
                ></ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template
    #dropdownTemplateId
    let-hideDropdownChevron="hideDropdownChevron"
    let-btnClass="btnClass"
>
    <molecule-context-menu-dropdown
        *ngIf="isVisible"
        [size]="dropdownMenuSize"
        [placement]="dropdownPlacement"
        [menu]="dropdownMenu"
        [menuTitle]="actionButton.dropdownMenuTitle"
        [btnClass]="btnClass"
        [altText]="actionButton.altText"
        [buttonStyle]="
            dropdownButtonStyle !== undefined
                ? dropdownButtonStyle
                : DEFAULT_DROPDOWN_BUTTON_STYLE
        "
        [parentContainer]="parentContainer"
        [hasSearch]="
            actionButton.mode === ACTION_BUTTONS_MODE.DropdownButtonAddSearch
        "
        [hideDropdownChevron]="hideDropdownChevron"
        [highlightSelected]="highlightSelectedMenuItem"
        [autoClose]="dropdownAutoClose"
        [disabled]="isDisabled"
        [isLoading]="isLoading"
        [cySelector]="actionButton.cySelector"
        (singleItemSelected)="menuItemSelected($event)"
        (toggled)="onDropdownToggled($event)"
    >
        <ng-container
            *ngIf="
                actionButton.mode === ACTION_BUTTONS_MODE.DropdownButtonAdd ||
                actionButton.mode ===
                    ACTION_BUTTONS_MODE.DropdownButtonAddSearch
            "
        >
            <wdx-icon class="me-1" icon="plus" [size]="size"></wdx-icon>
            <span class="d-none d-sm-block me-1">
                <ng-content></ng-content>
            </span>
        </ng-container>
        <ng-container
            *ngIf="actionButton.mode === ACTION_BUTTONS_MODE.DropdownButtonMenu"
        >
            <wdx-icon-container [size]="size">
                <wdx-icon icon="ellipsis-h" iconStyle="fas"></wdx-icon>
            </wdx-icon-container>
        </ng-container>
        <ng-container
            *ngIf="
                actionButton.mode ===
                ACTION_BUTTONS_MODE.DropdownButtonMenuWithLabel
            "
        >
            <wdx-icon
                *ngIf="actionButton.icon"
                class="me-1"
                [icon]="actionButton.icon"
            ></wdx-icon>
            <span
                >{{ actionButton.label | translateTokenisedString }}&nbsp;</span
            >
        </ng-container>
        <ng-container
            *ngIf="actionButton.mode === ACTION_BUTTONS_MODE.DropdownButtonIcon"
        >
            <wdx-icon-container [size]="size">
                <wdx-icon
                    *ngIf="isVisible"
                    [icon]="actionButton.icon"
                ></wdx-icon>
            </wdx-icon-container>
        </ng-container>
    </molecule-context-menu-dropdown>
</ng-template>
