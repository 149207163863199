import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as answersActions from './answers.actions';
import { AnswersService } from './answers.service';

@Injectable()
export class AnswersEffects {
    private actions$ = inject(Actions);
    private answersService = inject(AnswersService);

    getAnswerSetList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(answersActions.getAnswerSets),
            switchMap(() =>
                this.answersService.getAnswerSetList().pipe(
                    map((answerSets) =>
                        answersActions.getAnswerSetsSuccess({
                            answerSetList: answerSets,
                        }),
                    ),
                    catchError(() => of(answersActions.getAnswerSetsFailure())),
                ),
            ),
        ),
    );

    getAnswers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(answersActions.getAnswers),
            switchMap((action) =>
                this.answersService.getAnswers(action.code).pipe(
                    map((answers) =>
                        answersActions.getAnswersSuccess({
                            code: action.code,
                            answers,
                        }),
                    ),
                    catchError(() =>
                        of(
                            answersActions.getAnswersFailure({
                                code: action.code,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    disableAnswer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(answersActions.disableAnswer),
            switchMap((action) =>
                this.answersService
                    .disableAnswer(action.id, action.isDisabled)
                    .pipe(
                        map(() =>
                            answersActions.disableAnswerSuccess({
                                id: action.id,
                                isDisabled: action.isDisabled,
                            }),
                        ),
                        catchError(() =>
                            of(
                                answersActions.disableAnswerFailure({
                                    id: action.id,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    reorderAnswers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(answersActions.reorderAnswers),
            switchMap((action) =>
                this.answersService
                    .reorderAnswers(
                        action.code,
                        action.answers.map((answer) => answer.name),
                    )
                    .pipe(
                        map(() =>
                            answersActions.reorderAnswersSuccess({
                                code: action.code,
                                answers: action.answers,
                            }),
                        ),
                        catchError(() =>
                            of(
                                answersActions.reorderAnswersFailure({
                                    code: action.code,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );
}
