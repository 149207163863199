<div class="auto-sizing-textarea-duplicate form-control" *ngIf="dynamicHeight">
    {{ commentsText }}
    <!-- This div renders the text the user has input and will resize accordingly.
                    The textarea below is set to use absolute positioning to overlay and resize to match-->
</div>
<textarea
    title="add comment"
    #textArea
    class="no-resize"
    [class.auto-sizing-textarea]="dynamicHeight"
    data-cy="comment-input"
    wdxInputText
    [rows]="rows"
    [placeholder]="placeholder"
    [mentionConfig]="mentionConfig$ | ngrxPush"
    [mentionListTemplate]="mentionListTemplate"
    [ngModel]="commentsText | translateTokenisedString"
    (ngModelChange)="commentTextUpdated($event)"
    (searchTerm)="onSearch($event)"
    (opened)="onOpened()"
    (closed)="onClose()"
></textarea>

<ng-template #mentionListTemplate let-item="item">
    <div
        class="d-flex align-items-center"
        [attr.data-cy]="'mention-item-' + item.name"
    >
        <molecule-avatar
            *ngIf="!item.triggerChar"
            class="me-2"
            size="md"
            [avatar]="item | partyToAvatar"
        ></molecule-avatar>
        <span
            [innerHTML]="
                (!item.triggerChar ? item.name : item.displayName)
                    | translateTokenisedString
                    | safeHtml
            "
        ></span>
    </div>
</ng-template>
