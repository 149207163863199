<ng-template #tipContent>
    <div class="tooltip-content">
        @for (id of identities; track $index) {
            <div class="mb-1">
                <span class="fw-bold"
                    >{{ id.source?.name || id.source?.code }}:</span
                >
                {{ id.identity }}
            </div>
        }
    </div>
</ng-template>

<div [ngbTooltip]="tipContent" container="body">
    <wdx-list delimiter="bullet">
        @for (id of identities; track $index; let last = $last) {
            <li wdxListItem>
                {{ id.source?.name || id.source?.code }}:
                {{ id.identity }}
            </li>
        }
    </wdx-list>
</div>
