import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WdxToastService } from '@wdx/shared/components/wdx-toast';
import {
    Severity,
    TRANSLATION_TOAST_MESSAGE_DOC_ATTACHED_TO_CHECKLIST,
    TRANSLATION_TOAST_MESSAGE_DOC_MARKED_AS_SENT,
    TRANSLATION_TOAST_MESSAGE_DOC_REMOVED_FROM_CHECKLIST,
    TRANSLATION_TOAST_MESSAGE_SUCCESSFULLY,
    TranslationsService,
} from '@wdx/shared/utils';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import * as documentChecklistActions from './document-checklist.actions';
import { DocumentChecklistService } from './document-checklist.service';

@Injectable()
export class DocumentChecklistEffects {
    private actions$ = inject(Actions);
    private toastService = inject(WdxToastService);
    private documentChecklistService = inject(DocumentChecklistService);
    private translationsService = inject(TranslationsService);

    getDocumentRequirements$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentChecklistActions.getDocumentRequirements),
            switchMap((action) =>
                this.documentChecklistService
                    .getDocumentRequirementsCategories(
                        action.entityId,
                        action.entityType,
                        action.data,
                    )
                    .pipe(
                        map((documentRequirements) =>
                            documentChecklistActions.getDocumentRequirementsSuccess(
                                {
                                    entityId: action.entityId,
                                    entityType: action.entityType,
                                    documentRequirements: documentRequirements,
                                },
                            ),
                        ),
                        catchError((error) =>
                            of(
                                documentChecklistActions.getDocumentRequirementsFailure(
                                    {
                                        entityId: action.entityId,
                                        entityType: action.entityType,
                                        error,
                                    },
                                ),
                            ),
                        ),
                    ),
            ),
        ),
    );

    getDocumentRequirementTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentChecklistActions.getDocumentRequirementTypes),
            switchMap((action) =>
                this.documentChecklistService
                    .getDocumentRequirementTypes(action.app)
                    .pipe(
                        map((documentRequirementTypes) =>
                            documentChecklistActions.getDocumentRequirementTypesSuccess(
                                {
                                    documentRequirementTypes,
                                },
                            ),
                        ),
                        catchError((error) =>
                            of(
                                documentChecklistActions.getDocumentRequirementTypesFailure(
                                    {
                                        error,
                                    },
                                ),
                            ),
                        ),
                    ),
            ),
        ),
    );

    addDocumentRequirement$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentChecklistActions.addDocumentRequirement),
            mergeMap((action) =>
                this.documentChecklistService
                    .addDocumentRequirement(
                        action.clientId,
                        action.documentRequirementTypeCode,
                        action.systemEntity,
                        action.relatedId,
                        action.relatedEntity,
                    )
                    .pipe(
                        map(() =>
                            documentChecklistActions.addDocumentRequirementSuccess(
                                {
                                    clientId: action.clientId,
                                    documentRequirementTypeCode:
                                        action.documentRequirementTypeCode,
                                    relatedId: action.relatedId,
                                    relatedEntity: action.relatedEntity,
                                },
                            ),
                        ),
                        catchError((error) => {
                            return of(
                                documentChecklistActions.addDocumentRequirementError(
                                    {
                                        clientId: action.clientId,
                                        documentRequirementTypeCode:
                                            action.documentRequirementTypeCode,
                                        relatedId: action.relatedId,
                                        relatedEntity: action.relatedEntity,
                                        error,
                                    },
                                ),
                            );
                        }),
                    ),
            ),
        ),
    );

    generateDocumentFromTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentChecklistActions.generateDocumentFromTemplate),
            tap((action) => {
                this.toastService.show({
                    body: [
                        `Generating document template${
                            action.payload.length > 1 ? 's' : ''
                        }`,
                    ],
                    severity: Severity.Warning,
                    hideCloseOnBody: true,
                    throbber: true,
                    autohide: false,
                    closeOthers: true,
                });
            }),
            mergeMap((action) =>
                this.documentChecklistService
                    .generateDocumentFromTemplate(action.payload)
                    .pipe(
                        map(() =>
                            documentChecklistActions.generateDocumentFromTemplateSuccess(
                                {
                                    payload: action.payload,
                                },
                            ),
                        ),
                        catchError((error) =>
                            of(
                                documentChecklistActions.generateDocumentFromTemplateError(
                                    {
                                        payload: action.payload,
                                        error,
                                    },
                                ),
                            ),
                        ),
                    ),
            ),
        ),
    );

    generateDocumentFromTemplateSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    documentChecklistActions.generateDocumentFromTemplateSuccess,
                ),
                tap((action) => {
                    this.toastService.removeAll();
                    this.toastService.show({
                        body: [
                            `Document template${
                                action.payload?.length > 1 ? 's' : ''
                            } successfully generated.`,
                        ],
                        severity: Severity.Success,
                        closeOthers: true,
                    });
                }),
            ),
        { dispatch: false },
    );

    approveDocumentRequirement$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentChecklistActions.approveDocumentRequirement),
            mergeMap((action) =>
                this.documentChecklistService
                    .approveDocumentRequirement(
                        action.documentRequirementIds,
                        action.approve,
                    )
                    .pipe(
                        map(() =>
                            documentChecklistActions.approveDocumentRequirementSuccess(
                                {
                                    documentRequirementIds:
                                        action.documentRequirementIds,
                                    approve: action.approve,
                                },
                            ),
                        ),
                        catchError((error) =>
                            of(
                                documentChecklistActions.approveDocumentRequirementError(
                                    {
                                        documentRequirementIds:
                                            action.documentRequirementIds,
                                        error,
                                    },
                                ),
                            ),
                        ),
                    ),
            ),
        ),
    );

    cancelDocumentRequirement$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentChecklistActions.cancelDocumentRequirement),
            mergeMap(({ documentRequirementIds, entityType, entityId }) =>
                this.documentChecklistService
                    .cancelDocumentRequirement(documentRequirementIds)
                    .pipe(
                        map(() =>
                            documentChecklistActions.cancelDocumentRequirementSuccess(
                                {
                                    documentRequirementIds,
                                    entityType,
                                    entityId,
                                },
                            ),
                        ),
                        catchError((error) =>
                            of(
                                documentChecklistActions.cancelDocumentRequirementError(
                                    {
                                        documentRequirementIds,
                                        entityType,
                                        entityId,
                                        error,
                                    },
                                ),
                            ),
                        ),
                    ),
            ),
        ),
    );

    deleteDocumentRequirement$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentChecklistActions.deleteDocumentRequirement),
            mergeMap(({ documentRequirementId, entityType, entityId }) =>
                this.documentChecklistService
                    .deleteDocumentRequirement(documentRequirementId)
                    .pipe(
                        map(() =>
                            documentChecklistActions.deleteDocumentRequirementSuccess(
                                {
                                    documentRequirementId,
                                    entityType,
                                    entityId,
                                },
                            ),
                        ),
                        catchError((error) =>
                            of(
                                documentChecklistActions.deleteDocumentRequirementError(
                                    {
                                        documentRequirementId,
                                        entityType,
                                        entityId,
                                        error,
                                    },
                                ),
                            ),
                        ),
                    ),
            ),
        ),
    );

    createDocumentExternalTask$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentChecklistActions.createDocumentExternalTask),
            mergeMap(
                ({
                    documentRequirementId,
                    entityType,
                    entityId,
                    externalUserPartyId,
                }) =>
                    this.documentChecklistService
                        .createDocumentExternalTask(
                            documentRequirementId,
                            externalUserPartyId,
                        )
                        .pipe(
                            map(() =>
                                documentChecklistActions.createDocumentExternalTaskSuccess(
                                    {
                                        documentRequirementId,
                                        entityType,
                                        entityId,
                                    },
                                ),
                            ),
                            catchError((error) =>
                                of(
                                    documentChecklistActions.createDocumentExternalTaskError(
                                        {
                                            documentRequirementId,
                                            entityType,
                                            entityId,
                                            error,
                                        },
                                    ),
                                ),
                            ),
                        ),
            ),
        ),
    );

    createDocumentExternalTaskSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    documentChecklistActions.createDocumentExternalTaskSuccess,
                ),
                tap(() => {
                    this.toastService.show({
                        body: [
                            this.translationsService.getTranslationByKey(
                                TRANSLATION_TOAST_MESSAGE_SUCCESSFULLY,
                            ),
                        ],
                        severity: Severity.Success,
                        closeOthers: true,
                    });
                }),
            ),
        { dispatch: false },
    );

    sendDocumentRequirement$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentChecklistActions.sendDocumentRequirement),
            mergeMap((action) =>
                this.documentChecklistService
                    .sendDocumentRequirement(action.documentRequirementIds)
                    .pipe(
                        map(() =>
                            documentChecklistActions.sendDocumentRequirementSuccess(
                                {
                                    documentRequirementIds:
                                        action.documentRequirementIds,
                                },
                            ),
                        ),
                        catchError((error) =>
                            of(
                                documentChecklistActions.sendDocumentRequirementError(
                                    {
                                        documentRequirementIds:
                                            action.documentRequirementIds,
                                        error,
                                    },
                                ),
                            ),
                        ),
                    ),
            ),
        ),
    );

    linkDocumentRequirement$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentChecklistActions.linkDocumentToRequirement),
            mergeMap((action) =>
                this.documentChecklistService
                    .linkDocumentToRequirement(
                        action.documentRequirementId,
                        action.documentId,
                        action.isLinked,
                    )
                    .pipe(
                        map(() =>
                            documentChecklistActions.linkDocumentToRequirementSuccess(
                                {
                                    documentRequirementId:
                                        action.documentRequirementId,
                                    documentId: action.documentId,
                                    isLinked: action.isLinked,
                                },
                            ),
                        ),
                        catchError((error) =>
                            of(
                                documentChecklistActions.linkDocumentToRequirementError(
                                    {
                                        documentRequirementId:
                                            action.documentRequirementId,
                                        documentId: action.documentId,
                                        error,
                                    },
                                ),
                            ),
                        ),
                    ),
            ),
        ),
    );

    linkDocumentRequirementSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    documentChecklistActions.linkDocumentToRequirementSuccess,
                ),
                tap((action) => {
                    this.toastService.show({
                        body: [
                            action.isLinked
                                ? this.translationsService.getTranslationByKey(
                                      TRANSLATION_TOAST_MESSAGE_DOC_ATTACHED_TO_CHECKLIST,
                                  )
                                : this.translationsService.getTranslationByKey(
                                      TRANSLATION_TOAST_MESSAGE_DOC_REMOVED_FROM_CHECKLIST,
                                  ),
                        ],
                        severity: Severity.Success,
                        closeOthers: true,
                    });
                }),
            ),
        { dispatch: false },
    );

    sendDocumentRequirementSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(documentChecklistActions.sendDocumentRequirementSuccess),
                tap(() => {
                    this.toastService.show({
                        body: [
                            this.translationsService.getTranslationByKey(
                                TRANSLATION_TOAST_MESSAGE_DOC_MARKED_AS_SENT,
                            ),
                        ],
                        severity: Severity.Success,
                        closeOthers: true,
                    });
                }),
            ),
        { dispatch: false },
    );
}
