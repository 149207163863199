<molecule-input-lookup-dumb
    [isMulti]="isMulti"
    [showReadout]="showReadout"
    [hideGuidanceText]="hideGuidanceText"
    [formDisplayMode]="formDisplayMode"
    [formSummaryMode]="formSummaryMode"
    [inputOnly]="inputOnly"
    [formInputData]="formInputData"
    [lookupIsLoading]="lookupIsLoading$ | ngrxPush"
    [lookupHasError]="lookupHasError$ | ngrxPush"
    [lookupResults]="lookupResults$ | ngrxPush"
    [initialValue]="value"
    [validationErrorMessage]="validationErrorMessage"
    [lookupMode]="lookupMode"
    [placeholder]="placeholder"
    [showSuggestion]="showSuggestion"
    [showNoResultsMessage]="showNoResultsMessage"
    [formData]="formData"
    (modalRegistered)="onModalRegistered($event)"
    (open)="onOpen()"
    (close)="onClose()"
    (search)="onSearch($event)"
    (valueChanged)="onChange($event)"
    (generateDocument)="onGenerateAndDownloadDocument($event)"
    (previewAvailableDocumentData)="onPreviewAvailableDocumentData($event)"
    (deleteItem)="onDeleteItem($event)"
></molecule-input-lookup-dumb>
