import {
    AbstractControl,
    UntypedFormGroup,
    ValidationErrors,
} from '@angular/forms';
import { FormFieldType } from '@wdx/shared/utils';
import { DateTime } from 'luxon';

export class BespokeValidators {
    static getLuxonDate(date: string | Date) {
        return date instanceof Date
            ? DateTime.fromJSDate(date)
            : DateTime.fromISO(date);
    }

    static minArrayLength(
        min: number,
    ): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl): { [key: string]: number } | null => {
            return !Array.isArray(control.value) || control.value?.length >= min
                ? null
                : { minArrayLength: min };
        };
    }

    static maxArrayLength(
        max: number,
    ): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl): { [key: string]: number } | null => {
            if (!control.value) {
                return null;
            }

            return Array.isArray(control.value) && control.value.length <= max
                ? null
                : { maxArrayLength: max };
        };
    }

    static maxCurrencyAmount(
        max: number,
    ): (control: AbstractControl) => ValidationErrors | null {
        return (
            control: AbstractControl,
        ): { [key: string]: { max?: number } } | null => {
            return control.value?.amount > max ? { max: { max } } : null;
        };
    }

    static minCurrencyAmount(
        min: number,
    ): (control: AbstractControl) => ValidationErrors | null {
        return (
            control: AbstractControl,
        ): { [key: string]: { min?: number } } | null => {
            return control.value?.amount < min ? { min: { min } } : null;
        };
    }

    static dateHistoricRange(
        baseDate: string | Date,
        fieldType: FormFieldType,
    ): (control: AbstractControl) => ValidationErrors | null {
        return (
            control: AbstractControl,
        ): { [key: string]: boolean } | null => {
            if (!control.value) {
                return null;
            }

            const CONTROL_VALUE = control.value;
            const BASE_DATE = new Date(baseDate);

            /**
             * Adding 1 minute from the users time to ensure that the server date could be update to 1 minute less
             */
            let controlDateToMillis = new Date(CONTROL_VALUE).getTime();
            let baseDateToMillis = BASE_DATE.getTime() + 60 * 1000;

            if (fieldType === FormFieldType.Date) {
                baseDateToMillis = this.getLuxonDate(BASE_DATE)
                    .startOf('day')
                    .toMillis();
                controlDateToMillis = this.getLuxonDate(CONTROL_VALUE)
                    .startOf('day')
                    .toMillis();
            }

            return controlDateToMillis <= baseDateToMillis
                ? null
                : { dateMustBeBefore: true };
        };
    }

    static dateMustBeToday(control: AbstractControl) {
        if (!control.value) {
            return null;
        }

        const CONTROL_VALUE = control.value;

        const controlDateTime = DateTime.fromISO(CONTROL_VALUE);
        const today = DateTime.now();

        return controlDateTime.toISODate() === today.toISODate()
            ? null
            : { dateMustBeToday: true };
    }

    static dateMustBeBefore(
        baseDate: DateTime,
        errorLabel?: string,
    ): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl): { [key: string]: string } | null => {
            if (!control.value) {
                return null;
            }

            const controlDateTime = DateTime.fromISO(control.value);
            const controlDateToMillis = controlDateTime
                .startOf('day')
                .toMillis();
            const baseDateToMillis = baseDate.startOf('day').toMillis();

            const dateMustBeBefore = errorLabel
                ? errorLabel
                : `Must be before ${baseDate.startOf('day').toLocaleString(DateTime.DATE_FULL)}`;

            return controlDateToMillis < baseDateToMillis
                ? null
                : { dateMustBeBefore };
        };
    }

    static dateMustBeAfter(
        baseDate: DateTime,
        errorLabel?: string,
    ): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl): { [key: string]: string } | null => {
            if (!control.value) {
                return null;
            }

            const controlDateTime = DateTime.fromISO(control.value);
            const controlDateToMillis = controlDateTime
                .startOf('day')
                .toMillis();
            const baseDateToMillis = baseDate.startOf('day').toMillis();
            const dateMustBeAfter = errorLabel
                ? errorLabel
                : `Must be after ${baseDate.toLocaleString(DateTime.DATE_FULL)}`;

            return controlDateToMillis > baseDateToMillis
                ? null
                : { dateMustBeAfter };
        };
    }

    static datesMustBeValidRange(): (
        control: AbstractControl,
    ) => ValidationErrors | null {
        return (
            control: AbstractControl,
        ): { [key: string]: boolean } | null => {
            if (
                !control.value ||
                (!control?.value?.start && !control?.value?.end)
            ) {
                return null;
            }

            const startDateTime = DateTime.fromISO(control?.value?.start);
            const endDateTime = DateTime.fromISO(control?.value?.end);

            return startDateTime.isValid &&
                endDateTime.isValid &&
                startDateTime > endDateTime
                ? { datesMustBeValidRange: true }
                : null;
        };
    }

    static regex(
        pattern: string,
        message?: string | null,
    ): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl): { [key: string]: string } | null => {
            if (!control.value) {
                return null;
            }

            const matches = String(control.value).match(pattern);

            return matches ? null : { regex: message || pattern };
        };
    }

    static equalTo(
        formGroup: UntypedFormGroup,
        compareTo: string,
    ): (control: AbstractControl) => ValidationErrors | null {
        return (
            control: AbstractControl,
        ): { [key: string]: boolean } | null => {
            const controlToCompare = formGroup.controls[compareTo];
            return control.value === controlToCompare.value
                ? null
                : { equalTo: true };
        };
    }

    static mustContainRequiredFields(
        requiredKeys: string[],
    ): (control: AbstractControl) => ValidationErrors | null {
        return (
            control: AbstractControl,
        ): { [key: string]: boolean } | null => {
            if (!control.value) {
                return null;
            }
            const hasIncompleteFields = requiredKeys.some(
                (requiredKey) => !control.value[requiredKey],
            );
            return hasIncompleteFields
                ? { mustContainRequiredFields: true }
                : null;
        };
    }

    static isTrue(): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl): { [key: string]: boolean } | null =>
            control.value ? null : { isTrue: true };
    }
}
