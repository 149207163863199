import { Injectable, TemplateRef } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormFrameworkEvent } from '@wdx/shared/infrastructure/form-framework';
import { DYNAMIC_FORM, KeyValueObject } from '@wdx/shared/utils';
import { Subject } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import {
    DEFAULT_MODAL_OPTIONS,
    MAIN_NAVIGATION_MODAL_ID,
    ModalOptions,
} from './modal.model';

export type TypedKeyValueObject<T> = { [key: string]: T };

@Pipe({ name: 'randomString' })
export class RandomStringPipe implements PipeTransform {
    transform(length = 20): string {
        return Array.from({ length })
            .map(() => String.fromCharCode(Math.random() * 26 + 65))
            .join('');
    }
}

@Injectable()
export class ModalManagementService {
    private templateStore: {
        [key: string]: {
            modalTemplate: TemplateRef<any>;
            size: string | undefined;
            side: string | undefined;
        };
    } = {};
    private modalStore: TypedKeyValueObject<NgbModalRef> = {};
    private paramStore: KeyValueObject = {};
    private activeInstanceIds: string[] = [];

    latestInstanceParams$ = new Subject<KeyValueObject>();
    activeModalClosed$ = new Subject<string>();

    readonly BACKDROP_CLASS_PREFIX = 'ngb-backdrop-';
    readonly WINDOW_CLASS_PREFIX = 'ngb-window-';
    readonly FADE_IN_CLASS = 'fade-in';
    readonly DYNAMIC_FORM = DYNAMIC_FORM;

    constructor(
        private modalService: NgbModal,
        private router: Router,
    ) {}

    registerModal(
        modalTemplate: TemplateRef<any>,
        modalId: string,
        size?: string,
        side?: string,
    ): void {
        if (Object.keys(this.templateStore).includes(modalId)) {
            // eslint-disable-next-line no-console
            console.error(`${modalId} has already been registered`);
            return;
        }

        this.templateStore = {
            ...this.templateStore,
            [modalId]: {
                modalTemplate,
                size,
                side,
            },
        };
    }

    deregisterModal(modalId: string): void {
        delete this.templateStore[modalId];
        this.templateStore = { ...this.templateStore };
    }

    openModalWithComponent(
        component: any,
        customModalOptions: ModalOptions = {},
    ): NgbModalRef {
        const instanceId = new RandomStringPipe().transform();

        const options = {
            ...DEFAULT_MODAL_OPTIONS,
            ...customModalOptions,
        };

        const modalReference = this.modalService.open(component, {
            size: options.size,
            beforeDismiss: () => Boolean(this.closeActiveModal()),
            backdropClass: 'modal-backdrop--ngb',
            modalDialogClass: 'modal-dialog-transition',
            animation: false,
            backdrop: 'static',
        });

        this.modalStore = {
            ...this.modalStore,
            [instanceId]: modalReference,
        };

        this.activeInstanceIds = [...this.activeInstanceIds, instanceId];
        modalReference.componentInstance.modalInstanceId = instanceId;

        return modalReference;
    }

    openModalWithId(modalId: string, params?: KeyValueObject): NgbModalRef {
        const instanceId = new RandomStringPipe().transform();

        const modalParams = {
            modalId,
            instanceId,
            ...(params ? { modalParams: params } : {}),
        };

        const modalReference = this.modalService.open(
            this.templateStore[modalId]?.modalTemplate,
            {
                size: this.templateStore[modalId]?.size,
                beforeDismiss: () => Boolean(this.closeActiveModal()),
                backdropClass: `${this.BACKDROP_CLASS_PREFIX}${instanceId} custom-fade`,
                windowClass: `${this.WINDOW_CLASS_PREFIX}${instanceId} ${this.templateStore[modalId]?.side} custom-fade`,
                animation: false,
                ...(modalId !== MAIN_NAVIGATION_MODAL_ID
                    ? { backdrop: 'static' }
                    : {}),
            },
        );

        this.modalStore = {
            ...this.modalStore,
            [instanceId]: modalReference,
        };

        this.paramStore = {
            ...this.paramStore,
            [instanceId]: modalParams,
        };

        this.animateModalIn(instanceId);

        this.setModalRouting(modalParams);

        this.activeInstanceIds = [...this.activeInstanceIds, instanceId];

        this.latestInstanceParams$.next(modalParams);

        return modalReference;
    }

    closeModalWithId(modalId: string, event?: FormFrameworkEvent): void {
        const modalParams = Object.values(this.paramStore)
            .reverse()
            .find((param) => param.modalId === modalId);
        if (!modalParams) {
            return;
        }

        this.closeModalWithInstanceId(modalParams.instanceId, event);
    }

    closeModalWithInstanceId(
        instanceId?: string,
        event?: FormFrameworkEvent,
    ): void {
        if (!instanceId) {
            return;
        }

        const modalRef = this.modalStore[instanceId];
        if (!modalRef) {
            return;
        }

        this.animateModalOut(instanceId);
        this.clearModalRouting();
        setTimeout(() => {
            modalRef.close(event);
        }, 300);
    }

    closeActiveModal(event?: FormFrameworkEvent): string | undefined {
        if (!this.activeInstanceIds?.length) {
            return;
        }

        const lastActiveInstanceId = this.activeInstanceIds.pop();

        this.closeModalWithInstanceId(lastActiveInstanceId, event);

        return lastActiveInstanceId;
    }

    closeAllModals(): void {
        this.activeInstanceIds.forEach((instanceId) => {
            this.closeModalWithInstanceId(instanceId);
        });
        this.activeInstanceIds = [];
    }

    isActiveModal(id: string): boolean {
        const length: number = this.activeInstanceIds.length - 1;
        return this.paramStore[this.activeInstanceIds[length]].modalId === id;
    }

    getActiveModalParams() {
        const length: number = this.activeInstanceIds.length - 1;
        return this.paramStore[this.activeInstanceIds[length]].modalParams;
    }

    animateModalIn(instanceId: string): void {
        const modalBackdrop = document.getElementsByClassName(
            `${this.BACKDROP_CLASS_PREFIX}${instanceId}`,
        );
        const modalWindow = document.getElementsByClassName(
            `${this.WINDOW_CLASS_PREFIX}${instanceId}`,
        );

        setTimeout(() => {
            if (modalBackdrop.length) {
                modalBackdrop[0].classList.add(this.FADE_IN_CLASS);
            }
            if (modalWindow.length) {
                modalWindow[0].classList.add(this.FADE_IN_CLASS);
            }
        }, 1);
    }

    animateModalOut(instanceId: string): void {
        const modalBackdrop = document.getElementsByClassName(
            `${this.BACKDROP_CLASS_PREFIX}${instanceId}`,
        );
        const modalWindow = document.getElementsByClassName(
            `${this.WINDOW_CLASS_PREFIX}${instanceId}`,
        );

        if (modalBackdrop.length) {
            modalBackdrop[0].classList.remove(this.FADE_IN_CLASS);
        }
        if (modalWindow.length) {
            modalWindow[0].classList.remove(this.FADE_IN_CLASS);
        }
    }

    setModalRouting(modalParams?: KeyValueObject): void {
        if (!this.activeInstanceIds.length) {
            setTimeout(() => {
                this.router.navigateByUrl(
                    this.router.createUrlTree([], {
                        queryParams: {
                            modal:
                                modalParams &&
                                btoa(JSON.stringify(modalParams)),
                        },
                        queryParamsHandling: 'merge',
                        preserveFragment: true,
                    }),
                );
            });
        }
    }

    clearModalRouting(): void {
        const activeNav = this.router.getCurrentNavigation();
        if (activeNav) {
            // Wait for current navigation to complete
            this.router.events
                .pipe(
                    filter((event) => event instanceof NavigationEnd),
                    first(),
                )
                .subscribe(() => {
                    this.setModalRouting(undefined);
                });
        } else {
            this.setModalRouting(undefined);
        }
    }
}
