import { Component } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { currencyMaskNumberConfigOverride } from '@wdx/shared/utils';
import { BaseWdxFormControlClass } from '../../../abstract-classes/base-form-control.class';
import { IFormDynamicData } from '../../../interfaces';

@Component({
    selector: 'wdx-ff-input-control',
    templateUrl: './form-input-control.component.html',
})
export class FormInputControlComponent extends BaseWdxFormControlClass {
    readonly currencyMaskNumberConfigOverride =
        currencyMaskNumberConfigOverride;

    readonly INPUT_GROUP_TYPES = [
        this.FORM_FIELD_TYPE.Text,
        this.FORM_FIELD_TYPE.Password,
        this.FORM_FIELD_TYPE.Decimal,
        this.FORM_FIELD_TYPE.Integer,
    ];

    constructor(
        public override controlContainer: ControlContainer,
        public override dynamicDataService: IFormDynamicData,
    ) {
        super(controlContainer, dynamicDataService);
    }

    public dateBlurEvent() {
        if (!this.formControl?.pristine) {
            this.formControl?.markAsTouched();
        }
    }
}
