export const ACTIVITY_FILE_UNDER_FORM_ID = 'ActivityFileUnderForm';
export const ACTIVITY_NOTES_FORM_ID = 'ActivityNotesForm';
export const ADDRESS_CHANGE_FORM_ID = 'AddressChangeForm';
export const ADVISER_FORM_ID = 'AdviserForm';
export const ANSWER_FORM_ID = 'AnswerForm';
export const ARTICLE_EXTERNAL_FORM_ID = 'ArticleExternalForm';
export const ARTICLE_FILTER_FORM_ID = 'ArticleFilterForm';
export const ARTICLE_INTERNAL_FORM_ID = 'ArticleInternalForm';
export const ARTICLE_KNOWLEDGE_FORM_ID = 'ArticleKnowledgeForm';
export const BENCHMARK_FORM_ID = 'BenchmarkForm';
export const CAMPAIGN_FORM_ID = 'CampaignForm';
export const EVENT_FORM_ID = 'EventForm';
export const EVENT_PARTICIPATION_FORM_ID = 'EventParticipationForm';
export const CAMPAIGN_RESPONSE_FORM_ID = 'CampaignResponseForm';
export const CASE_ACCOUNT_MAINTENANCE_FORM = 'CaseAccountMaintenanceForm';
export const CASE_ENQUIRY_FORM_ID = 'CaseEnquiryForm';
export const CASE_FILTER_FORM_ID = 'CaseFilterForm';
export const CASE_FORM_ID = 'CaseForm';
export const CASE_TEAM_MEMBER_FORM_ID = 'CaseTeamMemberForm';
export const CLIENT_PRODUCT_TEAM_MEMBER_FORM_ID = 'ClientProductTeamMemberForm';
export const CLIENT_ROLE_FORM_ID = 'ClientRoleForm';
export const COMPLAINT_FORM_ID = 'ComplaintForm';
export const DOCUMENT_FORM_ID = 'DocumentForm';
export const DOCUMENT_REQUIREMENT_TYPE_FORM_ID = 'DocumentRequirementTypeForm';
export const DOCUMENT_TEMPLATE_FORM_ID = 'DocumentTemplateForm';
export const DOCUMENT_TYPE_FORM_ID = 'DocumentTypeForm';
export const DOCUMENTS_FILTER_FORM_ID = 'FiledDocumentFilterForm';
export const DRIVERS_LICENSE_FORM_ID = 'DriversLicenseForm';
export const EMAIL_FORM_ID = 'EmailForm';
export const ENVELOPE_FORM_ID = 'EnvelopeForm';
export const ENVELOPE_RECIPIENT_FORM_ID = 'EnvelopeRecipientForm';
export const EXTERNAL_USER_FORM_ID = 'ExternalUserForm';
export const EXTERNAL_TASK_FORM_ID = 'ExternalTaskForm';
export const FAMILY_FORM_ID = 'FamilyForm';
export const FEE_STRUCTURE_FORM_ID = 'FeeStructureForm';
export const FORMPIPE_DOCUMENTS_FILTER_FORM_ID = 'FormPipeDocumentFilterForm';
export const GLIA_FORM_ID = 'GliaForm';
export const IDENTITY_CHECK_APPROVAL_FORM_ID = 'IdentityCheckApprovalForm';
export const INBOX_FILTER_FORM_ID = 'InboxFilterForm';
export const INTERMEDIARY_FORM_ID = 'IntermediaryForm';
export const LEAD_FORM_ID = 'LeadForm';
export const LIST_FORM_ID = 'ListForm';
export const MAILING_INSTRUCTION_ID = 'MailingInstructionForm';
export const MANDATE_FORM_ID = 'MandateForm';
export const MANDATE_GROUP_FORM_ID = 'MandateGroupForm';
export const MANUAL_ADDRESS_FORM_ID = 'ManualAddressForm';
export const MEETING_FORM_ID = 'MeetingForm';
export const MERGE_PERSON_FORM_ID = 'MergeDuplicateForm';
export const MERGE_ORGANISATION_FORM_ID = 'MergeDuplicateOrganisationForm';
export const NOTE_FORM_ID = 'NoteForm';
export const OBJECTIVE_FORM_ID = 'ObjectiveForm';
export const ONBOARDING_FORM_ID = 'OnboardingForm';
export const OPPORTUNITY_FILTER_FORM_ID = 'OpportunityFilterForm';
export const OPPORTUNITY_FORM_ID = 'OpportunityForm';
export const OPPORTUNITY_LOST_FORM_ID = 'OpportunityLostForm';
export const OPPORTUNITY_TEAM_MEMBER_FORM_ID = 'OpportunityTeamMemberForm';
export const OPPORTUNITY_WON_FORM_ID = 'OpportunityWonForm';
export const ORGANISATION_CONNECTION_FORM_ID = 'OrganisationConnectionsForm';
export const ORGANISATION_FORM_ID = 'OrganisationForm';
export const ORGANISATION_LEAD_FORM_ID = 'OrganisationLeadForm';
export const OTHER_DOCUMENT_FORM_ID = 'OtherDocumentForm';
export const OXFORD_RISK_FORM_ID = 'OxfordRiskForm';
export const PARTY_TEAM_MEMBER_FORM_ID = 'PartyTeamMemberForm';
export const PASSPORT_FORM_ID = 'PassportForm';
export const PEP_AND_SANCTIONS_FORM_ID = 'PepAndSanctionsForm';
export const PERSON_FORM_ID = 'PersonForm';
export const PHONECALL_FORM_ID = 'PhoneCallForm';
export const PRODUCT_FORM_ID = 'ProductForm';
export const PRODUCT_PROVIDER_FORM_ID = 'ProductProviderForm';
export const PROFESSIONAL_FORM_ID = 'ProfessionalConnectionsForm';
export const QUEUES_FILTER_FORM_ID = 'QueueItemFilterForm';
export const REGION_FORM_ID = 'RegionForm';
export const RISK_LEVELS_FORM_ID = 'RiskLevelForm';
export const SALUTATION_FORM_ID = 'SalutationForm';
export const SECURE_MESSAGE_FORM_ID = 'SecureMessageForm';
export const SMS_FORM_ID = 'MessageForm';
export const TAG_FORM_ID = 'TagForm';
export const TASK_FILTER_FORM_ID = 'TaskFilterForm';
export const TASK_FORM_ID = 'TaskForm';
export const TEAM_FORM_ID = 'TeamForm';
export const TEAM_MEMBER_FORM_ID = 'TeamMemberForm';
export const UNBLU_FORM_ID = 'UnbluForm';
export const USER_FORM_ID = 'UserForm';
export const VIEW_TILE_FORM_ID = 'ViewTileForm';
export const WORK_ITEM_FORM_ID = 'WorkItemForm';
export const USER_PROFILE_FORM_ID = 'UserProfileForm';
export const BULK_UPDATE_NEXT_REVIEW_DATE_FORM_ID =
    'BulkUpdateNextReviewDateForm';
export const IDENTITY_CHECK_FORM_ID = 'IdentityCheckForm';
