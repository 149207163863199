/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum EntityPermissionType {
  View = 'View',
  Create = 'Create',
  Edit = 'Edit',
  Delete = 'Delete',
  Reactivate = 'Reactivate',
  ManageRecordAccess = 'ManageRecordAccess',
  Download = 'Download',
  File = 'File',
  Import = 'Import',
  Export = 'Export',
  AddToList = 'AddToList'
}
