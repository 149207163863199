import { Directive, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { KeyValueObject } from '@wdx/shared/utils';
import { Subject } from 'rxjs';
import { PluralStringPipe } from '../pipes/plural-string.pipe';
import { EncodedQueryParamsService } from '../services/encoded-query-params.service';
import { DynamicFormsManagementService, ModalManagementService } from '@wdx/clmi/ui';
import { OffcanvasManagementService } from '../services/offcanvas-management.service';
import { FilterWrapperService } from '../shared/services/resolvers/filter';
import * as rootReducer from '../state/_setup/reducers';

@Directive({
    selector: '[BaseDiDependantClassDirective]', //selector is required to allow tests to work
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class BaseDiDependantClass implements OnDestroy {
    actions$: Actions;
    dynamicFormsManagementService: DynamicFormsManagementService;
    elementRef: ElementRef;
    encodedQueryParamsService: EncodedQueryParamsService;
    modalManagementService: ModalManagementService;
    offcanvasManagementService: OffcanvasManagementService;
    renderer: Renderer2;
    route: ActivatedRoute;
    router: Router;
    fwService: FilterWrapperService;
    store$: Store<rootReducer.State>;

    destroyed$ = new Subject<boolean>();

    patchInjectedItems(items: KeyValueObject): void {
        Object.keys(items).forEach((key) => (this[key] = items[key]));
    }

    hasMissingDependencies(
        methodName: string,
        ...dependencies: string[]
    ): boolean {
        const missingDependencies = [...dependencies].filter(
            (dependency) => !this[dependency]
        );

        if (missingDependencies.length) {
            // eslint-disable-next-line no-console
            console.error(
                `${methodName}() cannot be used without passing dependencies in the patchInjectedItems() call of this class.`
            );
            // eslint-disable-next-line no-console
            console.error('EG: patchInjectedItems({ ... });');
            // eslint-disable-next-line no-console, max-len
            console.error(
                `Missing ${new PluralStringPipe().transform(
                    'dependency is',
                    missingDependencies.length,
                    'dependencies are'
                )}: ${missingDependencies.join(', ')}`
            );

            return true;
        }

        return false;
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
