import { Component, ElementRef, Input } from '@angular/core';

import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ContactPointType } from '@wdx/clmi/clmi-swagger-gen';
import { PartyToAvatarPipe } from '@wdx/clmi/utils/pipes';
import { dynamicFormsActions } from '@wdx/shared/infrastructure/form-framework';
import {
    DisplayDatePipe,
    KeyValueObject,
    TitleCasePipe,
    TranslationsService,
    WdxDateFormat,
    WdxDateTimeService,
} from '@wdx/shared/utils';
import { BaseInputClass } from '../../../classes/base-input-class';
import { InfoCardData } from '../../../models/info-card-data.model';
import { InfoCardExtendedData } from '../../../models/info-card-extended-data.model';
import { obfuscateTextPipe } from '../../../pipes/obfuscate-text.pipe';
import { ModalManagementService } from '@wdx/clmi/ui';
import * as rootReducer from '../../../state/_setup/reducers';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-info-card-check-duplications',
    templateUrl: './molecule-info-card-check-duplications.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'd-flex flex-column h-100 overflow-hidden' },
})
export class MoleculeInfoCardCheckDuplicationsComponent extends BaseInputClass {
    @Input() infoCards: InfoCardData[];
    @Input() isLoading: boolean;
    @Input() hasError: boolean;
    @Input() duplicates: any;
    @Input() formId: string;
    @Input() entityId: string;
    @Input() entityType: string;
    @Input() formData: any;

    readonly IGNORE_DUPLICATE = 'Ignore';

    formValuesToSubmit$: Observable<KeyValueObject>;

    isSaving: boolean;

    constructor(
        elementRef: ElementRef,
        actions$: Actions,
        store$: Store<rootReducer.State>,
        modalManagementService: ModalManagementService,
        private translationsService: TranslationsService,
        private dateTimeService: WdxDateTimeService,
    ) {
        super();

        this.patchInjectedItems({
            elementRef,
            actions$,
            store$,
            modalManagementService,
        });
    }

    duplicatesAsExtendedInfoCards(duplicates: any): InfoCardExtendedData[] {
        return duplicates
            ?.map((duplicate) => ({
                ...duplicate,
                id: duplicate.entityId,
            }))
            .map(
                (duplicate) =>
                    ({
                        infoCard: {
                            data: duplicate,
                            openInNewTab: !duplicate.isRestricted,
                            entityType: duplicate?.entityType,
                            entityId: duplicate?.entityId,
                            primaryInfo: duplicate?.isRestricted
                                ? '**Restricted**'
                                : duplicate.name,
                            secondaryInfo: {
                                label: duplicate?.leadSince
                                    ? this.translationsService.getTranslationByKey(
                                          'LIST_LEAD_CREATED',
                                          this.dateTimeService.convertDateToViewFriendlyFormat(
                                              duplicate.leadSince,
                                              {
                                                  format: WdxDateFormat.RelativeDate,
                                              },
                                          ),
                                      )
                                    : duplicate?.partyType
                                      ? [
                                            new TitleCasePipe().transform(
                                                duplicate.partyType,
                                            ),
                                        ]
                                      : 'Prospect',
                            },
                            tertiaryInfo: [
                                ...(duplicate?.dateOfBirth
                                    ? [
                                          {
                                              label: duplicate.isRestricted
                                                  ? `D.O.B ${new obfuscateTextPipe().transform(
                                                        new DisplayDatePipe().transform(
                                                            duplicate.dateOfBirth,
                                                            { format: 'DD' },
                                                        ),
                                                        4,
                                                    )}`
                                                  : `D.O.B ${new DisplayDatePipe().transform(
                                                        duplicate.dateOfBirth,
                                                        { format: 'DD' },
                                                    )}`,
                                          },
                                      ]
                                    : []),

                                ...(duplicate?.primaryContactPoint &&
                                duplicate?.primaryContactPoint
                                    .contactPointType === ContactPointType.Phone
                                    ? [
                                          {
                                              label: duplicate.isRestricted
                                                  ? `Mobile: ${new obfuscateTextPipe().transform(
                                                        duplicate
                                                            .primaryContactPoint
                                                            .name,
                                                        3,
                                                    )}`
                                                  : `Mobile: ${duplicate.primaryContactPoint.name}`,
                                          },
                                      ]
                                    : []),

                                ...(duplicate?.primaryContactPoint &&
                                duplicate?.primaryContactPoint
                                    .contactPointType === ContactPointType.Email
                                    ? [
                                          {
                                              label: duplicate.isRestricted
                                                  ? `Email: ${new obfuscateTextPipe().transform(
                                                        duplicate
                                                            .primaryContactPoint
                                                            .name,
                                                        4,
                                                    )}`
                                                  : `Email: ${duplicate.primaryContactPoint.name}`,
                                          },
                                      ]
                                    : []),

                                ...(duplicate?.registrationNumber
                                    ? [
                                          {
                                              label: `Registration: ${duplicate?.registrationNumber}`,
                                          },
                                      ]
                                    : []),
                            ],
                        },
                        avatars: [
                            new PartyToAvatarPipe().transform(
                                duplicate,
                                false,
                                !duplicate?.isRestricted,
                            ),
                        ],
                        secondaryAvatars: duplicate?.owner
                            ? [
                                  new PartyToAvatarPipe().transform(
                                      duplicate?.owner,
                                  ),
                              ]
                            : [],
                    }) as InfoCardExtendedData,
            );
    }

    onIgnoreDuplicates() {
        if (this.formData) {
            this.isSaving = true;
            this.store$.dispatch(
                dynamicFormsActions.updateStandardFormData({
                    formId: this.formId,
                    entityId: '',
                    formData: this.formData,
                    publish: false,
                    ignoreDuplicates: true,
                }),
            );
        }

        this.listenTo(
            dynamicFormsActions.updateStandardFormDataSuccess,
        ).subscribe(() => this.modalManagementService.closeAllModals());
    }
}
