/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum RelationshipStatus {
  Inactive = 'Inactive',
  Active = 'Active',
  Draft = 'Draft',
  Proposed = 'Proposed',
  PendingInactive = 'PendingInactive'
}
