<ul
    class="list-group list-group-flush h-100 overflow-hidden d-flex flex-column"
    [formGroup]="form"
>
    <li
        class="list-group-item d-flex flex-shrink-0 justify-content-between align-items-center"
    >
        <h5>{{ FILTER_LABEL | translate }}</h5>

        <section class="d-flex">
            <button
                class="btn btn-outline-primary ms-2"
                data-cy="query-builder-cancel-btn"
                (click)="clearAndReturnToViews()"
            >
                {{ CANCEL_BTN | translate }}
            </button>
            <button
                class="btn btn-outline-primary ms-2"
                [disabled]="expressions.invalid"
                data-cy="btn-apply-filter"
                (click)="applyFilter(true)"
            >
                {{ APPLY_BTN | translate }}
            </button>

            <button
                class="btn btn-primary ms-2"
                [disabled]="form.invalid"
                data-cy="btn-save-filter"
                (click)="saveView()"
            >
                {{ SAVE_BTN | translate }}
            </button>
        </section>
    </li>
    <li class="list-group-item bg-muted d-flex flex-column flex-shrink-0">
        <input
            type="text"
            [placeholder]="PLACEHOLDER_NEW_FILTER | translate"
            class="mb-2"
            wdxInputText
            formControlName="name"
            data-cy="input-name-new-filter"
        />
        <section
            class="d-flex justify-content-between align-items-center"
            [class.d-none]="viewBuilderResults$ | ngrxPush"
        >
            <wdx-dropdown ngbDropdown container="body">
                <atom-add-button
                    type="button"
                    data-cy="dropdown-add-condition"
                    ngbDropdownToggle
                >
                    {{ ADD_CONDITION | translate }}
                </atom-add-button>

                <wdx-dropdown-menu ngbDropdownMenu>
                    @for (
                        definition of definitions$ | ngrxPush;
                        track definition.name
                    ) {
                        <a
                            class="pointer"
                            wdxDropdownItem
                            (click)="addNewQuery(definition)"
                        >
                            {{ definition?.displayName?.value }}
                        </a>
                    }
                </wdx-dropdown-menu>
            </wdx-dropdown>
        </section>
    </li>
    <li
        class="flex-grow-1 overflow-hidden"
        formArrayName="expressions"
        [class.d-none]="viewBuilderResults$ | ngrxPush"
    >
        <section class="h-100 overflow-auto">
            @for (
                expressionForm of expressions.controls;
                track expressionForm;
                let i = $index
            ) {
                <ng-container
                    *ngIf="!expressionViewData[i]?.contextual"
                    [formGroup]="expressionForm"
                >
                    <li class="list-group-item">
                        <section
                            class="d-flex justify-content-between align-items-center"
                        >
                            <section>
                                <span class="small text-muted mb-1">
                                    {{
                                        i === 0
                                            ? (FILTER_WHERE | translate)
                                            : (FILTER_AND | translate)
                                    }}
                                </span>

                                <div class="mb-2 d-flex align-items-center">
                                    <span class="me-2">
                                        {{
                                            expressionViewData[i]?.displayName
                                                ?.value
                                        }}
                                    </span>

                                    <wdx-dropdown ngbDropdown container="body">
                                        <button
                                            ngbDropdownToggle
                                            background
                                            class="btn btn-outline-primary"
                                            data-cy="query-builder-dropdown"
                                        >
                                            {{
                                                FILTER_OPERATOR_PREFIX +
                                                    expressionForm.controls
                                                        ?.operator?.value
                                                    | translate
                                            }}
                                        </button>
                                        <wdx-dropdown-menu ngbDropdownMenu>
                                            @for (
                                                opt of expressionViewData[i]
                                                    ?.operators;
                                                track opt
                                            ) {
                                                <label
                                                    class="d-inline-flex justify-content-between align-items-center pointer"
                                                    wdxDropdownItem
                                                    [class.active]="
                                                        expressionForm.controls
                                                            ?.operator
                                                            ?.value === opt
                                                    "
                                                >
                                                    <span
                                                        class="flex-grow-1 text-truncate"
                                                    >
                                                        {{
                                                            FILTER_OPERATOR_PREFIX +
                                                                opt | translate
                                                        }}
                                                    </span>

                                                    <input
                                                        hidden
                                                        type="radio"
                                                        [value]="opt"
                                                        formControlName="operator"
                                                    />

                                                    <wdx-icon
                                                        *ngIf="
                                                            expressionForm
                                                                .controls
                                                                ?.operator
                                                                ?.value === opt
                                                        "
                                                        size="sm"
                                                        class="flex-shrink-0 ms-2"
                                                        icon="check"
                                                    ></wdx-icon>
                                                </label>
                                            }
                                        </wdx-dropdown-menu>
                                    </wdx-dropdown>
                                </div>
                            </section>

                            <section class="align-self-start">
                                <atom-icon-button
                                    btnClass="btn"
                                    size="sm"
                                    icon="trash-alt"
                                    (click)="deleteExpression(i)"
                                ></atom-icon-button>
                            </section>
                        </section>

                        <section class="d-flex flex-wrap">
                            @if (
                                !(
                                    NO_VALUE_OPERATORS
                                    | arrayIncludes
                                        : expressionForm.controls?.operator
                                              ?.value
                                )
                            ) {
                                @let viewData = expressionViewData[i];
                                @let fieldType = viewData.type;
                                @if (
                                    fieldType === FilterFieldType.Lookup ||
                                    fieldType === FilterFieldType.LookupMultiple
                                ) {
                                    <ng-container formArrayName="values">
                                        @for (
                                            val of arrayMultiSelectData[i]
                                                ?.data;
                                            track val;
                                            let childIndex = $index
                                        ) {
                                            <wdx-info-card
                                                background
                                                class="me-1 mb-1"
                                                [flush]="true"
                                            >
                                                <div wdxInfoCardLabel>
                                                    {{ val?.name }}
                                                </div>
                                                <div wdxInfoCardTrailing>
                                                    <atom-icon-button
                                                        btnClass="btn"
                                                        size="sm"
                                                        icon="times"
                                                        (click)="
                                                            deleteExpressionValue(
                                                                i,
                                                                childIndex,
                                                                val,
                                                                'id'
                                                            )
                                                        "
                                                    ></atom-icon-button>
                                                </div>
                                            </wdx-info-card>
                                        }

                                        <button
                                            data-cy="btn-ellipsis"
                                            background
                                            class="btn m-1"
                                            (click)="
                                                addDynamicComponent(i, viewData)
                                            "
                                        >
                                            <wdx-icon
                                                icon="ellipsis-h"
                                                iconStyle="fas"
                                            ></wdx-icon>
                                        </button>
                                    </ng-container>
                                } @else {
                                    <ng-container formArrayName="values">
                                        @for (
                                            valuesForm of getValues(i).controls;
                                            track valuesForm;
                                            let j = $index
                                        ) {
                                            @if (
                                                fieldType ===
                                                    FilterFieldType.Select ||
                                                fieldType ===
                                                    FilterFieldType.Answer ||
                                                fieldType ===
                                                    FilterFieldType.Enum
                                            ) {
                                                <clmi-filter-select
                                                    class="w-100"
                                                    data-cy="query-builder-filter-select"
                                                    [definition]="viewData"
                                                    [formControlNameOrIndex]="j"
                                                    [options]="
                                                        viewData.possibleValues
                                                    "
                                                    [multiple]="
                                                        viewData.multiOperator !==
                                                        false
                                                    "
                                                ></clmi-filter-select>
                                            } @else if (
                                                fieldType ===
                                                    FilterFieldType.String ||
                                                fieldType ===
                                                    FilterFieldType.Search
                                            ) {
                                                <input
                                                    wdxInputText
                                                    type="text"
                                                    data-cy="query-builder-text-input"
                                                    [formControlName]="j"
                                                />
                                            } @else if (
                                                fieldType ===
                                                FilterFieldType.Integer
                                            ) {
                                                <input
                                                    wdxInputText
                                                    type="number"
                                                    data-cy="query-builder-number-input"
                                                    [formControlName]="j"
                                                />
                                            } @else if (
                                                fieldType ===
                                                FilterFieldType.Decimal
                                            ) {
                                                <input
                                                    wdxInputText
                                                    type="number"
                                                    data-cy="query-builder-number-input"
                                                    step="any"
                                                    [formControlName]="j"
                                                />
                                            } @else if (
                                                fieldType ===
                                                FilterFieldType.Currency
                                            ) {
                                                <clmi-filter-currency
                                                    [cySelector]="
                                                        'query-builder-filter-currency'
                                                    "
                                                    [formControlName]="j"
                                                ></clmi-filter-currency>
                                            } @else if (
                                                fieldType ===
                                                FilterFieldType.Date
                                            ) {
                                                <clmi-filter-date
                                                    class="w-100"
                                                    type="date"
                                                    [operator]="
                                                        expressionForm.controls
                                                            ?.operator?.value
                                                    "
                                                    [formControlObj]="j"
                                                    [cySelector]="
                                                        'query-builder-filter-date'
                                                    "
                                                ></clmi-filter-date>
                                            } @else if (
                                                fieldType ===
                                                FilterFieldType.DateTime
                                            ) {
                                                <clmi-filter-date
                                                    class="w-100"
                                                    type="datetime-local"
                                                    [operator]="
                                                        expressionForm.controls
                                                            ?.operator?.value
                                                    "
                                                    [formControlObj]="j"
                                                    [cySelector]="
                                                        'query-builder-filter-date-local'
                                                    "
                                                ></clmi-filter-date>
                                            } @else if (
                                                fieldType ===
                                                FilterFieldType.Boolean
                                            ) {
                                                <wdx-form-field
                                                    *ngIf="
                                                        viewData?.type ===
                                                        FilterFieldType.Boolean
                                                    "
                                                    labelnomarginbottom
                                                >
                                                    <wdx-radio
                                                        label="True"
                                                        value="true"
                                                        [labelForId]="
                                                            expressionViewData[
                                                                i
                                                            ]?.name + '-true'
                                                        "
                                                        labelnomarginbottom
                                                    >
                                                        <input
                                                            class="form-check-input"
                                                            type="radio"
                                                            data-cy="query-builder-radio-true"
                                                            [value]="true"
                                                            [formControlName]="
                                                                j
                                                            "
                                                        />
                                                    </wdx-radio>

                                                    <wdx-radio
                                                        label="False"
                                                        value="false"
                                                        [labelForId]="
                                                            expressionViewData[
                                                                i
                                                            ]?.name + '-false'
                                                        "
                                                    >
                                                        <input
                                                            class="form-check-input"
                                                            type="radio"
                                                            data-cy="query-builder-radio-false"
                                                            [value]="false"
                                                            [formControlName]="
                                                                j
                                                            "
                                                        />
                                                    </wdx-radio>
                                                </wdx-form-field>
                                            }
                                        }
                                    </ng-container>
                                }
                            }
                        </section>
                    </li>
                </ng-container>
            }
        </section>
    </li>
</ul>

<ng-container #viewContainerRef></ng-container>
