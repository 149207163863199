import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    Article,
    ArticleStatusType,
    HistoryEntry,
    SystemEntity,
    UserActionEntity,
} from '@wdx/clmi/clmi-swagger-gen';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as articlesActions from '../../../../../state/articles/articles.actions';
import * as articlesSelectors from '../../../../../state/articles/articles.selectors';
import * as favouritesActions from '../../../../../state/favourites/favourites.actions';
import * as relatedRecordsActions from '../../../../../state/related-records/related-records.actions';

@Injectable({
    providedIn: 'root',
})
export class ArticlesFacade {
    private store$ = inject(Store);

    getArticle$(articleId: string): Observable<Article> {
        return this.store$
            .select(articlesSelectors.getSingle, {
                id: articleId,
            })
            .pipe(filter((article) => Boolean(article)));
    }

    getArticleIsLoading$(articleId: string): Observable<boolean> {
        return this.store$.select(articlesSelectors.getIsLoadingSingle, {
            id: articleId,
        });
    }

    getArticleHasError$(articleId: string): Observable<boolean> {
        return this.store$.select(articlesSelectors.getHasLoadingSingleError, {
            id: articleId,
        });
    }

    getArticleHistory$(articleId: string): Observable<HistoryEntry[]> {
        return this.store$
            .select(articlesSelectors.getHistoryForIdInfinityCombinedList, {
                id: articleId,
            })
            .pipe(filter((articleHistory) => Boolean(articleHistory)));
    }

    getArticleHistoryHasError$(articleId: string): Observable<boolean> {
        return this.store$.select(
            articlesSelectors.getHistoryForIdHasLoadingPageError,
            { id: articleId },
        );
    }

    loadArticle(articleId: string): void {
        this.store$.dispatch(
            articlesActions.getForId({
                articleId: articleId,
            }),
        );
    }

    loadArticleHistory(articleId: string): void {
        this.store$.dispatch(
            articlesActions.getHistoryForId({
                articleId: articleId,
                reset: true,
            }),
        );
    }

    setStatus(articleId: string, status: ArticleStatusType): void {
        this.store$.dispatch(
            articlesActions.setStatus({
                articleId: articleId,
                status,
            }),
        );
    }

    setLike(articleId: string, isLiked: boolean): void {
        this.store$.dispatch(
            favouritesActions.toggleFavourite({
                id: articleId,
                entity: UserActionEntity.Article,
                newValue: isLiked,
            }),
        );
    }

    setLink(
        articleId: string,
        entityType: SystemEntity,
        entityId: string,
        isLinked: boolean,
    ): void {
        const props = {
            entityType: entityType,
            entityId: entityId,
            articleId: articleId,
        };

        if (isLinked) {
            this.store$.dispatch(
                relatedRecordsActions.createLinkToArticleForEntity(props),
            );
        } else {
            this.store$.dispatch(
                relatedRecordsActions.deleteLinkToArticleForEntity(props),
            );
        }
    }
}
