/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum SelectApiSource {
  None = 'None',
  AnswerSet = 'AnswerSet',
  Activity = 'Activity',
  AmlRisk = 'AmlRisk',
  BankingProduct = 'BankingProduct',
  Benchmark = 'Benchmark',
  CaseType = 'CaseType',
  Channel = 'Channel',
  ClientType = 'ClientType',
  ClientSubType = 'ClientSubType',
  Country = 'Country',
  CountryReferral = 'CountryReferral',
  Currency = 'Currency',
  DocumentType = 'DocumentType',
  ClientProductDocumentTypes = 'ClientProductDocumentTypes',
  MailingInstructionDocumentTypes = 'MailingInstructionDocumentTypes',
  DocumentRequirementType = 'DocumentRequirementType',
  EconomicActivity = 'EconomicActivity',
  FamilyRelationshipType = 'FamilyRelationshipType',
  FeeStructure = 'FeeStructure',
  FormName = 'FormName',
  InsuranceProduct = 'InsuranceProduct',
  InvestmentProduct = 'InvestmentProduct',
  PensionProduct = 'PensionProduct',
  Language = 'Language',
  LendingProduct = 'LendingProduct',
  WealthManagementProduct = 'WealthManagementProduct',
  Mandate = 'Mandate',
  MandateGroup = 'MandateGroup',
  NationalIdentifierType = 'NationalIdentifierType',
  NudgeType = 'NudgeType',
  Objective = 'Objective',
  OrganisationClientType = 'OrganisationClientType',
  OrganisationRelationshipType = 'OrganisationRelationshipType',
  OrganisationToPersonRelationshipType = 'OrganisationToPersonRelationshipType',
  OrganisationToPersonEmploymentRoles = 'OrganisationToPersonEmploymentRoles',
  OrganisationType = 'OrganisationType',
  PersonType = 'PersonType',
  PipelineStages = 'PipelineStages',
  PortfolioProduct = 'PortfolioProduct',
  ProductParentsOnly = 'ProductParentsOnly',
  Product = 'Product',
  ProductFormName = 'ProductFormName',
  ProductType = 'ProductType',
  ProfessionalRelationshipType = 'ProfessionalRelationshipType',
  RelationshipType = 'RelationshipType',
  RelationshipToChild = 'RelationshipToChild',
  RelationshipToParent = 'RelationshipToParent',
  QiStatus = 'QiStatus',
  QueryRollupColumn = 'QueryRollupColumn',
  Region = 'Region',
  RiskLevel = 'RiskLevel',
  Role = 'Role',
  RoleSubType = 'RoleSubType',
  TeamType = 'TeamType',
  Salutation = 'Salutation',
  SystemEntity = 'SystemEntity',
  TagCategory = 'TagCategory',
  Tenant = 'Tenant',
  Title = 'Title',
  UserRole = 'UserRole',
  View = 'View',
  ViewForList = 'ViewForList',
  RoleContactPlays = 'RoleContactPlays',
  AssetTypes = 'AssetTypes',
  LiabilityTypes = 'LiabilityTypes',
  IncomeTypes = 'IncomeTypes',
  ExpenseTypes = 'ExpenseTypes',
  ProtectionTypes = 'ProtectionTypes',
  PersonalWealthSourceTypes = 'PersonalWealthSourceTypes',
  InstitutionalWealthSourceTypes = 'InstitutionalWealthSourceTypes',
  FundsOriginTypes = 'FundsOriginTypes',
  ParentProductCode = 'ParentProductCode',
  SourceSystems = 'SourceSystems',
  ProcessTypeCode = 'ProcessTypeCode',
  WorkItemTypeCode = 'WorkItemTypeCode'
}
