@for (avatar of avatars; track avatar.partyId) {
    <wdx-avatar-container
        *ngIf="avatar"
        [popupEnabled]="popupEnabled"
        (click)="avatarClicked(avatar)"
        wdxStopPropagation
    >
        <wdx-avatar
            wdxAvatar
            [avatar]="avatar"
            size="sm"
            [borderColor]="(avatars?.length || 0) > 1 ? 'light' : undefined"
        ></wdx-avatar>
        <div wdxAvatarPopupContent *ngIf="popupEnabled">
            <wdx-avatar-popup-content
                wdxAvatarPopupContent
                *ngIf="popupContent$ | ngrxPush as content; else loadingTmpl"
                [avatar]="avatar"
                [content]="content"
            ></wdx-avatar-popup-content>
        </div>
    </wdx-avatar-container>
}

@if (avatars?.length === 1 && label) {
    @if (linkClickCallback) {
        <a
            class="ms-2 flex-1"
            (click)="linkClickCallback()"
            data-cy="avatar-cell-callback"
            >{{ label }}</a
        >
    } @else if (url) {
        @if (newTab) {
            <a
                class="ms-2 flex-1"
                [routerLink]="url"
                data-cy="avatar-cell-link"
                target="_blank"
                >{{ label }}</a
            >
        } @else {
            <a
                class="ms-2 flex-1"
                [routerLink]="url"
                data-cy="avatar-cell-link"
                >{{ label }}</a
            >
        }
    } @else {
        <span class="ms-2 flex-1" data-cy="avatar-cell-label">{{ label }}</span>
    }
}

@if (badge && badge.label) {
    <div
        class="badge"
        [ngClass]="badge.color ? 'badge-' + badge.color : ''"
        data-cy="badge-renderer-badge"
    >
        {{ badge.label }}
    </div>
}

<ng-template #loadingTmpl>
    <div class="w-100 d-flex px-5 py-2 mx-5 my-2">
        <wdx-spinner></wdx-spinner>
    </div>
</ng-template>
