export enum FeatureSvg {
    AccessDenied = 'AccessDenied',
    AppNoFamily = 'AppNoFamily',
    AppNoGoals = 'AppNoGoals',
    AppNoProducts = 'AppNoProducts',
    AppNoSourceOfWealth = 'AppNoSourceOfWealth',
    ClientCharity = 'ClientCharity',
    ClientCorporate = 'ClientCorporate',
    ClientIndividual = 'ClientIndividual',
    ClientJoint = 'ClientJoint',
    ClientTrust = 'ClientTrust',
    Comments = 'Comments',
    DashboardNoAccess = 'DashboardNoAccess',
    EmptyFolder = 'EmptyFolder',
    InboxEmpty = 'InboxEmpty',
    NoChatData = 'NoChatData',
    NoData = 'NoData',
    NoListData = 'NoListData',
    PageNotFound = 'PageNotFound',
    SelectDataFromMenu = 'SelectDataFromMenu',
    ThumbsUp = 'ThumbsUp',
    Workspace = 'Workspace',
    Success = 'Success',
    UserSearch = 'UserSearch',
    UserClock = 'UserClock',
    xMark = 'xMark',
}

export type size = 'xs' | 'sm' | 'md' | 'lg';
