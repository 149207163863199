<ng-container [formGroup]="$any(controlContainer?.control)">
    <wdx-form-field
        [locked]="formElement?.isLocked"
        [label]="formElement?.label | translateTokenisedString"
        [guidanceText]="formElement?.guidanceText | translateTokenisedString"
        [isSensitive]="formElement?.isSensitive"
        [isRequired]="formElement?.isRequired"
        [labelForId]="inputId"
        [validationErrorMessage]="
            formControl?.touched
                ? (formControl?.errors | validationErrorMessage)
                : ''
        "
        [warningMessage]="warningText"
    >
        <div class="input-group" [formGroup]="currencyFormGroup">
            <div class="input-group-text">
                {{ currencySymbol }}
            </div>
            <input
                wdxInputText
                currencyMask
                type="text"
                inputmode="decimal"
                formControlName="amount"
                [attr.id]="inputId"
                [attr.data-cy]="'input-' + formElement.name"
                [options]="currencyMaskConfig"
                [attr.placeholder]="placeholder"
                [attr.required]="formElement.isRequired"
                [wdxUtilsDisabled]="
                    isDisabled ||
                    PENDING_CHANGES_LOCK_FIELD.includes(
                        $any(formElement?.pendingChange?.status)
                    )
                "
            />

            <ng-select
                *ngIf="currencies$ | ngrxPush as currencies"
                formControlName="isoCode"
                bindLabel="isoCode"
                [attr.data-cy]="'dropdown-' + formElement.name"
                [items]="currencies"
                [readonly]="
                    isDisabled ||
                    PENDING_CHANGES_LOCK_FIELD.includes(
                        $any(formElement?.pendingChange?.status)
                    )
                "
                [compareWith]="compareSelectedCurrency"
                [clearable]="false"
            ></ng-select>
        </div>
    </wdx-form-field>
</ng-container>
