import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { DUPLICATION_DETECTION_MODAL_ID } from '../../constants/modal.constants';
import { ModalManagementService } from '@wdx/clmi/ui';
import * as duplicationCheckActions from '../../state/duplication-check/duplication-check.actions';

@Injectable()
export class DuplicationCheckEffects {
    private actions$ = inject(Actions);
    private modalManagementService = inject(ModalManagementService);

    openDuplicationCheckModal$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(duplicationCheckActions.openDuplicatesModal),
                tap(() =>
                    this.modalManagementService.openModalWithId(
                        DUPLICATION_DETECTION_MODAL_ID
                    )
                )
            ),
        { dispatch: false }
    );
}
