import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    OnInit,
    Output,
} from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { KeyValueObject, TranslationsService } from '@wdx/shared/utils';
import { filter, take } from 'rxjs/operators';
import { dynamicFormsActions } from '@wdx/shared/infrastructure/form-framework';
import { ModalManagementService } from '../modal-management.service';

@Component({
    selector: 'clmi-ui-quick-create-modal',
    templateUrl: './quick-create-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickCreateModalComponent implements OnInit {
    formTitle!: string;
    formId!: string;
    entityId!: string;
    initialisationParams!: KeyValueObject;
    modalInstanceId?: string;

    @Output() createSuccess = new EventEmitter();

    constructor(
        private actionsSubject$: ActionsSubject,
        private modalManagementService: ModalManagementService,
        private translationService: TranslationsService,
    ) {}

    ngOnInit(): void {
        this.actionsSubject$
            .pipe(
                ofType(
                    ...dynamicFormsActions.formDataCreateAndUpdateSuccessActions,
                ),
                filter((action: any) => action?.formId === this.formId),
                take(1),
            )
            .subscribe(() => this.createSuccess.emit());
    }

    onFormTitleReceived(formTitle: string | null) {
        if(!formTitle) {
            return;
        }
        this.formTitle = formTitle;
    }

    get modalTitle() {
        return !this.formTitle
            ? 'Loading...'
            : this.translationService.translateTokenisedString(this.formTitle);
    }

    @Output() modalClosed = new EventEmitter();

    onClose() {
        const modalId = this.modalManagementService.closeActiveModal();
        if (!modalId) {
            return;
        }
        this.modalManagementService.activeModalClosed$.next(modalId);
        this.modalClosed.emit();
    }
}
