import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import {
    FieldDefinition,
    SelectApiSource,
    UTILS_ICON_CHEVRON_RIGHT,
    UTILS_ICON_DROPDOWN,
} from '@wdx/shared/utils';
import { Subject } from 'rxjs';
import { debounceTime, filter, map, take, takeUntil } from 'rxjs/operators';
import { BaseWdxFormControlClass } from '../../../abstract-classes/base-form-control.class';
import { IFormDynamicData } from '../../../interfaces/form-dynamic-data.interface';
import { ExtendedFieldDefinitionOption } from '../../../models';

@Component({
    selector: 'wdx-ff-hierarchical-control',
    templateUrl: './form-hierarchical-control.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormHierarchicalControlComponent
    extends BaseWdxFormControlClass
    implements OnInit
{
    @ViewChild('hierarchySelect') hierarchySelect!: NgSelectComponent;
    constructor(
        public override controlContainer: ControlContainer,
        public override dynamicDataService: IFormDynamicData,
    ) {
        super(controlContainer, dynamicDataService);
    }

    idLookup$ = new Subject<string>();

    finalLevel!: number;
    finalLevelOptions!: ExtendedFieldDefinitionOption[];
    firstLevelOptions!: ExtendedFieldDefinitionOption[];
    allOptions!: ExtendedFieldDefinitionOption[];
    activeHierarchy: ExtendedFieldDefinitionOption[] = [];
    isHierarchyLookup = false;

    readonly ICON_CHEVRON_DOWN = UTILS_ICON_DROPDOWN;
    readonly ICON_CHEVRON_RIGHT = UTILS_ICON_CHEVRON_RIGHT;

    get hierarchyLookupPlaceholder() {
        return 'Select an option to explore hierarchy';
    }

    get idLookupPlaceholder() {
        return !this.isDisabled ? 'Enter ID' : '';
    }

    get fieldDefinition() {
        return this.formElement as FieldDefinition;
    }

    ngOnInit(): void {
        this.dynamicDataService
            .getSelectApiSourceOptions(
                this.formElement.selectSource as SelectApiSource,
            )
            .pipe(
                take(1),
                map((options) =>
                    options.sort(
                        (a, b) =>
                            a.label?.localeCompare(b.label as string) as number,
                    ),
                ),
            )
            .subscribe((options) => {
                this.allOptions = options;

                this.firstLevelOptions = options.filter(
                    (option) => option.level === 0,
                );

                this.finalLevel = options[options.length - 1].level as number;

                this.finalLevelOptions = options
                    .filter((option) => option.level === this.finalLevel)
                    .sort((a, b) => a.value.localeCompare(b.value))
                    .map((option) => ({
                        ...option,
                        category: this.firstLevelOptions.find((o) =>
                            option.value.startsWith(o.value),
                        )?.label,
                    }));

                this.options$.next(null as any);

                this.subscribeToIdLookup();
            });
    }

    subscribeToIdLookup() {
        this.idLookup$
            .pipe(
                takeUntil(this.destroyed$),
                debounceTime(50),
                filter((searchText) => Boolean(searchText)),
            )
            .subscribe((searchText) => {
                if (searchText?.length > 0) {
                    const options = this.finalLevelOptions.filter((item) => {
                        if (searchText.length === 1) {
                            return (
                                item.value
                                    .toLowerCase()
                                    .indexOf(searchText.toLowerCase()) > -1
                            );
                        }
                        return (
                            item.value
                                .toLowerCase()
                                .indexOf(searchText.toLowerCase()) > -1 ||
                            (item.label as string)
                                .toLowerCase()
                                ?.indexOf(searchText.toLowerCase()) > -1
                        );
                    });
                    this.options$.next(options);
                } else {
                    this.options$.next(null as any);
                }
            });
    }

    closeHieracySelect() {
        this.hierarchySelect.close();
    }

    reset() {
        this.options$.next([]);
        this.activeHierarchy = [];
        this.formControl?.setValue(null);
    }

    onChangeHierarchy(item: ExtendedFieldDefinitionOption) {
        if (item) {
            this.hierarchySelect.searchTerm = '';

            if (item.level === this.finalLevel) {
                this.closeHieracySelect();
            } else {
                this.formControl?.setValue(null);
                this.activeHierarchy.push(item);
                this.options$.next(
                    this.allOptions.filter((option) => {
                        return (
                            option.value.startsWith(item.value) &&
                            option.level === (item.level as number) + 1
                        );
                    }),
                );
            }
        }
    }

    onActiveHierarchyLevelSelect(item: ExtendedFieldDefinitionOption) {
        this.activeHierarchy = this.activeHierarchy.filter(
            (selection) =>
                (selection.level as number) <= (item.level as number),
        );
        this.options$.next(
            this.allOptions.filter((option) => {
                return (
                    option.value.startsWith(item.value) &&
                    option.level === (item.level as number) + 1
                );
            }),
        );
    }

    onClearIdLookup() {
        this.reset();
    }

    onCloseIdLookup() {
        if (!this.formControl?.value) {
            this.reset();
        }
    }

    onClearHierarchy() {
        this.reset();
        this.options$.next(this.firstLevelOptions);
        this.closeHieracySelect();
    }

    onSwitchToHierarchyLookup() {
        this.isHierarchyLookup = true;
        this.reset();
        this.options$.next(this.firstLevelOptions);
    }

    onSwitchToIdLookup() {
        this.isHierarchyLookup = false;
        this.reset();
        this.options$.next(null as any);
    }
}
