export enum ModalSize {
    Sm = 'sm',
    Md = 'md',
    Lg = 'lg',
    Xl = 'xl',
}

export enum ModalSide {
    Left = 'left',
    Right = 'right',
}

export interface Modal {
    id: string;
    parameters?: { [key: string]: string };
}

export interface ModalOptions {
    size?: ModalSize;
    side?: ModalSide;
}

export const DEFAULT_MODAL_OPTIONS: ModalOptions = {
    size: ModalSize.Lg,
    side: ModalSide.Right,
};

export const MAIN_NAVIGATION_MODAL_ID = 'main-navigation-modal';


