import { ValueFormatterParams, ColDef } from '@ag-grid-community/core';
import {
    WdxCurrencyOptions,
    WdxNumberService,
    WdxDateTimeService,
    WdxDateFormat,
    TranslationsService,
    ShortCurrencyPipe,
    LocalisedString,
} from '@wdx/shared/utils';
import { AG_GRID_VALUE_GETTER } from './value-getter-functions';

export interface WdxAgGridCurrencyField {
    value: number;
    currency: WdxCurrencyOptions;
}

export function PERCENTAGE_VALUE_FORMATTER(
    p: ValueFormatterParams<any, number>,
) {
    return typeof p.value === 'number' ? `${p.value.toFixed(1)}%` : '';
}

export function CURRENCY_VALUE_FORMATTER(numberService: WdxNumberService) {
    return (params: ValueFormatterParams<any, WdxAgGridCurrencyField>) => {
        return typeof params.value?.value === 'number' && params.value?.currency
            ? numberService.formatCurrency(
                  params.value?.value,
                  params.value?.currency,
              ) || ''
            : '';
    };
}

export const DATE_CELL_VALUE_FORMATTER =
    (
        dateTimeService: WdxDateTimeService,
        format = WdxDateFormat.AbsoluteDate,
    ) =>
    (params: ValueFormatterParams) => {
        const value = AG_GRID_VALUE_GETTER(params);
        return value
            ? dateTimeService.convertDateToViewFriendlyFormat(value, { format })
            : '';
    };

export const DATE_CELL_COMPARATOR = (dateA: string, dateB: string) =>
    new Date(dateA).getTime() - new Date(dateB).getTime();

export const DATE_CELL_COL_DEFS: ColDef = {
    minWidth: 100,
    maxWidth: 125,
};

export const TRANSLATE_CELL_VALUE =
    (translationsService: TranslationsService) =>
    (params: ValueFormatterParams) => {
        const value: string | LocalisedString = AG_GRID_VALUE_GETTER(params);
        if (!value) {
            return '';
        }
        if (typeof value === 'string') {
            return translationsService.translateTokenisedString(
                value as string,
            );
        }
        return (value as LocalisedString).value
            ? (value as LocalisedString).value
            : translationsService.translateTokenisedString(
                  (value as LocalisedString).key as string,
              );
    };

export const SHORT_CURRENCY_VALUE_FORMATTER =
    () => (params: ValueFormatterParams) => {
        const value = AG_GRID_VALUE_GETTER(params);
        return value
            ? new ShortCurrencyPipe().transform(value?.value, value?.isoCode)
            : '';
    };
