import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { Article, ArticleType, SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import { PartyToAvatarPipe } from '@wdx/clmi/utils/pipes';
import { TenantsService, URL_ARTICLES_LISTING } from '@wdx/shared/utils';
import { Observable } from 'rxjs';
import { ActionButton } from '../../../../../models/action-button.model';
import { ActionButtonMode } from '../../../../../models/action-buttons-mode.model';
import { ListStyle } from '../../../../../models/list.model';
import { PermissionsService } from '../../../../../shared/services/permissions/permissions.service';
import { ArticleListItem } from '../../models/article-list-item.model';
import { ArticlesFacade } from '../../services/articles-facade/articles.facade';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-article-card',
    templateUrl: './molecule-article-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoleculeArticleCardComponent implements OnChanges {
    @Input() set article(article: Article) {
        this.articleListItem = this.getArticleCard(article);
    }
    @Input() listStyle = ListStyle.Grid;
    @Input() showArticleImage = true;
    @Input() showLinkButton = false;

    @Input() linkToEntityId: string;
    @Input() linkToEntityType: SystemEntity;
    @Input() isLinked: boolean;

    canLink$: Observable<boolean>;

    articleListItem: ArticleListItem;
    likeButton: ActionButton;
    linkButton: ActionButton;

    readonly LIST_STYLE = ListStyle;
    readonly ARTICLE_TYPE = ArticleType;

    get ROUTE_ARTICLE(): string[] {
        return [
            this.tenantsService.TENANT_CODE[0],
            ...URL_ARTICLES_LISTING,
            this.articleListItem.id,
        ];
    }

    constructor(
        private articlesFacade: ArticlesFacade,
        private permissionsService: PermissionsService,
        private tenantsService: TenantsService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        const linkToEntityType =
            this.linkToEntityType ?? changes?.linkToEntityType?.currentValue;
        const linkToEntityId =
            this.linkToEntityId ?? changes?.linkToEntityId?.currentValue;

        if (linkToEntityType && linkToEntityId) {
            this.canLink$ = this.permissionsService.canEdit$(
                linkToEntityType,
                linkToEntityId,
            );
        }
    }

    onLikeToggled(isLiked: boolean): void {
        this.articlesFacade.setLike(this.articleListItem.id, isLiked);
        this.articleListItem.likes = isLiked
            ? this.articleListItem.likes + 1
            : this.articleListItem.likes - 1;
    }

    onLinkToggled(isLinked: boolean): void {
        this.articlesFacade.setLink(
            this.articleListItem.id,
            this.linkToEntityType,
            this.linkToEntityId,
            isLinked,
        );
    }

    getLikeButton(isFavourite: boolean): ActionButton {
        return {
            mode: ActionButtonMode.LikeButton,
            isToggledOn: isFavourite,
            cySelector: 'action-icon-like',
        };
    }

    getLinkButton(isLinked: boolean): ActionButton {
        return {
            mode: ActionButtonMode.LinkedButton,
            isToggledOn: isLinked,
            cySelector: 'action-icon-linked',
        };
    }

    getArticleCard(article: Article): ArticleListItem {
        return {
            id: article.id,
            name: article.name,
            imageUrl: article.images[0]?.imageUrl,
            source: article.source,
            url: article.url,
            avatar: new PartyToAvatarPipe().transform(article.owner),
            datePublished: article.datePublished
                ? new Date(article.datePublished)
                : undefined,
            synopsis: article.synopsis,
            targets: article.totalDistributedSent,
            views: article.totalDistributedViewed,
            links: article.totalLinked,
            likes: article.favouriteCount,
            isFavourite: article.isFavourite,
            feeds: article.myFeedCount,
            comments: article.numberChats,
            status: article.status,
            articleType: article.type,
            internalIdentifier: article.internalIdentifier,
            files: article.files,
            tags: article.tags,
        };
    }
}
