import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class QuerySectionsFacadeService {
    showBuilder$ = new BehaviorSubject<boolean>(false);

    toggleQueryBuilder(): void {
        this.showBuilder$.next(!this.showBuilder$.value);
    }

    closeBuilder(): void {
        this.showBuilder$.next(false);
    }
}
