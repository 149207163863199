export const SIGNAL_R_HUB_NAME = 'datachange';

export enum SignalrEvent {
    ActivityCommentAdded = 'ActivityCommentAdded',
    BulkJobCompleted = 'BulkJobCompleted',
    CaseReady = 'CaseReady',
    CaseUpdated = 'CaseUpdated',
    DocumentGenerated = 'DocumentGenerated',
    DocumentListUpdated = 'DocumentListUpdated',
    ExportGenerated = 'ExportGenerated',
    ImportCompleted = 'ImportCompleted',
    NotificationsUpdate = 'NotificationsUpdate',
    PartyCasesUpdated = 'PartyCasesUpdated',
    PartyRoleCasesUpdated = 'PartyRoleCasesUpdated',
    PartyRoleUpdated = 'PartyRoleUpdated',
    PartyUpdated = 'PartyUpdated',
    SendAmlReportUpdated = 'SendAmlReportUpdated',
    UIReload = 'UIReload',
    WorkItemCompleted = 'WorkItemCompleted',
    VisitorEngaged = 'VisitorEngaged',
    AppUpdated = 'AppUpdated',
}

export const AllSignalrEvents = [
    SignalrEvent.ActivityCommentAdded,
    SignalrEvent.BulkJobCompleted,
    SignalrEvent.CaseReady,
    SignalrEvent.CaseUpdated,
    SignalrEvent.DocumentGenerated,
    SignalrEvent.DocumentListUpdated,
    SignalrEvent.ExportGenerated,
    SignalrEvent.ImportCompleted,
    SignalrEvent.NotificationsUpdate,
    SignalrEvent.PartyCasesUpdated,
    SignalrEvent.PartyRoleCasesUpdated,
    SignalrEvent.PartyRoleUpdated,
    SignalrEvent.PartyUpdated,
    SignalrEvent.UIReload,
    SignalrEvent.WorkItemCompleted,
    SignalrEvent.VisitorEngaged,
    SignalrEvent.AppUpdated,
];
