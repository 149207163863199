import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WdxSize } from '@wdx/shared/utils';
import { take } from 'rxjs/operators';
import { BaseModalQueryComponentClass } from '../../../classes/base-modal-query-component.class';
import { ModalManagementService } from '@wdx/clmi/ui';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'atom-modal-wrapper',
    templateUrl: './atom-modal-wrapper.component.html',
})
export class AtomModalWrapperComponent
    extends BaseModalQueryComponentClass
    implements OnInit, OnDestroy
{
    @Input() modalTemplate: TemplateRef<any>;
    @Input() modalId: string;
    @Input() size: WdxSize = 'md';
    @Input() side: 'left' | 'right' = 'right';

    constructor(
        route: ActivatedRoute,
        modalManagementService: ModalManagementService
    ) {
        super();

        this.patchInjectedItems({
            route,
            modalManagementService,
        });
    }

    ngOnInit(): void {
        this.modalManagementService.registerModal(
            this.modalTemplate,
            this.modalId,
            this.size,
            this.side
        );

        let modalIdFromModalQueryParameter: any;
        let modalParamsFromModalQueryParameter: any;

        this.modalQueryParameter()
            .pipe(take(1))
            .subscribe(({ modalId, modalParams }) => {
                modalIdFromModalQueryParameter = modalId;
                modalParamsFromModalQueryParameter = modalParams;
            });

        if (modalIdFromModalQueryParameter === this.modalId) {
            this.modalManagementService.openModalWithId(
                modalIdFromModalQueryParameter,
                modalParamsFromModalQueryParameter
            );
        }
    }

    ngOnDestroy(): void {
        this.modalManagementService.deregisterModal(this.modalId);
    }
}
