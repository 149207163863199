import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PushPipe } from '@ngrx/component';
import { WdxBadgeModule } from '@wdx/shared/components/wdx-badge';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxToggleButtonComponent } from '@wdx/shared/components/wdx-toggle-button';
import { TitleCasePipe } from '@wdx/shared/utils';
import { MoleculeActionButtonModule } from '../../../components/molecules/molecule-action-button/molecule-action-button.module';
import { MoleculeAvatarModule } from '../../../components/molecules/molecule-avatar/molecule-avatar.module';
import { MoleculeInfoCardModule } from '../../../components/molecules/molecule-info-card/molecule-info-card.module';
import { MoleculeLifecycleStageTagModule } from '../../../components/molecules/molecule-lifecycle-stage-tag/molecule-lifecycle-stage-tag.module';
import { WidgetTableModule } from '../../../components/widgets/widget-table/widget-table.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { ViewShellModule } from '../../../shared/components';
import { QueryBuilderFilterHandlerService } from '../../../shared/features/form-filters/shared/services/filter-handler/filter-handler.service';
import { FilterQueryService } from '../form-filters/shared';
import { FilterTableAdvisersComponent } from './advisers/advisers.component';
import { FilterTableClientProductsComponent } from './client-products';
import { FilterTableClientRelationshipsComponent } from './client-relationships/client-relationships.component';
import { FilterTableListsComponent } from './lists';
import { FilterTableActionButtonTemplateComponent } from './templates/action-button/action-button.component';
import { FilterTableInfoCardTemplateComponent } from './templates/info-card';
import { FilterTableLifecycleStageTemplateComponent } from './templates/lifecycle-stage';
import { FilterTableResponsiveCardTemplateComponent } from './templates/responsive-card';

@NgModule({
    declarations: [
        FilterTableActionButtonTemplateComponent,
        FilterTableAdvisersComponent,
        FilterTableClientProductsComponent,
        FilterTableClientRelationshipsComponent,
        FilterTableInfoCardTemplateComponent,
        FilterTableLifecycleStageTemplateComponent,
        FilterTableListsComponent,
        FilterTableResponsiveCardTemplateComponent,
    ],
    imports: [
        CommonModule,
        MoleculeActionButtonModule,
        MoleculeAvatarModule,
        MoleculeInfoCardModule,
        MoleculeLifecycleStageTagModule,
        PipesModule,
        PushPipe,
        RouterModule,
        ViewShellModule,
        WdxBadgeModule,
        WidgetTableModule,
        WdxIconModule,
        WdxToggleButtonComponent,
        TitleCasePipe,
    ],
    providers: [FilterQueryService, QueryBuilderFilterHandlerService],
    exports: [
        FilterTableAdvisersComponent,
        FilterTableClientProductsComponent,
        FilterTableClientRelationshipsComponent,
        FilterTableListsComponent,
    ],
})
export class FilterTableModule {}
