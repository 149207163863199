/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum PageType {
  Home = 'Home',
  Group = 'Group',
  Dashboard = 'Dashboard',
  List = 'List',
  Apps = 'Apps',
  Tiles = 'Tiles',
  Inbox = 'Inbox',
  Calendar = 'Calendar'
}
