import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { FormElementStyle } from '@wdx/shared/utils';
import { BaseWdxFormControlClass } from '../../../abstract-classes/base-form-control.class';
import { IFormDynamicData } from '../../../interfaces/form-dynamic-data.interface';

@Component({
    selector: 'wdx-ff-date-control',
    templateUrl: './form-date-control.component.html',
})
export class FormDateControlComponent
    extends BaseWdxFormControlClass
    implements OnInit
{
    public hideEnd?: boolean;
    public get groupControl() {
        return this.formControl as FormGroup;
    }

    constructor(
        public override controlContainer: ControlContainer,
        public override dynamicDataService: IFormDynamicData,
    ) {
        super(controlContainer, dynamicDataService);
    }

    ngOnInit() {
        this.hideEnd =
            this.formElement?.elementStyle === FormElementStyle.StartOnly;
    }

    public dateBlurEvent(controlName: 'start' | 'end') {
        const control = this.groupControl.controls[controlName];
        if (control?.pristine) {
            control?.markAsTouched();
        }
        if (
            !this.groupControl.controls['start'].touched &&
            (this.hideEnd || !this.groupControl.controls['end'].touched)
        ) {
            this.formControl?.markAsTouched();
        }
    }
}
