/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum ClientProductType {
  Investment = 'Investment',
  Banking = 'Banking',
  Credit = 'Credit',
  Portfolio = 'Portfolio',
  StockBroking = 'StockBroking',
  Insurance = 'Insurance',
  Lending = 'Lending',
  WealthManagement = 'WealthManagement',
  Protection = 'Protection',
  Pension = 'Pension'
}
