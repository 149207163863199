<wdx-form-field
    [locked]="formElement?.isLocked"
    [label]="formElement?.label | translateTokenisedString"
    [guidanceText]="formElement?.guidanceText | translateTokenisedString"
    [isSensitive]="formElement?.isSensitive"
    [isRequired]="formElement?.isRequired"
    [labelForId]="inputId"
    [validationErrorMessage]="
        formControl?.touched
            ? (formControl?.errors | validationErrorMessage)
            : ''
    "
    [warningMessage]="warningText"
>
    <ng-container *ngIf="fileIndexes?.length">
        <wdx-document-info
            *ngFor="let fileIndex of fileIndexes"
            class="d-block mb-1 w-100"
            [fileIndex]="fileIndex"
            (downloadClicked)="downloadClicked()"
        >
            <wdx-icon-button
                *ngIf="!isDisabled"
                class="ms-2"
                size="sm"
                wdxStyle="outline-primary"
                (wdxOnClick)="onDeleteFile(fileIndex)"
                ><wdx-icon icon="trash-alt"></wdx-icon
            ></wdx-icon-button>
        </wdx-document-info>
    </ng-container>

    <wdx-file-upload
        *ngIf="
            formElement.max === undefined ||
            (fileIndexes?.length || 0) < (formElement.max || 99)
        "
        data-cy="document-upload"
        [disabled]="isDisabled ? true : false"
        [imageUpload]="formElement.fieldType === FORM_FIELD_TYPE.ImageUpload"
        [customEndpoint]="formElement?.customEndpoint"
        [reset]="resetUpload"
        (fileUploaded)="onFileUploaded($event)"
    ></wdx-file-upload>
</wdx-form-field>
