import { Injectable } from '@angular/core';
import { LicenseInfo } from '@wdx/clmi/clmi-swagger-gen';
import { Observable } from 'rxjs';
import { BaseApiService } from '../base-api.service';

@Injectable()
export class LicenseService extends BaseApiService {
    getLicense(): Observable<LicenseInfo> {
        return this.get<LicenseInfo>(`license`);
    }
}
