<ng-container [formGroup]="$any(controlContainer?.control)">
    <wdx-form-field
        [attr.data-cy]="'editor-' + formElement.name"
        [locked]="formElement?.isLocked"
        [label]="formElement?.label | translateTokenisedString"
        [guidanceText]="formElement?.guidanceText | translateTokenisedString"
        [isSensitive]="formElement?.isSensitive"
        [isRequired]="formElement?.isRequired"
        [labelForId]="inputId"
        [validationErrorMessage]="
            formControl?.touched
                ? (formControl?.errors | validationErrorMessage)
                : ''
        "
        [warningMessage]="warningText"
    >
        <editor
            *ngIf="ready$ | ngrxPush"
            data-cy="textarea-editor"
            [apiKey]="editorApiKey"
            [init]="editorConfig"
            [disabled]="
                isDisabled ||
                PENDING_CHANGES_LOCK_FIELD.includes(
                    $any(formElement?.pendingChange?.status)
                )
            "
            [initialValue]="$any(initialValue$ | ngrxPush)"
            (onSelectionChange)="onEditorChange($event)"
        ></editor>
    </wdx-form-field>
</ng-container>
