import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfigService } from '../api-config.service';
import {
    Query,
    CubeQuery,
    CubeResult,
    CurrencyValue,
    FilterDefinition,
    RollupQuery,
    SystemEntity,
} from '@wdx/clmi/clmi-swagger-gen';
import { BaseApiService } from '../base-api.service';
import { ActivityQueryType, QueryType } from '@wdx/clmi/api-services/models';

export enum QueryUrlSegment {
    Query = 'query',
    Cube = 'cube',
    Definition = 'definition',
    Rollup = 'rollup',
}

@Injectable({ providedIn: 'root' })
export class QueryApiService extends BaseApiService {
    constructor(http: HttpClient, apiConfig: ApiConfigService) {
        super(http, apiConfig);
    }

    /**
     * Performs a query lookup
     * @param queryType The relevant System Entity.
     * @param query A query matching the CubeQuery interface.
     * @returns The query results.
     */
    getQuery<T>(
        entityType: SystemEntity,
        query: Query,
        options = {},
    ): Observable<T> {
        return this.post<T, CubeQuery>(
            `${QueryUrlSegment.Query}/${entityType.toLowerCase()}`,
            query,
            options,
        );
    }

    /**
     * Performs a cube lookup
     * @param queryType The relevant System Entity.
     * @param query A query matching the CubeQuery interface.
     * @returns The cube results.
     */
    getCube(
        entityType: SystemEntity,
        query: CubeQuery,
    ): Observable<CubeResult[]> {
        return this.post<CubeResult[], CubeQuery>(
            `${QueryUrlSegment.Query}/${entityType.toLowerCase()}/${
                QueryUrlSegment.Cube
            }`,
            query,
        );
    }

    /**
     * Returns rollup results from a given rollup query
     * @param rollupQuery
     * @param entityTye
     */
    getRollup(
        entityType: SystemEntity,
        rollupQuery: RollupQuery,
    ): Observable<number | CurrencyValue> {
        return this.post<number, RollupQuery>(
            `${QueryUrlSegment.Query}/${entityType.toLowerCase()}/rollup`,
            rollupQuery,
        );
    }

    /**
     * Returns the given query definitions for a given type
     * @param queryType
     */
    getQueryDefinition(queryType: QueryType): Observable<FilterDefinition> {
        const TYPE =
            queryType === ActivityQueryType.Inbox
                ? SystemEntity.Activity
                : queryType;
        return this.get<FilterDefinition>(
            `${QueryUrlSegment.Query}/${TYPE.toLowerCase()}/${
                QueryUrlSegment.Definition
            }`,
        );
    }
}
