import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    inject,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    AbstractControl,
    ControlContainer,
    UntypedFormControl,
    UntypedFormGroup,
} from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Country, FieldDefinitionOption, Locale } from '@wdx/shared/utils';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import {
    delay,
    map,
    skip,
    switchMap,
    take,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { BaseWdxFormControlClass } from '../../../abstract-classes/base-form-control.class';
import { IFormDynamicData } from '../../../interfaces';
import { FormValidationService, TelephoneService } from '../../../services';

const E164_REGEX = /^\+[1-9]\d{1,14}$/;

@Component({
    selector: 'wdx-ff-telephone-control',
    templateUrl: './form-telephone-control.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormTelephoneControlComponent
    extends BaseWdxFormControlClass
    implements OnInit, AfterViewInit
{
    private formValidationService = inject(FormValidationService);
    @ViewChild('countrySelect') countrySelect!: NgSelectComponent;

    countries$!: Observable<Country[]>;
    /** used by ngx-mask, https://www.npmjs.com/package/ngx-mask */
    mask!: string;
    telephoneFormGroup = new UntypedFormGroup({
        country: new UntypedFormControl(null),
        number: new UntypedFormControl(null),
        type: new UntypedFormControl(null),
    });

    locale$!: Observable<Locale>;

    numberControl!: AbstractControl;
    countryControl!: AbstractControl;
    typeControl!: AbstractControl;

    private countries!: Country[];

    public fieldDefOptions$ = this.options$ as BehaviorSubject<
        FieldDefinitionOption[]
    >;

    constructor(
        public override controlContainer: ControlContainer,
        public override dynamicDataService: IFormDynamicData,
        private telephoneService: TelephoneService,
    ) {
        super(controlContainer, dynamicDataService);
    }

    ngOnInit(): void {
        this.locale$ = this.dynamicDataService.getMeLocale();
        this.countries$ = this.dynamicDataService.getCountries();

        this.numberControl = this.telephoneFormGroup.get(
            'number',
        ) as AbstractControl;
        const validators = this._formElement
            ? this.formValidationService.getValidators(this._formElement)
            : [];
        this.numberControl.addValidators(validators);
        this.countryControl = this.telephoneFormGroup.get(
            'country',
        ) as AbstractControl;
        this.typeControl = this.telephoneFormGroup.get(
            'type',
        ) as AbstractControl;
    }

    ngAfterViewInit(): void {
        const defaultNumber = this.formControl?.value?.number;

        if (defaultNumber) {
            this.numberControl.setValue(defaultNumber, { emitEvent: false });
        }

        combineLatest([this.countries$, this.fieldDefOptions$])
            .pipe(take(1))
            .subscribe(([countries, options]) => {
                this.countries = countries;
                const defaultType =
                    this.formControl?.value?.type || options?.[0]?.value;
                this.typeControl.setValue(defaultType, { emitEvent: false });

                const defaultCountry = this.formControl?.value
                    ? countries.find(
                          (country) =>
                              country.isoCode ===
                              this.formControl?.value?.country,
                      )
                    : countries[0];
                this.countryControl.setValue(defaultCountry?.isoCode, {
                    emitEvent: false,
                });
                this.setMask(
                    this.telephoneService.getCountryPattern(
                        this.typeControl.value,
                        defaultCountry as Country,
                    ),
                );
            });

        this.countryControl.valueChanges
            .pipe(
                switchMap((isoCode) =>
                    this.countries$.pipe(
                        map((countries) =>
                            countries.find(
                                (country) => country.isoCode === isoCode,
                            ),
                        ),
                    ),
                ),
            )
            .pipe(
                takeUntil(this.destroyed$),
                tap((country) => {
                    this.setMask(
                        this.telephoneService.getCountryPattern(
                            this.typeControl.value,
                            country as Country,
                        ),
                    );
                }),
                delay(0),
            )
            .subscribe(() => {
                if (this.numberControl.value) {
                    this.numberControl.setValue(this.numberControl.value);
                    this.numberControl.markAsTouched();
                    this.numberControl.updateValueAndValidity();
                }
            });

        this.telephoneFormGroup.valueChanges
            .pipe(
                takeUntil(this.destroyed$),
                tap((value) => {
                    if (value.number) {
                        const e164Number = this.generateE164Number(
                            value.country,
                            value.number,
                        );
                        this.formControl?.setValue({
                            country: value.country,
                            number: value.number,
                            type: value.type,
                            e164Number,
                        });
                    } else {
                        this.formControl?.setValue(null);
                    }
                    this.formControl?.markAsDirty();
                }),
                skip(1),
                tap(() => {
                    this.formControl?.markAsTouched();
                }),
            )
            .subscribe();

        this.setE164Number(this.telephoneFormGroup.value);
    }

    setMask(phonePattern: string) {
        this.mask = phonePattern || '0*';
    }

    private setE164Number(data: { country: string; number: string }) {
        const e164Number = this.generateE164Number(data.country, data.number);
        if (e164Number) {
            this.formControl?.setValue({
                ...(this.formControl?.value || {}),
                e164Number: e164Number,
            });
        }
    }

    private generateE164Number(
        country?: string,
        number?: string,
    ): string | undefined {
        if (!country || !number) {
            return;
        }
        const dialCode = this.countries.find(
            (c) => c.isoCode === country,
        )?.dialCode;

        // remove initial empty space & leading zeros
        const phoneNumber = number.replace(/^\s*0*/, '');

        if (!dialCode || !phoneNumber) {
            return;
        }

        const e164FormattedNumber = `+${dialCode}${phoneNumber}`;
        const e164Number = E164_REGEX.test(e164FormattedNumber)
            ? e164FormattedNumber
            : undefined;

        return e164Number;
    }
}
