import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { FileIndex } from '@wdx/shared/utils';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
    fileDownloadActions,
    fileDownloadReducer,
    fileDownloadSelectors,
    filesActions,
    filesReducer,
    filesSelectors,
} from '../../+state';
import { FilePreview, FileUploadPackage } from '../../models';

@Injectable({
    providedIn: 'root',
})
export class FilesFacadeService {
    previewFileIndexes: string[] = [];

    constructor(
        private store$: Store<{
            [fileDownloadReducer.FEATURE_KEY]: fileDownloadReducer.State;
            [filesReducer.FEATURE_KEY]: filesReducer.State;
        }>,
    ) {}

    getFilePreview$(fileIndex: FileIndex): Observable<FilePreview | undefined> {
        return this.store$
            .select(fileDownloadSelectors.getFilePreview, {
                id: fileIndex?.fileIndex as string,
            })
            .pipe(
                tap(() => {
                    if (
                        !this.previewFileIndexes.includes(
                            fileIndex?.fileIndex as string,
                        )
                    ) {
                        this.loadFilePreview(fileIndex);
                        this.previewFileIndexes.push(
                            fileIndex?.fileIndex as string,
                        );
                    }
                }),
            );
    }

    getFilePreviewIsLoading$(fileIndex: FileIndex): Observable<boolean> {
        return this.store$.pipe(
            select(fileDownloadSelectors.getFilePreviewIsLoading, {
                id: fileIndex.fileIndex as string,
            }),
            map(Boolean),
        );
    }

    getFilePreviewHasError$(fileIndex: FileIndex): Observable<boolean> {
        return this.store$.pipe(
            select(fileDownloadSelectors.getFilePreviewHasLoadingError, {
                id: fileIndex.fileIndex as string,
            }),
            map(Boolean),
        );
    }

    getFileUploadFileIndex$(fileUploadPackage: FileUploadPackage) {
        return this.store$.select(filesSelectors.getFileUploadFileIndex, {
            id: fileUploadPackage.id,
        });
    }

    getFileUploadStatus$(fileUploadPackage: FileUploadPackage) {
        return this.store$.select(filesSelectors.getFileUploadStatus, {
            id: fileUploadPackage.id,
        });
    }

    getFileUploadProgress$(fileUploadPackage: FileUploadPackage) {
        return this.store$.select(filesSelectors.getFileUploadProgress, {
            id: fileUploadPackage.id,
        });
    }

    loadFileIndex(
        fileUploadPackage: FileUploadPackage,
        customEndpoint?: string,
    ) {
        this.store$.dispatch(
            filesActions.uploadFile({
                id: fileUploadPackage.id,
                file: fileUploadPackage.file,
                customEndpoint,
            }),
        );
    }

    loadFilePreview(fileIndex: FileIndex): void {
        this.store$.dispatch(
            fileDownloadActions.getFilePreview({
                fileIndex: fileIndex,
            }),
        );
    }

    destroyFilePreviews() {
        this.previewFileIndexes.forEach((fileIndex) => {
            this.store$.dispatch(
                fileDownloadActions.destroyFilePreview({
                    fileIndex,
                }),
            );
        });
        this.previewFileIndexes = [];
    }

    downloadFile(fileIndex: FileIndex): void {
        this.store$.dispatch(
            fileDownloadActions.getFileDownload({
                fileIndex: fileIndex.fileIndex as string,
                fileName: fileIndex.name,
            }),
        );
    }

    deleteFile(fileIndex: string): void {
        this.store$.dispatch(
            fileDownloadActions.deleteFile({
                fileIndex: fileIndex,
            }),
        );
    }

    downloadContextualFile(queryEntity: string, id: string): void {
        this.store$.dispatch(
            fileDownloadActions.getContextualFileDownload({
                queryEntity: queryEntity,
                id: id,
            }),
        );
    }

    loadContextualFilePreview(
        queryEntity: string,
        id: string,
        filetype: string,
    ): void {
        this.store$.dispatch(
            fileDownloadActions.getContextualFilePreview({
                queryEntity: queryEntity,
                id: id,
                fileType: filetype,
            }),
        );
    }

    getContextualFilePreview$(
        queryEntity: string,
        id: string,
        filetype: string,
    ): Observable<FilePreview | undefined> {
        return this.store$
            .select(fileDownloadSelectors.getFilePreview, { id })
            .pipe(
                tap((filePreview) => {
                    if (!filePreview) {
                        this.loadContextualFilePreview(
                            queryEntity,
                            id,
                            filetype,
                        );
                    }
                }),
            );
    }
}
