import {
    TRANSLATION_TABLE_HEADER_AUM,
    TRANSLATION_TABLE_HEADER_LAST_CONTACTED,
    TRANSLATION_TABLE_HEADER_LAST_REVIEW,
    TRANSLATION_TABLE_HEADER_LIFECYCLE_STAGE,
    TRANSLATION_TABLE_HEADER_NAME,
    TRANSLATION_TABLE_HEADER_NEXT_ACTIVITY,
    TRANSLATION_TABLE_HEADER_NEXT_REVIEW,
    TRANSLATION_TABLE_HEADER_OPEN_CASES,
    TRANSLATION_TABLE_HEADER_PIPELINE,
    TRANSLATION_TABLE_HEADER_SUB_TYPE,
    TRANSLATION_TABLE_HEADER_TYPE,
} from '@wdx/shared/utils';
import { SortMenuSortBy } from '../../../../models/sort-menu.model';
import { Privilege } from '../../../../models/privilege.model';
import { SystemEntity } from '@wdx/clmi/clmi-swagger-gen';

export const CLIENT_RELATIONSHIPS_DATA = {
    viewType: SystemEntity.Client,
    label: 'Client Relationships',
    translationKey: 'AREA_CONTACTS_CLIENTS',
    dropdownMenuPrivileges: {
        export: Privilege.ExportClient,
        import: Privilege.ImportClient,
    },
    hasLegacyChildren: true,
};

export const CLIENT_RELATIONSHIPS_TABLE_HEADERS = [
    {
        value: 'Name',
        translationKey: TRANSLATION_TABLE_HEADER_NAME,
        sortByFieldName: SortMenuSortBy.Name,
    },
    {
        value: 'Lifecycle Stage',
        translationKey: TRANSLATION_TABLE_HEADER_LIFECYCLE_STAGE,
    },
    {
        value: 'Type',
        translationKey: TRANSLATION_TABLE_HEADER_TYPE,
    },
    {
        value: 'Sub-Type',
        translationKey: TRANSLATION_TABLE_HEADER_SUB_TYPE,
    },
    {
        value: 'Last Contacted',
        translationKey: TRANSLATION_TABLE_HEADER_LAST_CONTACTED,

        sortByFieldName: SortMenuSortBy.LastContacted,
    },
    {
        value: 'Next Activity',
        translationKey: TRANSLATION_TABLE_HEADER_NEXT_ACTIVITY,
        sortByFieldName: SortMenuSortBy.NextMeeting,
    },
    {
        value: 'Open Cases',
        translationKey: TRANSLATION_TABLE_HEADER_OPEN_CASES,
    },
    {
        value: 'Pipeline (#)',
        translationKey: TRANSLATION_TABLE_HEADER_PIPELINE,
        sortByFieldName: SortMenuSortBy.Pipeline,
    },
    {
        value: 'AUM',
        translationKey: TRANSLATION_TABLE_HEADER_AUM,

        sortByFieldName: SortMenuSortBy.AUM,
    },
    {
        value: 'Last Review',
        translationKey: TRANSLATION_TABLE_HEADER_LAST_REVIEW,
        sortByFieldName: SortMenuSortBy.DateLastReview,
    },
    {
        value: 'Next Review',
        translationKey: TRANSLATION_TABLE_HEADER_NEXT_REVIEW,
        sortByFieldName: SortMenuSortBy.DateNextReview,
    },
    {
        value: null,
    },
];
