import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    Renderer2,
} from '@angular/core';
import { FormDefinition, FormLayout } from '@wdx/clmi/clmi-swagger-gen';
import { FormFrameworkEvent } from '@wdx/shared/infrastructure/form-framework';
import { FormDisplayMode, FormSetup, WdxDestroyClass } from '@wdx/shared/utils';
import { takeUntil } from 'rxjs';
import { DynamicFormsManagementService } from './dynamic-forms-management.service';

@Directive({
    selector: '[clmiUiDynamicFormId] , [clmiUiDynamicFormSetup]',
})
export class ClmiDynamicFormDirective extends WdxDestroyClass {
    @Input() clmiUiDynamicFormId!: string;
    @Input() clmiUiDynamicFormSetup!: FormSetup;
    @Input() entityId!: string;
    @Input() formDisplayMode!: FormDisplayMode;
    @Input() hardCodedFormDefinition!: FormDefinition;
    @Input() hardCodedFormLayout!: FormLayout;

    @Output() dynamicFormEvent = new EventEmitter<FormFrameworkEvent>();

    @HostListener('click') onClick() {
        if (!this.clmiUiDynamicFormId && !this.clmiUiDynamicFormSetup) {
            return;
        }

        const formSetup: FormSetup = {
            ...(this.clmiUiDynamicFormId
                ? { formId: this.clmiUiDynamicFormId || this.clmiUiDynamicFormSetup.formId }
                : {}),
            ...(this.entityId ? { entityId: this.entityId } : {}),
            ...(this.formDisplayMode
                ? { formDisplayMode: this.formDisplayMode }
                : {}),
            ...(this.hardCodedFormDefinition
                ? { hardCodedFormDefinition: this.hardCodedFormDefinition }
                : {}),
            ...(this.hardCodedFormLayout
                ? { hardCodedFormLayout: this.hardCodedFormLayout }
                : {}),
            ...(this.clmiUiDynamicFormSetup?.initialisationMode
                ? {
                      initialisationMode:
                          this.clmiUiDynamicFormSetup.initialisationMode,
                  }
                : {}),
            ...(this.clmiUiDynamicFormSetup || {}),
        };

        this.dynamicFormsManagementService
            .openDynamicFormModal(formSetup)
            ?.closed.pipe(takeUntil(this.destroyed$))
            .subscribe((event) => this.dynamicFormEvent.emit(event));
    }

    constructor(
        elementRef: ElementRef,
        renderer: Renderer2,
        private dynamicFormsManagementService: DynamicFormsManagementService,
    ) {
        super();
        renderer.setAttribute(
            elementRef.nativeElement,
            'href',
            'javascript:void(0)',
        );
    }
}
