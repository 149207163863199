<div
    class="modal-header d-flex align-items-center"
    [attr.data-cy]="'modal-' + modalTitle"
    [title]="modalTitle"
>
    <ng-content select=".header"></ng-content>
    <h5 class="modal-title text-truncate">{{ modalTitle }}
    </h5>

    <button
        type="button"
        class="close"
        aria-label="Close"
        data-cy="btn-close"
        (click)="onClose()"
    >
        <i class="fal fa-times"></i>
    </button>
</div>
<div
    class="modal-body"
    [attr.data-cy]="'modal-content-' + modalTitle">
    <wdx-ff-form-container
        [wdxIsLoading]="!formTitle"
        [formId]="formId"
        [entityId]="entityId"
        [isQuickCreate]="true"
        [initialisationParams]="initialisationParams"
        [modalInstanceId]="modalInstanceId"
        (formTitleReceived)="onFormTitleReceived($event)"
    ></wdx-ff-form-container>
    
</div>