import {
    ChangeDetectionStrategy,
    Component,
    Input,
    EventEmitter,
    Output,
    inject,
    OnInit,
} from '@angular/core';
import { View } from '@wdx/clmi/clmi-swagger-gen';
import { WdxDestroyClass } from '@wdx/shared/utils';
import { Observable } from 'rxjs';
import { filter, pairwise, takeUntil, tap } from 'rxjs/operators';
import { QuerySectionsFacadeService } from './shared/services/query-sections-facade/query-sections-facade.service';
import { FilterViewFacadeService } from './shared/services/filter-view-facade/filter-view-facade.service';
import * as R from 'ramda';

export interface FormFiltersConfig {
    emitEventOnly?: boolean;
    disableBuilder?: boolean;
}

export enum FormFiltersEventType {
    ViewSelected,
    ViewSaved,
    ApplyFilter,
}

export interface FormFiltersEvent {
    type: FormFiltersEventType;
    view?: View;
}

@Component({
    selector: 'clmi-form-filters',
    templateUrl: './form-filters.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [QuerySectionsFacadeService],
})
export class FormFiltersComponent extends WdxDestroyClass implements OnInit {
    private querySectionsFacadeService = inject(QuerySectionsFacadeService);
    private filterViewFacadeService = inject(FilterViewFacadeService);

    @Input() config: FormFiltersConfig = {};

    @Output() formFiltersEvent = new EventEmitter<FormFiltersEvent>();
    @Output() builderOpen = new EventEmitter<boolean>();

    ngOnInit() {
        this.filterViewFacadeService.viewsSystem$
            .pipe(
                takeUntil(this.destroyed$),
                pairwise(),
                filter(([prev, current]) => !R.equals(prev, current)),
            )
            .subscribe(() => this.querySectionsFacadeService.closeBuilder());
    }

    public isQueryBuilderVisible$: Observable<boolean> =
        this.querySectionsFacadeService.showBuilder$.pipe(
            tap((open) => this.builderOpen.emit(open)),
            takeUntil(this.destroyed$),
        );
}
