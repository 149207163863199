<button
    class="btn {{ btnClass }} btn-icon"
    triggers="mouseenter:mouseleave"
    container="body"
    popoverClass="popover-icon-button"
    [attr.data-cy]="cySelector"
    [openDelay]="POPOVER_OPEN_DELAY"
    [placement]="['top', 'bottom', 'left', 'right']"
    [class.disabled]="disabled"
    [disabled]="disabled"
    [clmiUiDynamicFormSetup]="formSetup"
    [ngbPopover]="altText"
    [disablePopover]="!altText"
    (click)="onIconButtonClicked($event)"
>
    <wdx-icon-container [size]="size">
        <wdx-icon [iconStyle]="iconStyle" [icon]="icon"
            ><ng-content></ng-content
        ></wdx-icon>
    </wdx-icon-container>
</button>
<span
    *ngIf="notification?.label"
    class="position-absolute top-0 start-100 translate-middle badge rounded-pill border border-light mt-2 ms-n1"
    [class.badge-info]="notification.severity === undefined"
    [class.badge-success]="
        notification.severity === NOTIFICATION_SEVERITY.Success
    "
    [class.badge-warning]="
        notification.severity === NOTIFICATION_SEVERITY.Warning
    "
    [class.badge-danger]="
        notification.severity === NOTIFICATION_SEVERITY.Danger
    "
>
    {{ notification.label }}
</span>
