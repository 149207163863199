<wdx-dropdown ngbDropdown container="body" (openChange)="openChange($event)">
    <button
        class="px-2 py-1"
        type="button"
        wdxIconButton
        [wdxButtonSize]="WdxButtonSize.Sm"
        ngbDropdownToggle
    >
        <wdx-icon iconStyle="fas" icon="ellipsis-h"></wdx-icon>
    </button>
    <wdx-dropdown-menu ngbDropdownMenu>
        <ng-container *ngIf="opened">
            <ng-container
                *ngIf="{
                    actions: actions$ | ngrxPush,
                } as context"
            >
                <div *ngIf="!context?.actions" class="text-center p-2">
                    <wdx-spinner [small]="true"></wdx-spinner>
                </div>
                <button
                    *ngFor="let action of context.actions"
                    type="button"
                    class="d-flex"
                    ngbDropdownItem
                    (click)="actionClicked(action, data)"
                >
                    <wdx-icon
                        *ngIf="action.icon"
                        [icon]="action.icon"
                        class="me-1"
                    ></wdx-icon>
                    <div *ngIf="!action.icon" class="m-1 ps-1"></div>
                    {{ action?.displayName?.value }}
                </button>
            </ng-container>
        </ng-container>
    </wdx-dropdown-menu>
</wdx-dropdown>
