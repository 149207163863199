export enum IdentityAndVerificationUrlSegment {
    IdentityCheck = 'identityCheck',
    Party = 'party',
    Options = 'options',
    Historicalidcheck = 'historicalidcheck',
    Documents = 'Documents',
    Action = 'action',
    Share = 'share',
    Status = 'status',
    Submitted = 'submitted',
}
