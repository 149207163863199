@if (preLabel) {
    @if (preLabelClickCallback) {
        <button
            [wdxButton]="WdxButtonStyle.Link"
            [wdxButtonSize]="WdxButtonSize.Sm"
            class="ps-2"
            (click)="preLabelClickCallback()"
            data-cy="badge-renderer-prelabel-link"
            wdxStopPropagation
        >
            {{ preLabel }}
        </button>
    } @else {
        <span class="pe-2" data-cy="badge-renderer-prelabel">
            {{ preLabel }}
        </span>
    }
}
@if (!disableBadge) {
    <div [ngClass]="classes" data-cy="badge-renderer-badge">{{ label }}</div>
}
@if (postLabel) {
    @if (postLabelClickCallback) {
        <button
            [wdxButton]="WdxButtonStyle.Link"
            [wdxButtonSize]="WdxButtonSize.Sm"
            class="ps-2"
            (click)="postLabelClickCallback()"
            data-cy="badge-renderer-postlabel-link"
            wdxStopPropagation
        >
            {{ preLabel }}
        </button>
    } @else {
        <span class="ps-2" data-cy="badge-renderer-postlabel">
            {{ postLabel }}
        </span>
    }
}
