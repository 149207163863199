import { FormFieldType } from '@wdx/shared/utils';

export const FORM_GROUP = [
    FormFieldType.TextDropdown,
    FormFieldType.DateRange,
    FormFieldType.DateTimeRange,
];

/**
 * Fields where layout and FormGroup controls are defined at component level
 */
export const MANUAL_FORM_GROUP = [FormFieldType.Address];

export const CREATE_FORM_GROUP = {
    [FormFieldType.TextDropdown]: [
        {
            formControlName: 'text',
        },
        {
            formControlName: 'dropdown',
        },
    ],
    [FormFieldType.DateRange]: [
        {
            formControlName: 'start',
        },
        {
            formControlName: 'end',
        },
    ],
    [FormFieldType.DateTimeRange]: [
        {
            formControlName: 'start',
        },
        {
            formControlName: 'end',
        },
    ],
};
