<ng-container [formGroup]="$any(controlContainer?.control)">
    <wdx-form-field
        [locked]="formElement?.isLocked"
        [label]="formElement?.label | translateTokenisedString"
        [isRequired]="formElement?.isRequired"
        [validationErrorMessage]="
            formControl?.touched
                ? (formControl?.errors | validationErrorMessage)
                : ''
        "
        [warningMessage]="warningText"
    >
        <wdx-checkbox
            [label]="
                (formElement?.guidanceText | translateTokenisedString) || ''
            "
            [formControlName]="$any(formElement.name)"
            [disabled]="isDisabled ? true : false"
        ></wdx-checkbox>
    </wdx-form-field>
</ng-container>
