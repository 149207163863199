import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewContainerRef,
    inject,
} from '@angular/core';
import { delay, takeUntil } from 'rxjs/operators';
import { AssignToService } from '../../../shared/features/assign-to/services/assign-to.service';

import {
    LookupContextMode,
    LookupResultsMode,
    LookupSourceType,
    SystemEntity,
} from '@wdx/clmi/clmi-swagger-gen';
import { Subject } from 'rxjs';

import {
    TRANSLATION_ENTITY_TEAM_SINGULAR,
    TRANSLATION_ENUM_TABTYPE_TEAMMEMBERS,
    TRANSLATION_SVG_TEXT_NO_TEAM_MEMBERS,
    UTILS_ICON_ADD,
} from '@wdx/shared/utils';
import { MoleculeInputLookupComponent } from '../../../components/molecules/molecule-input-lookup/containers/molecule-input-lookup/molecule-input-lookup.component';
import { TeamFacade } from '../../../features/teams/shared/services/team.facade';
import { InfoCardData } from '../../../models/info-card-data.model';
import { FeatureSvg } from '../../../models/svg.model';
import { ModalManagementService } from '@wdx/clmi/ui';
import {
    TeamMembersService,
    TeamMembersTransformService,
} from './shared/services';

@Component({
    selector: 'clmi-team-members',
    templateUrl: './team-members.component.html',
    providers: [TeamMembersService, TeamMembersTransformService],
})
export class TeamMembersComponent implements OnInit, OnDestroy {
    data: any;
    teams: any[];
    requiredData$ = new Subject();
    hasCompletedOnInit: boolean;
    _id: string;
    public readonly ICON_ADD = UTILS_ICON_ADD;

    get getTmService() {
        return this.tmService;
    }

    get id() {
        return this._id;
    }
    @Input() set id(val) {
        this._id = val;
        if (this.hasCompletedOnInit) {
            this.tmService.destroyed$.next(true);
            this.tmService.entityId = val;
            this.tmService.getTeamMembers();
        }
    }
    @Input() systemEntity: SystemEntity;
    @Input() addTeamMemberForm: string;
    @Input() readonlyView: boolean;
    @Input() isAccordion = false;
    @Input() useLookupAddMember = false;
    @ViewChild('lookupContainer', { read: ViewContainerRef })
    lookupContainer: ViewContainerRef;

    @Output() assignSuccess = new EventEmitter();

    readonly NO_DATA_SVG = FeatureSvg.NoData;
    readonly ENTITY_TEAM_SINGULAR = TRANSLATION_ENTITY_TEAM_SINGULAR;
    readonly ENUM_TABTYPE_TEAMMEMBERS = TRANSLATION_ENUM_TABTYPE_TEAMMEMBERS;
    readonly SVG_TEXT_NO_TEAM_MEMBERS = TRANSLATION_SVG_TEXT_NO_TEAM_MEMBERS;

    private modalManagementService = inject(ModalManagementService);
    private teamFacade = inject(TeamFacade);
    private tmService = inject(TeamMembersService);
    private assignToService = inject(AssignToService);

    ngOnInit(): void {
        this.hasCompletedOnInit = true;
        this.tmService.entityId = this.id;
        this.tmService.systemEntity = this.systemEntity;
        this.tmService.teamMemberForm = this.addTeamMemberForm;
        this.tmService.getTeamMembers();
        this.assignToService
            .onAssignToSuccess$()
            .pipe(takeUntil(this.tmService.destroyed$))
            .subscribe((data) => {
                this.assignSuccess.emit(data);
                this.tmService.dispatchTeamMembers();
            });
    }

    ngOnDestroy(): void {
        this.tmService.destroyed$.next(true);
        this.tmService.destroyed$.complete();
    }

    openLookupAssignTo(): void {
        this.assignToService.openAssignToModal(this.systemEntity, this.id);
    }

    trackByPartyTeamMemberId(
        _: number,
        partyTeamMember: { infoCard: InfoCardData },
    ): string {
        return partyTeamMember?.infoCard?.id;
    }

    openAddNewMemberModal() {
        const lookupComponent = this.lookupContainer.createComponent(
            MoleculeInputLookupComponent,
        );

        const lookupInstance = lookupComponent.instance;

        const destroyed$ = lookupInstance.destroyed$;

        lookupInstance.showReadout = false;
        lookupInstance.isMulti = false;

        lookupInstance.formInputData = {
            label: 'Add New Member',
            id: 'userLookup',
            lookupSources: [{ type: LookupSourceType.User }],
            lookupContextMode: LookupContextMode.SearchPreservesContext,
            lookupResultsMode: LookupResultsMode.Immediate,
        };

        lookupInstance.formInputData.entityType = this.systemEntity;
        lookupInstance.formInputData.entityId = this.id;
        lookupInstance.showNoResultsMessage = false;
        lookupInstance.initialValue = '';

        lookupInstance.modalRegistered
            .pipe(delay(0), takeUntil(destroyed$))
            .subscribe((modalId) => {
                this.modalManagementService.openModalWithId(modalId);
            });

        lookupInstance.valueChanged
            .pipe(delay(0), takeUntil(destroyed$))
            .subscribe((value) => {
                this.teamFacade.addTeamMember(this.id, value.id);
                this.getTmService.addingTeamMembersIsLoading$.next(true);
                this.lookupContainer.remove();
            });

        lookupInstance.closed
            .pipe(delay(0), takeUntil(destroyed$))
            .subscribe(() => this.lookupContainer.remove());
    }
}
