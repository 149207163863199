import { createAction, props } from '@ngrx/store';
import {
    IdentityCheck,
    IdentityCheckVerificationOptions,
} from '@wdx/clmi/clmi-swagger-gen';

export const getIdentityCheckForParty = createAction(
    '[Identity And Verification] Get Party Identity Check',
    props<{
        partyId: string;
    }>(),
);

export const getIdentityCheckForPartySuccess = createAction(
    '[Identity And Verification] Get Party Identity Check Success',
    props<{
        partyId: string;
        identityCheck: IdentityCheck[];
    }>(),
);

export const getIdentityCheckForPartyError = createAction(
    '[Identity And Verification ] Get Party Identity Check Error',
    props<{
        error: Error;
        partyId: string;
    }>(),
);

export const getIdentityCheck = createAction(
    '[Identity And Verification] Get Identity Check',
    props<{
        identitycheckId: string;
    }>(),
);

export const getIdentityCheckSuccess = createAction(
    '[Identity And Verification] Get Identity Check Success',
    props<{
        identitycheckId: string;
        identityCheck: IdentityCheck;
    }>(),
);

export const getIdentityCheckError = createAction(
    '[Identity And Verification ] Get Identity Check Error',
    props<{
        error: Error;
        identitycheckId: string;
    }>(),
);

export const getIdentityCheckOptions = createAction(
    '[Identity And Verification] Get Identity Check Options',
    props<{ partyId: string; clientId?: string }>(),
);

export const getIdentityCheckOptionsSuccess = createAction(
    '[Identity And Verification] Get Identity Check Options Success',
    props<{
        options: IdentityCheckVerificationOptions;
    }>(),
);

export const getIdentityCheckOptionsError = createAction(
    '[Identity And Verification ] Get Identity Check Options Error',
    props<{
        error: Error;
    }>(),
);

export const shareIdCheckWithClient = createAction(
    '[Identity And Verification ] Share With Client',
    props<{
        identitycheckId: string;
        clientId: string;
    }>(),
);

export const shareIdCheckWithClientSuccess = createAction(
    '[Identity And Verification ] Share With Client Success',
    props<{
        identitycheckId: string;
        clientId: string;
    }>(),
);

export const shareIdCheckWithClientFailure = createAction(
    '[Identity And Verification ] Share With Client Failure',
    props<{
        error: Error;
    }>(),
);
