import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { dynamicFormsReducers } from '@wdx/shared/infrastructure/form-framework';
import { BaseSignalRStoreState, signalrReducer } from 'ngrx-signalr-core';

import * as mailingInstructionsReducer from '../../shared/features/mailing-instructions/state/mailing-instructions.reducer';
import * as accessReducer from '../../state/access/access.reducer';
import * as activitiesReducer from '../../state/activities/activities.reducer';
import * as adminRolesReducer from '../../state/admin-roles/admin-roles.reducer';
import * as amlReportsReducer from '../../state/aml-reports/aml-reports.reducer';
import * as amlReducer from '../../state/aml/aml.reducer';
import * as answersReducer from '../../state/answers/answers.reducer';
import * as appsReducer from '../../state/apps/apps.reducer';
import * as articlesReducer from '../../state/articles/articles.reducer';
import * as auditsReducer from '../../state/audits/audits.reducer';
import * as authenticationReducer from '../../state/authentication/authentication.reducer';
import * as avatarFileUploadReducer from '../../state/avatar-file-upload/avatar-file-upload.reducer';
import * as avatarProfileFileUploadReducer from '../../state/avatar-profile-file-upload/avatar-profile-file-upload.reducer';
import * as batchesReducer from '../../state/batch-list/batch-list.reducer';
import * as batchMessageReducer from '../../state/batch-message/batch-message.reducer';
import * as caseTypesReducer from '../../state/case-types/case-types.reducer';
import * as casesReducer from '../../state/cases/cases.reducer';
import * as chatsReducer from '../../state/chats/chats.reducer';
import * as clientProductsReducer from '../../state/client-products/client-products.reducer';
import * as clientsReducer from '../../state/clients/clients.reducer';
import * as commentsReducer from '../../state/comments/comments.reducer';
import * as connectionsReducer from '../../state/connections/connections.reducer';
import * as contactPointsReducer from '../../state/contact-points/contact-points.reducer';
import * as countriesReducer from '../../state/countries/countries.reducer';
import * as currenciesReducer from '../../state/currencies/currencies.reducer';
import * as documentChecklistReducer from '../../state/document-checklist/document-checklist.reducer';
import * as documentRequirementTypesReducer from '../../state/document-requirement-types/document-requirement-types.reducer';
import * as documentTemplatesReducer from '../../state/document-templates/document-templates.reducer';
import * as documentTypesReducer from '../../state/document-types/document-types.reducer';
import * as documentsReducer from '../../state/documents/documents.reducer';
import * as duplicatesReducer from '../../state/duplicates/duplicates.reducer';
import * as duplicationCheckReducer from '../../state/duplication-check/duplication-check.reducer';
import * as emailReducer from '../../state/email/email.reducer';
import * as engagementBarReducer from '../../state/engagement-bar/engagement-bar.reducer';
import * as envelopeReducer from '../../state/envelope/envelope.reducer';
import * as favouritesReducer from '../../state/favourites/favourites.reducer';
import * as feedsReducer from '../../state/feeds/feeds.reducer';
import * as globalSearchReducer from '../../state/global-search/global-search.reducer';
import * as globalReducer from '../../state/global/global.reducer';
import * as goalTypesReducer from '../../state/goal-types/goal-types.reducer';
import * as httpReducer from '../../state/http/http.reducer';
import * as investmentAccountsReducer from '../../state/investment-accounts/investment-accounts.reducer';
import * as investmentDataReducer from '../../state/investment-data/investment-data.reducer';
import * as investorRiskProfileReducer from '../../state/investor-risk-profile/investor-risk-profile.reducer';
import * as kanbanStagesReducer from '../../state/kanban-stages/kanban-stages.reducer';
import * as leadsReducer from '../../state/leads/leads.reducer';
import * as listsReducer from '../../state/lists/lists.reducer';
import * as lookupsReducer from '../../state/lookups/lookups.reducer';
import * as marketingReducer from '../../state/marketing/marketing.reducer';
import * as messagesReducer from '../../state/message/message.reducer';
import * as notesReducer from '../../state/notes/notes.reducer';
import * as notificationsReducer from '../../state/notifications/notifications.reducer';
import * as nudgeTypesReducer from '../../state/nudge-types/nudge-types.reducer';
import * as nudgesReducer from '../../state/nudges/nudges.reducer';
import * as opportunitiesReducer from '../../state/opportunities/opportunities.reducer';
import * as organisationClientTypesReducer from '../../state/organisation-client-types/organisation-client-types.reducer';
import * as organisationsReducer from '../../state/organisations/organisations.reducer';
import * as outcomesReducer from '../../state/outcomes/outcomes.reducer';
import * as partiesReducer from '../../state/parties/parties.reducer';
import * as partyRoleReducer from '../../state/party-role/party-role.reducer';
import * as peopleReducer from '../../state/people/people.reducer';
import * as personalConnectionsReducer from '../../state/personal-connections/personal-connections.reducer';
import * as portfolioReducer from '../../state/portfolio/portfolio.reducer';
import * as processTypesReducer from '../../state/process-types/process-types.reducer';
import * as processesReducer from '../../state/processes/processes.reducer';
import * as productTypesReducer from '../../state/product-types/product-types.reducer';
import * as professionalConnectionsReducer from '../../state/professional-connections/professional-connections.reducer';
import * as prospectsReducer from '../../state/prospects/prospects.reducer';
import * as queuesReducer from '../../state/queues/queues.reducer';
import * as recentRecordsReducer from '../../state/recent-records/recent-records.reducer';
import * as relatedClientsReducer from '../../state/related-clients/related-clients.reducer';
import * as relatedRecordsReducer from '../../state/related-records/related-records.reducer';
import * as relationshipsReducer from '../../state/relationships/relationships.reducer';
import * as remindersReducer from '../../state/reminders/reminders.reducer';
import * as rolesReducer from '../../state/roles/roles.reducer';
import * as selectOptionsReducer from '../../state/select-options/select-options.reducer';
import * as suggestedPartiesReducer from '../../state/suggested-parties/suggested-parties.reducer';
import * as systemEventsReducer from '../../state/system-events/system-events.reducer';
import * as tagsReducer from '../../state/tags/tags.reducer';
import * as teamReducer from '../../state/team/team.reducer';
import * as viewsReducer from '../../state/views/views.reducer';
import * as viewTileReducer from '../../state/viewtile/viewtile.reducer';
import * as fromWebHooks from '../../state/web-hooks/web-hooks.reducer';
import * as workItemsReducer from '../../state/work-items/work-items.reducer';

import {
    avatarInfoReducer,
    AvatarInfoState,
    DASHBOARDS_FEATURE_KEY,
    dashboardsReducer,
    DashboardsStoreState,
    eventReducer,
    EventStoreState,
    filterReducer,
    FilterState,
    identityAndVerificationReducer,
    IdentityAndVerificationStoreState,
    importEntitiesReducer,
    ImportEntitiesStoreState,
    licenseReducer,
    LicenseStoreState,
    operationsReducer,
    OperationsStoreState,
    OXFORD_RISK_FEATURE_KEY,
    oxfordRiskReducer,
    OxfordRiskState,
    PERSONAL_SETTINGS_FEATURE_KEY,
    personalSettingsReducer,
    PersonalSettingsState,
    regionReducer,
    RegionState,
    systemReducer,
    SystemStoreState,
    IdentityCheckStore,
} from '@wdx/clmi/api-services/state';

import {
    systemEntityReducer,
    userReducer,
    UserState,
} from '@wdx/clmi/api-services/services';
export interface State {
    access: accessReducer.State;
    activities: activitiesReducer.State;
    adminRoles: adminRolesReducer.State;
    aml: amlReducer.State;
    amlReports: amlReportsReducer.State;
    answers: answersReducer.State;
    apps: appsReducer.State;
    articles: articlesReducer.State;
    audits: auditsReducer.State;
    avatarFileUpload: avatarFileUploadReducer.State;
    avatarInfo: AvatarInfoState;
    avatarProfileFileUpload: avatarProfileFileUploadReducer.State;
    batches: batchesReducer.State;
    batchMessages: batchMessageReducer.State;
    [casesReducer.CASES_FEATURE_KEY]: casesReducer.State;
    caseTypes: caseTypesReducer.State;
    chats: chatsReducer.State;
    clientProducts: clientProductsReducer.State;
    clients: clientsReducer.State;
    comments: commentsReducer.State;
    connections: connectionsReducer.State;
    contactPoints: contactPointsReducer.State;
    countries: countriesReducer.State;
    currencies: currenciesReducer.State;
    [DASHBOARDS_FEATURE_KEY]: DashboardsStoreState;
    documentChecklist: documentChecklistReducer.State;
    documentRequirementTypes: documentRequirementTypesReducer.State;
    documents: documentsReducer.State;
    documentTemplates: documentTemplatesReducer.State;
    documentTypes: documentTypesReducer.State;
    duplicates: duplicatesReducer.State;
    duplicationCheck: duplicationCheckReducer.State;
    [dynamicFormsReducers.FEATURE_KEY]: dynamicFormsReducers.State;
    email: emailReducer.State;
    engagementBar: engagementBarReducer.State;
    envelope: envelopeReducer.State;
    favourites: favouritesReducer.State;
    feeds: feedsReducer.State;
    filters: FilterState;
    global: globalReducer.State;
    globalSearch: globalSearchReducer.State;
    goalTypes: goalTypesReducer.State;
    http: httpReducer.State;
    investmentAccounts: investmentAccountsReducer.State;
    investmentData: investmentDataReducer.State;
    investorRiskProfile: investorRiskProfileReducer.State;
    kanbanStages: kanbanStagesReducer.State;
    leads: leadsReducer.State;
    lists: listsReducer.State;
    lookups: lookupsReducer.State;
    mailingInstructions: mailingInstructionsReducer.State;
    marketing: marketingReducer.State;
    messages: messagesReducer.State;
    notes: notesReducer.State;
    notifications: notificationsReducer.State;
    nudges: nudgesReducer.State;
    nudgeTypes: nudgeTypesReducer.State;
    opportunities: opportunitiesReducer.State;
    organisationClientTypes: organisationClientTypesReducer.State;
    organisations: organisationsReducer.State;
    outcomes: outcomesReducer.State;
    [OXFORD_RISK_FEATURE_KEY]: OxfordRiskState;
    parties: partiesReducer.State;
    partyRole: partyRoleReducer.State;
    people: peopleReducer.State;
    personalConnections: personalConnectionsReducer.State;
    [PERSONAL_SETTINGS_FEATURE_KEY]: PersonalSettingsState;
    portfolio: portfolioReducer.State;
    processes: processesReducer.State;
    processTypes: processTypesReducer.State;
    productTypes: productTypesReducer.State;
    professionalConnections: professionalConnectionsReducer.State;
    prospects: prospectsReducer.State;
    queues: queuesReducer.State;
    recentRecords: recentRecordsReducer.State;
    relatedClients: relatedClientsReducer.State;
    relatedRecords: relatedRecordsReducer.State;
    relationships: relationshipsReducer.State;
    reminders: remindersReducer.State;
    roles: rolesReducer.State;
    router: RouterReducerState;
    [selectOptionsReducer.SELECT_OPTIONS_FEATURE_KEY]: selectOptionsReducer.SelectOptionsState;
    session: authenticationReducer.State;
    signalr: BaseSignalRStoreState;
    suggestedParties: suggestedPartiesReducer.State;
    system: SystemStoreState;
    [systemEventsReducer.SYSTEM_EVENTS_FEATURE_KEY]: systemEventsReducer.State;
    tags: tagsReducer.State;
    team: teamReducer.State;
    user: UserState;
    [viewsReducer.VIEWS_FEATURE_KEY]: viewsReducer.State;
    viewTile: viewTileReducer.State;
    webHooks: fromWebHooks.State;
    workItems: workItemsReducer.State;
    region: RegionState;
    importEntities: ImportEntitiesStoreState;
    license: LicenseStoreState;
    event: EventStoreState;
    operations: OperationsStoreState;
    [IdentityCheckStore]: IdentityAndVerificationStoreState;
}

export const reducers = {
    access: accessReducer.reducer,
    activities: activitiesReducer.reducer,
    adminRoles: adminRolesReducer.reducer,
    aml: amlReducer.reducer,
    amlReports: amlReportsReducer.reducer,
    answers: answersReducer.reducer,
    apps: appsReducer.reducer,
    articles: articlesReducer.reducer,
    audits: auditsReducer.reducer,
    avatarFileUpload: avatarFileUploadReducer.reducer,
    avatarInfo: avatarInfoReducer,
    avatarProfileFileUpload: avatarProfileFileUploadReducer.reducer,
    batches: batchesReducer.reducer,
    batchMessages: batchMessageReducer.reducer,
    [casesReducer.CASES_FEATURE_KEY]: casesReducer.reducer,
    caseTypes: caseTypesReducer.reducer,
    chats: chatsReducer.reducer,
    clientProducts: clientProductsReducer.reducer,
    clients: clientsReducer.reducer,
    comments: commentsReducer.reducer,
    connections: connectionsReducer.reducer,
    contactPoints: contactPointsReducer.reducer,
    countries: countriesReducer.reducer,
    currencies: currenciesReducer.reducer,
    [DASHBOARDS_FEATURE_KEY]: dashboardsReducer,
    documentChecklist: documentChecklistReducer.reducer,
    documentRequirementTypes: documentRequirementTypesReducer.reducer,
    documents: documentsReducer.reducer,
    documentTemplates: documentTemplatesReducer.reducer,
    documentTypes: documentTypesReducer.reducer,
    duplicates: duplicatesReducer.reducer,
    duplicationCheck: duplicationCheckReducer.reducer,
    dynamicForms: dynamicFormsReducers.reducer,
    email: emailReducer.reducer,
    engagementBar: engagementBarReducer.reducer,
    envelope: envelopeReducer.reducer,
    favourites: favouritesReducer.reducer,
    feeds: feedsReducer.reducer,
    filters: filterReducer,
    global: globalReducer.reducer,
    globalSearch: globalSearchReducer.reducer,
    goalTypes: goalTypesReducer.reducer,
    http: httpReducer.reducer,
    investmentAccounts: investmentAccountsReducer.reducer,
    investmentData: investmentDataReducer.reducer,
    investorRiskProfile: investorRiskProfileReducer.reducer,
    kanbanStages: kanbanStagesReducer.reducer,
    leads: leadsReducer.reducer,
    lists: listsReducer.reducer,
    lookups: lookupsReducer.reducer,
    mailingInstructions: mailingInstructionsReducer.reducer,
    marketing: marketingReducer.reducer,
    messages: messagesReducer.reducer,
    notes: notesReducer.reducer,
    notifications: notificationsReducer.reducer,
    nudges: nudgesReducer.reducer,
    nudgeTypes: nudgeTypesReducer.reducer,
    opportunities: opportunitiesReducer.reducer,
    organisationClientTypes: organisationClientTypesReducer.reducer,
    organisations: organisationsReducer.reducer,
    outcomes: outcomesReducer.reducer,
    [OXFORD_RISK_FEATURE_KEY]: oxfordRiskReducer,
    parties: partiesReducer.reducer,
    partyRole: partyRoleReducer.reducer,
    people: peopleReducer.reducer,
    personalConnections: personalConnectionsReducer.reducer,
    [PERSONAL_SETTINGS_FEATURE_KEY]: personalSettingsReducer,
    portfolio: portfolioReducer.reducer,
    processes: processesReducer.reducer,
    processTypes: processTypesReducer.reducer,
    productTypes: productTypesReducer.reducer,
    professionalConnections: professionalConnectionsReducer.reducer,
    prospects: prospectsReducer.reducer,
    queues: queuesReducer.reducer,
    recentRecords: recentRecordsReducer.reducer,
    relatedClients: relatedClientsReducer.reducer,
    relatedRecords: relatedRecordsReducer.reducer,
    relationships: relationshipsReducer.reducer,
    reminders: remindersReducer.reducer,
    roles: rolesReducer.reducer,
    router: routerReducer,
    [selectOptionsReducer.SELECT_OPTIONS_FEATURE_KEY]:
        selectOptionsReducer.reducer,
    session: authenticationReducer.reducer,
    signalr: signalrReducer,
    suggestedParties: suggestedPartiesReducer.reducer,
    system: systemReducer,
    [systemEventsReducer.SYSTEM_EVENTS_FEATURE_KEY]:
        systemEventsReducer.reducer,
    tags: tagsReducer.reducer,
    team: teamReducer.reducer,
    user: userReducer,
    [viewsReducer.VIEWS_FEATURE_KEY]: viewsReducer.reducer,
    viewTile: viewTileReducer.reducer,
    webHooks: fromWebHooks.reducer,
    workItems: workItemsReducer.reducer,
    systemEntity: systemEntityReducer,
    region: regionReducer,
    importEntities: importEntitiesReducer,
    license: licenseReducer,
    event: eventReducer,
    operations: operationsReducer,
    [IdentityCheckStore]: identityAndVerificationReducer,
};
