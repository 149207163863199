import { Injectable, inject } from '@angular/core';
import { GlobalSearchResult } from '@wdx/clmi/clmi-swagger-gen';
import { ACTIVITY_FORM_ID_MAP } from '../../../constants/activity.constants';
import { DynamicFormsManagementService } from '@wdx/clmi/ui';

@Injectable({
    providedIn: 'root',
})
export class GlobalSearchProcessService {
    private dynamicFormsManagementService = inject(
        DynamicFormsManagementService,
    );

    handleNonRoutedAction(globalSearchResult: GlobalSearchResult): void {
        const formId = ACTIVITY_FORM_ID_MAP[globalSearchResult.entityType];
        if (formId) {
            this.onOpenDynamicForm({
                entityId: globalSearchResult.entityId,
                formId,
            });
        }
    }

    private onOpenDynamicForm(formSettings: {
        formId: string;
        entityId: string;
    }): void {
        this.dynamicFormsManagementService.openDynamicFormModal({
            formId: formSettings.formId,
            entityId: formSettings.entityId,
        });
    }
}
