import {
    ChangeDetectionStrategy,
    Component,
    Input,
    TemplateRef,
    ViewChild,
    inject,
} from '@angular/core';
import {
    TRANSLATION_IMPORT_ORDER_CLIENT,
    TRANSLATION_IMPORT_ORDER_CLIENTROLES,
    TRANSLATION_IMPORT_ORDER_EXAMPLE,
    TRANSLATION_IMPORT_ORDER_INFORMATION,
    TRANSLATION_IMPORT_ORDER_PARTY,
    TRANSLATION_IMPORT_ORDER_PARTYDETAILS,
    TRANSLATION_IMPORT_ORDER_PARTYROLE,
    TRANSLATION_IMPORT_ORDER_PRODUCTS,
    TRANSLATION_IMPORT_ORDER_REFERENCEDATA,
    TRANSLATION_IMPORT_ORDER_SEE_DETAILS,
    UTILS_ICON_CHEVRON_LEFT,
    UTILS_ICON_CHEVRON_RIGHT,
    WdxDestroyClass,
} from '@wdx/shared/utils';
import { Observable, takeUntil, tap } from 'rxjs';
import { ModalManagementService } from '@wdx/clmi/ui';
import { ImportFileUploaderService } from '../../../features/import-file-uploader';
import { IMPORT_ENTITIES_MODAL_ID } from '../import-entities.constant';
import { ImportEntitiesFacadeService } from '../services/import-entities-facade.service';
import { ImportCategory, SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import { SystemSettingsService } from '../../../services/system-settings/system-settings.service';

@Component({
    selector: 'clmi-import-entities-modal',
    templateUrl: './import-entities-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportEntitiesModalComponent extends WdxDestroyClass {
    private importEntitiesFacadeService = inject(ImportEntitiesFacadeService);
    private importFileUploaderService = inject(ImportFileUploaderService);
    private modalManagementService = inject(ModalManagementService);

    importEntities$: Observable<ImportCategory[]>;
    importEntitiesIsLoading$: Observable<boolean>;
    importEntitiesHasError$: Observable<boolean>;
    documentationUrl = inject(SystemSettingsService).get()
        .ImportOrderInformationUrl;

    @ViewChild('popoverContent') public popoverContent: TemplateRef<any>;

    @Input() modalTitle: string;

    public selectedCategory: ImportCategory;

    readonly IMPORT_ENTITIES_MODAL_ID = IMPORT_ENTITIES_MODAL_ID;
    readonly IMPORT_ORDER_INFORMATION = TRANSLATION_IMPORT_ORDER_INFORMATION;
    readonly IMPORT_ORDER_EXAMPLE = TRANSLATION_IMPORT_ORDER_EXAMPLE;
    readonly IMPORT_ORDER_SEE_DETAILS = TRANSLATION_IMPORT_ORDER_SEE_DETAILS;
    readonly IMPORT_ORDER_REFERENCEDATA =
        TRANSLATION_IMPORT_ORDER_REFERENCEDATA;
    readonly IMPORT_ORDER_PARTY = TRANSLATION_IMPORT_ORDER_PARTY;
    readonly IMPORT_ORDER_PARTYDETAILS = TRANSLATION_IMPORT_ORDER_PARTYDETAILS;
    readonly IMPORT_ORDER_PARTYROLE = TRANSLATION_IMPORT_ORDER_PARTYROLE;
    readonly IMPORT_ORDER_CLIENT = TRANSLATION_IMPORT_ORDER_CLIENT;
    readonly IMPORT_ORDER_CLIENTROLES = TRANSLATION_IMPORT_ORDER_CLIENTROLES;
    readonly IMPORT_ORDER_PRODUCTS = TRANSLATION_IMPORT_ORDER_PRODUCTS;
    readonly ICON_CHEVRON_LEFT = UTILS_ICON_CHEVRON_LEFT;
    readonly ICON_CHEVRON_RIGHT = UTILS_ICON_CHEVRON_RIGHT;

    fetchData() {
        this.importEntitiesFacadeService.setImportEntitiesList();
        this.importEntities$ =
            this.importEntitiesFacadeService.importEntities$.pipe(
                takeUntil(this.destroyed$),
                tap(
                    (entities) =>
                        !entities &&
                        this.importEntitiesFacadeService.getImportEntities(),
                ),
            );
        this.importEntitiesIsLoading$ =
            this.importEntitiesFacadeService.importEntitiesIsLoading$;
        this.importEntitiesHasError$ =
            this.importEntitiesFacadeService.importEntitiesHasError$;
    }
    onModalClosed(): void {
        this.modalManagementService.closeActiveModal();
    }

    onImport(entity: SystemEntity): void {
        this.importFileUploaderService.openModal(entity);
    }

    selectCategory(category: ImportCategory): void {
        this.selectedCategory = category;
    }

    onBackToCategoriesList(): void {
        this.selectedCategory = null;
    }
}
