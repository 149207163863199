import { createSelector } from '@ngrx/store';
import * as answersReducer from './answers.reducer';

export const getState = (state: {
    answers: answersReducer.State;
}): answersReducer.State => state.answers;

export const getAnswerSets = createSelector(
    getState,
    (state: answersReducer.State) => state.answerSet?.list,
);

export const getAnswerSetsIsLoading = createSelector(
    getState,
    (state: answersReducer.State) => state.answerSet?.isLoadingList,
);

export const getFilteredAnswerSets = createSelector(
    getState,
    (state: answersReducer.State) => {
        const list = state.answerSet?.list || [];
        const searchText = state.searchText?.toLowerCase() || '';

        if (!searchText || searchText.length < 2) {
            return list;
        }

        return list.filter((answerSet) =>
            Object.values(answerSet).some((val) =>
                String(val).toLowerCase().includes(searchText),
            ),
        );
    },
);

export const getAnswers = createSelector(
    getState,
    (state: answersReducer.State, props: { code: string }) =>
        state.answers && state.answers[props.code]
            ? state.answers[props.code].list
            : null,
);

export const getAnswersIsLoading = createSelector(
    getState,
    (state: answersReducer.State, props: { code: string }) =>
        state.answers && state.answers[props.code]
            ? state.answers[props.code].isLoadingList
            : null,
);
