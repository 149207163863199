import { Injectable } from '@angular/core';
import {
    FilterOperator,
    Query,
    SystemEntity,
    View,
} from '@wdx/clmi/clmi-swagger-gen';
import { LocalStorageKey, LocalStorageService } from '@wdx/shared/utils';
import { EXTERNAL_TASK_DATA } from '../../../../features/external-tasks/external-tasks.static';
import { Observable, from } from 'rxjs';
import { INBOX_DATA } from '../../../../features/inbox/inbox.static';
import { TASK_DATA } from '../../../../features/tasks/tasks.static';
import { DynamicTabInstance } from '../../../../models/dynamic-tab-instance.model';
import {
    APP_CLIENTS,
    APP_CLIENT_PRODUCTS,
    APP_EMPLOYEES,
    APP_EXTERNAL_TASKS,
    APP_LISTS,
    APP_TASKS,
    APP_TYPES,
} from '../../../../shared/features/form-filters/form-filters.static';
import { CLIENT_RELATIONSHIPS_DATA } from '../../../features/filter-table';
import { CLIENT_PRODUCTS_DATA } from '../../../features/filter-table/client-products';
import { LISTS_DATA } from '../../../features/filter-table/lists';
import { FilterResolver } from './filter.resolver';
import { ADVISERS_ROUTE_DATA } from '../../../features/filter-table/advisers/advisers.static';

@Injectable()
export class FilterWrapperService {
    constructor(
        private filterResolver: FilterResolver,
        private localStorageService: LocalStorageService,
    ) {}

    config$(
        props: {
            value: string;
            lookupType: SystemEntity;
            field: string;
            operator: FilterOperator;
            viewType?: SystemEntity;
        },
        completeInstance?: DynamicTabInstance,
    ): Observable<View> {
        let data;
        let SYSTEM_ENTITY: SystemEntity;
        let STATIC_DATA;

        switch (completeInstance?.tabId) {
            case APP_EXTERNAL_TASKS:
                SYSTEM_ENTITY = SystemEntity.ExternalTask;
                STATIC_DATA = EXTERNAL_TASK_DATA;
                break;
            case APP_TASKS:
                SYSTEM_ENTITY = SystemEntity.Task;
                STATIC_DATA = TASK_DATA;
                break;
            case APP_CLIENTS:
                SYSTEM_ENTITY = SystemEntity.Client;
                STATIC_DATA = CLIENT_RELATIONSHIPS_DATA;
                break;
            case APP_EMPLOYEES:
                SYSTEM_ENTITY = SystemEntity.Adviser;
                STATIC_DATA = ADVISERS_ROUTE_DATA;
                break;
            case APP_CLIENT_PRODUCTS:
                SYSTEM_ENTITY = SystemEntity.ClientProduct;
                STATIC_DATA = CLIENT_PRODUCTS_DATA;
                break;
            case APP_LISTS:
                SYSTEM_ENTITY = SystemEntity.List;
                STATIC_DATA = LISTS_DATA;
                break;
            default:
                SYSTEM_ENTITY = SystemEntity.Activity;
                STATIC_DATA = INBOX_DATA;
        }

        if (SYSTEM_ENTITY) {
            this.localStorageService.setStringKey(
                `${LocalStorageKey.DefaultViewId}-${SYSTEM_ENTITY}`,
                `${
                    SYSTEM_ENTITY === SystemEntity.Activity
                        ? 'inbox'
                        : SYSTEM_ENTITY.toLowerCase()
                }-all`,
            );
        }

        if (!completeInstance || APP_TYPES.includes(completeInstance.tabId)) {
            data = this.setActivity(
                props,
                STATIC_DATA,
                completeInstance?.data?.query,
            );
        }

        return from(this.filterResolver.resolve(data));
    }

    setActivity(props, STATIC_DATA, query?: Query): any {
        return {
            data: {
                ...STATIC_DATA,
                contextual: query?.expressions || [
                    {
                        field: props?.field,
                        operator: props?.operator,
                        contextual: true,
                        values: [
                            {
                                id: props.value,
                                lookupType: props?.lookupType
                                    ? props.lookupType
                                    : SystemEntity.Party,
                            },
                        ],
                    },
                ],
            },
        };
    }
}
