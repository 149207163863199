import { Action, createReducer, on } from '@ngrx/store';
import { AvatarInfo, SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import { CrudState, CrudStateObject, KeyValueObject } from '@wdx/shared/utils';
import * as avatarInfoActions from './avatar-info.actions';

export interface State {
    avatarInfo?: KeyValueObject<CrudStateObject<AvatarInfo>>;
}

export const initialState: State = {
    avatarInfo: {},
};

const setState = (state: State) =>
    state?.avatarInfo || ({} as CrudStateObject<AvatarInfo>);
const setEntityState = (state: State, entityType: SystemEntity) =>
    (state.avatarInfo && state.avatarInfo[entityType]) ||
    ({} as CrudStateObject<AvatarInfo>);
const setEntityIdState = (
    state: State,
    entityType: SystemEntity,
    entityId: string,
) =>
    (state.avatarInfo &&
        state.avatarInfo[entityType] &&
        state.avatarInfo[entityType][entityId]) ||
    ({} as CrudState<AvatarInfo>);

const reducerSetup = createReducer(
    initialState,

    on(
        avatarInfoActions.getForEntityId,
        (state, props): State => ({
            ...state,
            avatarInfo: {
                ...setState(state),
                [props.entityType as any]: {
                    ...setEntityState(state, props.entityType),
                    [props.entityId]: {
                        ...setEntityIdState(
                            state,
                            props.entityType,
                            props.entityId,
                        ),
                        isLoadingSingle: true,
                        hasLoadingSingleError: false,
                    },
                },
            },
        }),
    ),

    on(
        avatarInfoActions.getForEntityIdSuccess,
        (state, props): State => ({
            ...state,
            avatarInfo: {
                ...setState(state),
                [props.entityType as any]: {
                    ...setEntityState(state, props.entityType),
                    [props.entityId]: {
                        ...setEntityIdState(
                            state,
                            props.entityType,
                            props.entityId,
                        ),
                        isLoadingSingle: false,
                        hasLoadingSingleError: false,
                        single: props.avatarInfo,
                    },
                },
            },
        }),
    ),

    on(
        avatarInfoActions.getForEntityIdFailure,
        (state, props): State => ({
            ...state,
            avatarInfo: {
                ...setState(state),
                [props.entityType as any]: {
                    ...setEntityState(state, props.entityType),
                    [props.entityId]: {
                        ...setEntityIdState(
                            state,
                            props.entityType,
                            props.entityId,
                        ),
                        isLoadingSingle: false,
                        hasLoadingSingleError: true,
                    },
                },
            },
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
