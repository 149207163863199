import { createSelector } from '@ngrx/store';
import { MappedLookupResult } from '../../models/mapped-lookup-result.model';
import * as lookupsReducer from './lookups.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    lookups: lookupsReducer.State;
}): lookupsReducer.State => state.lookups;

/**
 * Reducer property selectors
 */
export const getIsLoadingList = createSelector(
    getState,
    (state: lookupsReducer.State, props: any) =>
        state.lookups[props.lookupId]?.isLoadingList,
);
export const getHasLoadingListError = createSelector(
    getState,
    (state: lookupsReducer.State, props: any) =>
        state.lookups[props.lookupId]?.hasLoadingListError,
);
export const getList = createSelector(
    getState,
    (state: lookupsReducer.State, props: any) =>
        state.lookups[props.lookupId]?.list,
);

/**
 * Derived selectors
 */
export const getListAsMappedLookupResults = createSelector(
    getState,
    (state: lookupsReducer.State, props: any): MappedLookupResult[] => {
        return (state.lookups[props.lookupId]?.list || []).map((result) => ({
            name: result.name,
            avatarToken: '',
            id: result.id,
            type: props.entityType,
        }));
    },
);

export const getIsLoadingListForIds = createSelector(
    getState,
    (state: lookupsReducer.State, props: any) =>
        props.ids.some((id) => state.lookups[id]?.isLoadingList),
);

export const getHasLoadingListErrorForIds = createSelector(
    getState,
    (state: lookupsReducer.State, props: any) =>
        props.ids.some((id) => state.lookups[id]?.hasLoadingListError),
);

export const getListForIds = createSelector(
    getState,
    (state: lookupsReducer.State, props: any) =>
        props.ids.reduce(
            (accumulator, id) => ({
                ...accumulator,
                [id]: state.lookups[id]?.list || [],
            }),
            {},
        ),
);
