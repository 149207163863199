import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ICellRendererParams } from '@ag-grid-community/core';
import { IdentityMap } from '@wdx/shared/swagger-gen';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { WdxListModule } from '@wdx/shared/components/wdx-list';

@Component({
    selector: 'wdx-ag-grid-identities-cell-renderer',
    standalone: true,
    styleUrls: ['./indentities-cell-renderer.component.scss'],
    imports: [CommonModule, NgbTooltipModule, WdxListModule],
    templateUrl: './indentities-cell-renderer.component.html',
})
export class IdentitiesRendererComponent implements ICellRendererAngularComp {
    public identities?: IdentityMap[];

    public agInit(params: ICellRendererParams<any, IdentityMap[]>): void {
        if (params?.value) {
            this.identities = params.value as IdentityMap[];
        }
    }

    public refresh(params: ICellRendererParams<any, IdentityMap[]>): boolean {
        this.agInit(params);
        return true;
    }
}
