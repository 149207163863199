<ng-container *ngFor="let fragment of fragments">
    <ng-container *ngIf="fragment.type === NOTIFICATION_FRAGMENT_TYPE.Text">
        {{ fragment.value | translateTokenisedString }}
    </ng-container>

    <ng-container *ngIf="fragment.type === NOTIFICATION_FRAGMENT_TYPE.Link">
        <a
            [routerLink]="fragment.routerLink"
            [queryParams]="fragment.queryParams"
            [attr.data-cy]="'notification-link-' + fragment.value"
            (click)="fragment.routerLink && onLinkSelected()"
        >
            {{ fragment.value | translateTokenisedString }}
        </a>
    </ng-container>

    <ng-container
        *ngIf="fragment.type === NOTIFICATION_FRAGMENT_TYPE.FileDownload"
    >
        <button wdxButton="inline" (click)="onDownload(fragment)">
            {{ fragment.value }}
        </button>
    </ng-container>
</ng-container>
