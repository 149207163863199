import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuickCreateModalComponent } from './quick-create-modal.component';
import { WdxFormFrameworkModule } from '@wdx/shared/infrastructure/form-framework';
import { WdxIsLoadingModule } from '@wdx/shared/components/wdx-is-loading';
import { TranslationsService } from '@wdx/shared/utils';
import { ModalManagementService } from '../modal-management.service';

@NgModule({
    declarations: [QuickCreateModalComponent],
    imports: [
        CommonModule,
        WdxFormFrameworkModule,
        WdxIsLoadingModule,
    ],
    exports: [
        QuickCreateModalComponent,
    ],
    providers: [
        TranslationsService,
        ModalManagementService,
    ],
})
export class QuickCreateModalModule {}
