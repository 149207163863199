import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import { Component, HostBinding } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { PushPipe } from '@ngrx/component';
import {
    WdxButtonModule,
    WdxButtonSize,
    WdxButtonStyle,
} from '@wdx/shared/components/wdx-button';
import { WdxDropdownModule } from '@wdx/shared/components/wdx-dropdown';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxSpinnerModule } from '@wdx/shared/components/wdx-spinner';
import { EntityAction } from '@wdx/shared/utils';
import { Observable } from 'rxjs';

export type ActionRendererAction = EntityAction & {
    callback?: (value: any) => void;
};

type ActionRendererActionsObs = Observable<ActionRendererAction[]>;

export interface ActionsCellRendererValue {
    actions$: ActionRendererActionsObs;
    defaultCallback: (action: EntityAction, data: any) => void;
}

@Component({
    selector: 'wdx-ag-grid-actions-cell-renderer',
    standalone: true,
    imports: [
        CommonModule,
        WdxButtonModule,
        WdxIconModule,
        WdxDropdownModule,
        NgbDropdownModule,
        PushPipe,
        WdxSpinnerModule,
    ],
    templateUrl: './actions-cell-renderer.component.html',
})
export class ActionsRendererComponent implements ICellRendererAngularComp {
    @HostBinding('class') class =
        'd-flex h-100 justify-content-center align-items-center';

    public readonly WdxButtonSize = WdxButtonSize;
    public readonly WdxButtonStyle = WdxButtonStyle;
    public actions$?: ActionRendererActionsObs;
    public data: any;
    public opened = false;
    private defaultCallback?: (action: EntityAction, data: any) => void;

    public agInit(
        params: ICellRendererParams<any, ActionsCellRendererValue>,
    ): void {
        this.data = params.data;
        this.defaultCallback = params.value?.defaultCallback;
        this.actions$ = params.value?.actions$;
    }

    public refresh(
        params: ICellRendererParams<any, ActionsCellRendererValue>,
    ): boolean {
        this.agInit(params);
        return true;
    }

    public actionClicked(action: ActionRendererAction, data: any) {
        if (action.callback) {
            action.callback(data);
            return;
        }
        this.defaultCallback?.(action, data);
    }

    public openChange(opened: boolean) {
        this.opened = opened;
    }
}
