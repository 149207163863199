import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterHandler } from '../../../classes/filter-handler.class';
import { ActionButton } from '../../../models/action-button.model';
import { ActionButtonMode } from '../../../models/action-buttons-mode.model';
import { AssignToEvent } from '../../../models/assign.model';
import { DropdownButtonStyle } from '../../../models/dropdown-button-style.model';
import { GroupStyle } from '../../../models/group-style.model';
import { InfoCardData } from '../../../models/info-card-data.model';
import { InfoCardExtendedData } from '../../../models/info-card-extended-data.model';
import { Icon } from '@wdx/shared/utils';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'organism-extended-info-card-list',
    templateUrl: './organism-extended-info-card-list.component.html',
})
export class OrganismExtendedInfoCardListComponent {
    @Input() isLoading: boolean;
    @Input() hasError: boolean;
    @Input() extendedInfoCards: InfoCardExtendedData[];
    @Input() searchType: string;
    @Input() searchTypePlural: string;
    @Input() totalRecordCount: number;
    @Input() filterHandler: FilterHandler;
    @Input() showButtons = true;
    @Input() createNewActionButton: ActionButton;
    @Input() inputSearchText: string;
    @Input() listOnly: boolean;
    @Input() buttons: ActionButton[];
    @Input() icon: Icon;
    @Input() showSearchBar = true;

    @Output() infinityScrollFired = new EventEmitter();
    @Output() toggleFavourite = new EventEmitter<any>();
    @Output() searchText = new EventEmitter<string>();
    @Output() assignTo = new EventEmitter<AssignToEvent>();

    activeInfoCardData: InfoCardData;

    readonly CREATE_ACTION_BUTTON_MODE = ActionButtonMode;
    readonly DROPDOWN_BUTTON_STYLE = DropdownButtonStyle;
    readonly GROUP_STYLE = GroupStyle;

    onSearch(value: string): void {
        this.filterHandler?.setSearchText(value);
        this.searchText.emit(value);
    }

    onInfinityScrollFired(): void {
        this.infinityScrollFired.emit();
    }

    onToggleFavourite(isFavourite: boolean, id: string): void {
        this.toggleFavourite.emit({
            isFavourite: isFavourite,
            id: id,
        });
    }
}
