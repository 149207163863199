import { NgModule } from '@angular/core';
import { AtomSplitButtonDirective } from '../directives/atom-split-button.directive';
import { ClickPreventDefault } from './click-prevent-default.directive';
import { HasPrivilegeDirective } from './has-privilege.directive';
import { InfiniteScrollExtendedDirective } from './infinite-scroll-extended.directive';
import { InfiniteScrollDirective } from './infinite-scroll.directive';
import { ModalDirective } from './modal.directive';
import { OffcanvasDirective } from './offcanvas.directive';
import { ResponsiveColumnsDirective } from './responsive-columns.directive';
import { ClmiDynamicFormDirective } from '@wdx/clmi/ui';

@NgModule({
    declarations: [
        ClickPreventDefault,
        ClmiDynamicFormDirective,
        HasPrivilegeDirective,
        InfiniteScrollDirective,
        InfiniteScrollExtendedDirective,
        ModalDirective,
        OffcanvasDirective,
        ResponsiveColumnsDirective,
        AtomSplitButtonDirective,
    ],
    exports: [
        ClickPreventDefault,
        ClmiDynamicFormDirective,
        HasPrivilegeDirective,
        InfiniteScrollDirective,
        InfiniteScrollExtendedDirective,
        ModalDirective,
        OffcanvasDirective,
        ResponsiveColumnsDirective,
        AtomSplitButtonDirective,
    ],
})
export class DirectivesModule {}
