import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ICellRendererParams } from '@ag-grid-community/core';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';

@Component({
    selector: 'wdx-ag-grid-boolean-cell-renderer',
    standalone: true,
    imports: [CommonModule, WdxIconModule],
    template: `
        <div class="text-center">
            @if (value) {
                <wdx-icon [scale]="1.5" icon="square-check"></wdx-icon>
            } @else {
                <wdx-icon [scale]="1.5" icon="square"></wdx-icon>
            }
        </div>
    `,
})
export class BooleanRendererComponent implements ICellRendererAngularComp {
    public value: boolean | null | undefined;

    agInit(params: ICellRendererParams<any, boolean>): void {
        this.value = params?.value;
    }

    refresh(params: ICellRendererParams<any, boolean>): boolean {
        this.agInit(params);
        return true;
    }
}
