import {
    ProductStatus,
    RelationshipStatus,
    StatusType,
} from '@wdx/clmi/clmi-swagger-gen';
import { Highlight, WdxDateTimeService } from '@wdx/shared/utils';
import { TagData } from '../models/tag-data.model';

export const INACTIVE_TAG: TagData = {
    label: StatusType.Inactive,
    highlight: Highlight.Info,
    shade: 100,
};

export const DRAFT_TAG: TagData = {
    label: ProductStatus.Draft,
    highlight: Highlight.Info,
    shade: 100,
};

export const CLOSING_TAG: TagData = {
    label: ProductStatus.Closing,
    highlight: Highlight.Warning,
    shade: 100,
};

export const PROPOSED_TAG: TagData = {
    label: ProductStatus.Proposed,
    highlight: Highlight.Info,
    shade: 100,
};

export const ACTIVE_TAG: TagData = {
    label: ProductStatus.Active,
    highlight: Highlight.Success,
    shade: 100,
};

export const BLOCKED_TAG: TagData = {
    label: ProductStatus.Blocked,
    highlight: Highlight.Danger,
    shade: 100,
};

export const CANCELLED_TAG: TagData = {
    label: ProductStatus.Cancelled,
    highlight: Highlight.Danger,
    shade: 100,
};

export const INACTIVE_RELATIONSHIP_TAG = (tooltip?: string): TagData => {
    return {
        label: RelationshipStatus.Inactive,
        highlight: Highlight.Info,
        shade: 100,
        tooltip: tooltip,
    };
};

export const DRAFT_RELATIONSHIP_TAG: TagData = {
    label: RelationshipStatus.Draft,
    highlight: Highlight.Info,
    shade: 100,
};

export const ALL_TAG_CATEGORIES_LABEL = 'All Tag Categories';
export const PRIMARY_LABEL = 'Primary';

export const PRIMARY_TAG: TagData = {
    label: PRIMARY_LABEL,
    highlight: Highlight.Info,
    shade: 100,
};

export const RELATIONSHIP_STATUS_TAG = (
    wdxDateTimeService: WdxDateTimeService,
    relationshipStatus?: RelationshipStatus,
    date?: string,
) => {
    const tooltip = date
        ? `Since: ${wdxDateTimeService?.convertDateToViewFriendlyFormat(date)}`
        : null;
    switch (relationshipStatus) {
        case RelationshipStatus.Inactive:
            return tooltip
                ? [INACTIVE_RELATIONSHIP_TAG(tooltip)]
                : [INACTIVE_RELATIONSHIP_TAG()];
        case RelationshipStatus.Draft:
            return [DRAFT_RELATIONSHIP_TAG];

        default:
            return [];
    }
};

export const RELATIONSHIP_STATUS_TAG_TRANSLATION: Record<
    RelationshipStatus,
    string
> = {
    [RelationshipStatus.Inactive]: 'RELATIONSHIP_STATUS_INACTIVE',
    [RelationshipStatus.Active]: 'RELATIONSHIP_STATUS_ACTIVE',
    [RelationshipStatus.Draft]: 'RELATIONSHIP_STATUS_DRAFT',
    [RelationshipStatus.Proposed]: 'RELATIONSHIP_STATUS_PROPOSED',
    [RelationshipStatus.PendingInactive]:
        'RELATIONSHIP_STATUS_PENDING_INACTIVE',
};

export const RELATIONSHIP_STATUS_TAG_INACTIVE: TagData = {
    label: RELATIONSHIP_STATUS_TAG_TRANSLATION[RelationshipStatus.Inactive],
    highlight: Highlight.Info,
    shade: 100,
};

export const RELATIONSHIP_STATUS_TAG_DRAFT: TagData = {
    label: RELATIONSHIP_STATUS_TAG_TRANSLATION[RelationshipStatus.Draft],
    highlight: Highlight.Info,
    shade: 100,
};

export const RELATIONSHIP_STATUS_TAG_PENDING_INACTIVE: TagData = {
    label: RELATIONSHIP_STATUS_TAG_TRANSLATION[
        RelationshipStatus.PendingInactive
    ],
    highlight: Highlight.Info,
    shade: 100,
};
