/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum AppType {
  Consent = 'Consent',
  ContactInformation = 'ContactInformation',
  Goals = 'Goals',
  SourceOfWealth = 'SourceOfWealth',
  AttitudeToRisk = 'AttitudeToRisk',
  Knowledge = 'Knowledge',
  CommercialContext = 'CommercialContext',
  OrganisationFinancials = 'OrganisationFinancials',
  WealthAndIncome = 'WealthAndIncome',
  ClientRoles = 'ClientRoles',
  Family = 'Family',
  Employment = 'Employment',
  Tax = 'Tax',
  InterestsAndHobbies = 'InterestsAndHobbies',
  InvestorRiskProfile = 'InvestorRiskProfile',
  PersonBusinessRisk = 'PersonBusinessRisk',
  OrganisationBusinessRisk = 'OrganisationBusinessRisk',
  Esg = 'Esg',
  Employees = 'Employees',
  MailingInstructions = 'MailingInstructions',
  EstatePlanning = 'EstatePlanning',
  Protection = 'Protection',
  ClientSummary = 'ClientSummary',
  ProductSelection = 'ProductSelection',
  PersonDetails = 'PersonDetails',
  OrganisationDetails = 'OrganisationDetails',
  ClientCommunication = 'ClientCommunication',
  ProfessionalConnections = 'ProfessionalConnections',
  AmlRisk = 'AmlRisk',
  OrganisationEmirFmia = 'OrganisationEmirFmia',
  OrganisationSourceOfWealth = 'OrganisationSourceOfWealth',
  PartyBankAccount = 'PartyBankAccount',
  ClientBankAccount = 'ClientBankAccount',
  BusinessRiskClient = 'BusinessRiskClient',
  TaxClient = 'TaxClient',
  OrganisationTax = 'OrganisationTax',
  AboutYou = 'AboutYou',
  Kyc = 'Kyc',
  ExternalUser = 'ExternalUser',
  OrganisationConnections = 'OrganisationConnections',
  Powers = 'Powers',
  PendingChanges = 'PendingChanges',
  Review = 'Review',
  OxfordRisk = 'OxfordRisk',
  ClientProductRelationships = 'ClientProductRelationships',
  AdverseMediaCheck = 'AdverseMediaCheck',
  OrganisationExternalStructure = 'OrganisationExternalStructure',
  CorporateStructure = 'CorporateStructure',
  PepAndSanctions = 'PepAndSanctions',
  CoreScreening = 'CoreScreening',
  IdentityVerification = 'IdentityVerification',
  ClientRiskAssessment = 'ClientRiskAssessment',
  NationalityAndResidence = 'NationalityAndResidence',
  Portfolio = 'Portfolio',
  Investment = 'Investment',
  Insurance = 'Insurance',
  Lending = 'Lending',
  Banking = 'Banking',
  PortfolioInstructions = 'PortfolioInstructions',
  ProductSetup = 'ProductSetup',
  ProductDetails = 'ProductDetails',
  ProcessData = 'ProcessData',
  DocumentChecklist = 'DocumentChecklist',
  MandatoryDocumentChecklist = 'MandatoryDocumentChecklist',
  CaseDocumentChecklist = 'CaseDocumentChecklist',
  Appendix = 'Appendix',
  Testing = 'Testing',
  Group = 'Group',
  NotApplicable = 'NotApplicable'
}
