import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as lookupsActions from './lookups.actions';
import { LookupsService } from './lookups.service';

@Injectable()
export class LookupsEffects {
    private actions$ = inject(Actions);
    private lookupsService = inject(LookupsService);

    getLookup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(lookupsActions.getLookup),
            switchMap((action) =>
                this.lookupsService
                    .getLookup(
                        action.lookupSource,
                        action.searchText,
                        action.entityId,
                        action.entityType,
                    )
                    .pipe(
                        map((results) =>
                            lookupsActions.getLookupSuccess({
                                lookupId: action.lookupId,
                                results,
                            }),
                        ),
                        catchError((error) =>
                            of(
                                lookupsActions.getLookupFailure({
                                    lookupId: action.lookupId,
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    getContextualLookup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(lookupsActions.getContextualLookup),
            switchMap((action) =>
                this.lookupsService
                    .getContextualLookup(
                        action.lookupSource,
                        action.lookups,
                        action.searchText,
                        action.context,
                    )
                    .pipe(
                        map((results) =>
                            lookupsActions.getContextualLookupSuccess({
                                lookupId: action.lookupId,
                                results,
                            }),
                        ),
                        catchError((error) =>
                            of(
                                lookupsActions.getContextualLookupFailure({
                                    lookupId: action.lookupId,
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );
}
